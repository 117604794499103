'use strict';

var base = require('base/checkout/shipping');
var cleave = require('../components/cleave');
var formHelpers = require('base/checkout/formErrors');
var scrollAnimate = require('base/components/scrollAnimate');
var clientSideValidation = require('../components/clientSideValidation');
var addressHelpers = require('base/checkout/address');
cleave.handleMobileNumber();
cleave.handleCreditCardExpiryDate();

/**
 * Clear out all the shipping form values and select the new address in the drop down
 * @param {Object} order - the order object
 */
function clearShippingForms(order) {
    order.shipping.forEach(function (shipping) {
        $('input[value=' + shipping.UUID + ']').each(function (formIndex, el) {
            var form = el.form;
            if (!form) return;

            $('input[name$=_address1]', form).val('');
            $('input[name$=_address2]', form).val('');
            $('input[name$=_city]', form).val('');
            $('input[name$=_postalCode]', form).val('');
            $('select[name$=_stateCode],input[name$=_stateCode]', form).val('');
            $('select[name$=_country]', form).val('');


            $('input[name$=_isGift]', form).prop('checked', false);
            $('textarea[name$=_giftMessage]', form).val('');
            $(form).find('.gift-message').addClass('d-none');

            $(form).attr('data-address-mode', 'new');
            var addressSelectorDropDown = $('.addressSelector option[value=new]', form);
            $(addressSelectorDropDown).prop('selected', true);
        });
    });

    $('body').trigger('shipping:clearShippingForms', { order: order });
}
/**
 * updates the shipping address selector within shipping forms
 * @param {Object} productLineItem - the productLineItem model
 * @param {Object} shipping - the shipping (shipment model) model
 * @param {Object} order - the order model
 * @param {Object} customer - the customer model
 */
function updateShippingAddressSelector(productLineItem, shipping, order, customer) {
    var uuidEl = $('input[value=' + productLineItem.UUID + ']');
    var shippings = order.shipping;

    var form;
    var $shippingAddressSelector;
    var hasSelectedAddress = false;

    if (uuidEl && uuidEl.length > 0) {
        form = uuidEl[0].form;
        $shippingAddressSelector = $('.addressSelector', form);
    }

    if ($shippingAddressSelector && $shippingAddressSelector.length === 1) {
        $shippingAddressSelector.empty();
        // Add New Address option
        $shippingAddressSelector.append(addressHelpers.methods.optionValueForAddress(
            null,
            false,
            order
        ));

        if (customer.addresses && customer.addresses.length > 0) {
            $shippingAddressSelector.append(addressHelpers.methods.optionValueForAddress(
                order.resources.accountAddresses,
                false,
                order
            ));

            customer.addresses.forEach(function (address) {
                var isSelected = shipping.matchingAddressId === address.ID;
                $shippingAddressSelector.append(
                    addressHelpers.methods.optionValueForAddress(
                        { UUID: 'ab_' + address.ID, shippingAddress: address },
                        isSelected,
                        order
                    )
                );
            });
        }
        // Separator -
        $shippingAddressSelector.append(addressHelpers.methods.optionValueForAddress(
            order.resources.shippingAddresses, false, order, { className: 'multi-shipping' }
        ));
        shippings.forEach(function (aShipping) {
            if (!aShipping.fromStoreId) {
                var block = $shippingAddressSelector.parents('.shipment-selector-block');
                var selectedShippingMethod = aShipping.selectedShippingMethod ? aShipping.selectedShippingMethod.ID : '';
                if ((block.attr('data-isfreight') != 'true' && selectedShippingMethod == '4') || (block.attr('data-isfreight') == 'true' && selectedShippingMethod != '4')) {
                    return;
                }
                var isSelected = shipping.UUID === aShipping.UUID;
                hasSelectedAddress = hasSelectedAddress || isSelected;
                var addressOption = addressHelpers.methods.optionValueForAddress(
                    aShipping,
                    isSelected,
                    order,
                    { className: 'multi-shipping' }
                );

                var newAddress = addressOption.html() === order.resources.addNewAddress;
                var matchingUUID = aShipping.UUID === shipping.UUID;
                if ((newAddress && matchingUUID) || (!newAddress && matchingUUID) || (!newAddress && !matchingUUID)) {
                    $shippingAddressSelector.append(addressOption);
                }
                if (newAddress && !matchingUUID) {
                    $(addressOption[0]).remove();
                }
            }
        });
    }

    if (!hasSelectedAddress) {
        // show
        $(form).addClass('hide-details');
    } else {
        $(form).removeClass('hide-details');
    }

    $('body').trigger('shipping:updateShippingAddressSelector', {
        productLineItem: productLineItem,
        shipping: shipping,
        order: order,
        customer: customer
    });
}

/**
 * Update the read-only portion of the shipment display (per PLI)
 * @param {Object} productLineItem - the productLineItem model
 * @param {Object} shipping - the shipping (shipment model) model
 * @param {Object} order - the order model
 * @param {Object} [options] - options for updating PLI summary info
 * @param {Object} [options.keepOpen] - if true, prevent changing PLI view mode to 'view'
 */
function updatePLIShippingSummaryInformation(productLineItem, shipping, order, options) {
    var $pli = $('input[value=' + productLineItem.UUID + ']');
    var form = $pli && $pli.length > 0 ? $pli[0].form : null;

    if (!form) return;

    var $viewBlock = $('.view-address-block', form);

    var address = shipping.shippingAddress || {};
    var selectedMethod = shipping.selectedShippingMethod;

    var nameLine = address.firstName ? address.firstName + ' ' : '';
    if (address.lastName) nameLine += address.lastName;

    var address1Line = address.address1;
    var address2Line = address.address2;

    var phoneLine = address.phone;

    var shippingCost = selectedMethod ? selectedMethod.shippingCost : '';
    var methodNameLine = selectedMethod ? selectedMethod.displayName : '';
    var methodArrivalTime = selectedMethod && selectedMethod.estimatedArrivalTime
        ? '(' + selectedMethod.estimatedArrivalTime + ')'
        : '';

    var tmpl = $('#pli-shipping-summary-template').clone();

    $('.ship-to-name', tmpl).text(nameLine);
    $('.ship-to-address1', tmpl).text(address1Line);
    $('.ship-to-address2', tmpl).text(address2Line);
    $('.ship-to-city', tmpl).text(address.city);
    if (address.stateCode) {
        $('.ship-to-st', tmpl).text(address.stateCode);
    }
    $('.ship-to-zip', tmpl).text(address.postalCode);
    $('.ship-to-phone', tmpl).text(phoneLine);

    if (!address2Line) {
        $('.ship-to-address2', tmpl).hide();
    }

    if (!phoneLine) {
        $('.ship-to-phone', tmpl).hide();
    }

    if (shipping.selectedShippingMethod) {
        $('.display-name', tmpl).text(methodNameLine);
        $('.arrival-time', tmpl).text(methodArrivalTime);
        $('.price', tmpl).text(shippingCost);
    }

    if (shipping.isGift) {
        $('.gift-message-summary', tmpl).text(shipping.giftMessage);
        var shipment = $('.gift-message-' + shipping.UUID);
        $(shipment).val(shipping.giftMessage);
    } else {
        $('.gift-summary', tmpl).addClass('d-none');
    }
    // checking h5 title shipping to or pickup
    var $shippingAddressLabel = $('.shipping-header-text', tmpl);
    $('body').trigger('shipping:updateAddressLabelText',
        { selectedShippingMethod: selectedMethod, resources: order.resources, shippingAddressLabel: $shippingAddressLabel });

    $viewBlock.html(tmpl.html());

    $('body').trigger('shipping:updatePLIShippingSummaryInformation', {
        productLineItem: productLineItem,
        shipping: shipping,
        order: order,
        options: options
    });
}

/**
 * Update list of available shipping methods whenever user modifies shipping address details.
 * @param {jQuery} $shippingForm - current shipping form
 */
function updateShippingMethodList($shippingForm) {
    // delay for autocomplete!
    setTimeout(function () {
        var $shippingMethodList = $shippingForm.find('.shipping-method-list');
        var urlParams = addressHelpers.methods.getAddressFieldsFromUI($shippingForm);
        var shipmentUUID = $shippingForm.find('[name=shipmentUUID]').val();
        var url = $shippingMethodList.data('actionUrl');
        urlParams.shipmentUUID = shipmentUUID;
        var $shipmentSelectorBlock = $('.shipment-selector-block');

        $shipmentSelectorBlock.spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('body').trigger('checkout:updateCheckoutView',
                        {
                            order: data.order,
                            customer: data.customer,
                            options: { keepOpen: true }
                        });
                    $shipmentSelectorBlock.spinner().stop();
                }
            }
        });
    }, 300);
}

/**
 * Update the shipping UI for a single shipping info (shipment model)
 * @param {Object} shipping - the shipping (shipment model) model
 * @param {Object} order - the order/basket model
 * @param {Object} customer - the customer model
 * @param {Object} [options] - options for updating PLI summary info
 * @param {Object} [options.keepOpen] - if true, prevent changing PLI view mode to 'view'
 */
function updateShippingInformation(shipping, order, customer, options) {
    // First copy over shipmentUUIDs from response, to each PLI form
    order.shipping.forEach(function (aShipping) {
        aShipping.productLineItems.items.forEach(function (productLineItem) {
            base.methods.updateProductLineItemShipmentUUIDs(productLineItem, aShipping);
        });
    });

    // Now update shipping information, based on those associations
    base.methods.updateShippingMethods(shipping);

    // If there is only one shipping method, make sure that its radio button is checked
    var shippingOpts = $("input[name = dwfrm_shipping_shippingAddress_shippingMethodID]");
    if ((shippingOpts.length === 1) && (!shippingOpts[0].checked)) {
        shippingOpts[0].checked = true;
        console.log("Only one shipping method shown. Setting its radio button to checked.");
    }

    updateShippingAddressFormValues(shipping);
    base.methods.updateShippingSummaryInformation(shipping, order);
    updateShippingSummaryInformation(shipping, order);

    // And update the PLI-based summary information as well
    shipping.productLineItems.items.forEach(function (productLineItem) {
        updateShippingAddressSelector(productLineItem, shipping, order, customer);
        updatePLIShippingSummaryInformation(productLineItem, shipping, order, options);
    });

    $('body').trigger('shipping:updateShippingInformation', {
        order: order,
        shipping: shipping,
        customer: customer,
        options: options
    });
}

/**
 * updates the shipping address form values within shipping forms
 * @param {Object} shipping - the shipping (shipment model) model
 */
function updateShippingAddressFormValues(shipping) {
    var addressObject = $.extend({}, shipping.shippingAddress);

    if (!addressObject) {
        addressObject = {
            firstName: null,
            lastName: null,
            address1: null,
            address2: null,
            city: null,
            postalCode: null,
            stateCode: null,
            countryCode: null,
            phone: null
        };
    }

    addressObject.isGift = shipping.isGift;
    addressObject.giftMessage = shipping.giftMessage;

    $('input[value=' + shipping.UUID + ']').each(function (formIndex, el) {
        var form = el.form;
        if (!form) return;
        var countryCode = "US";
        if (!$('#multiShipCheck').is(':visible')) {
            if (addressObject.firstName) {
                $('input[name$=_firstName]', form).val(addressObject.firstName);
            }
            if (addressObject.lastName) {
                $('input[name$=_lastName]', form).val(addressObject.lastName);
            }
        }
        if (addressObject.address1) {
            $('input[name$=_address1]', form).val(addressObject.address1);
        }
        if (addressObject.address2) {
            $('input[name$=_address2]', form).val(addressObject.address2);
        }
        if (addressObject.city) {
            $('input[name$=_city]', form).val(addressObject.city);
        }
        if (addressObject.postalCode) {
            $('input[name$=_postalCode]', form).val(addressObject.postalCode);
        }
        if (addressObject.stateCode) {
            $('select[name$=_stateCode],input[name$=_stateCode]', form)
                .val(addressObject.stateCode);
        }

        if (countryCode && typeof countryCode === 'object') {
            $('select[name$=_country]', form).val(addressObject.countryCode.value);
        } else {
            $('select[name$=_country]', form).val(addressObject.countryCode);
        }
        $('.shippingCountry, .billingCountry').val('US');

        if (addressObject.phone) {
            $('input[name$=_phone]', form).data('cleave').setRawValue(addressObject.phone);
        }

        if (addressObject.isGift) {
            $('input[name$=_isGift]', form).prop('checked', addressObject.isGift);
            if (addressObject.giftMessage) {
                $('textarea[name$=_giftMessage]', form).val(addressObject.isGift && addressObject.giftMessage ? addressObject.giftMessage : '');
            }
        }
    });

    $('body').trigger('shipping:updateShippingAddressFormValues', { shipping: shipping });
}


/**
 * updates the order shipping summary for an order shipment model
 * @param {Object} shipping - the shipping (shipment model) model
 * @param {Object} order - the order model
 */
function updateShippingSummaryInformation(shipping, order) {
    $('[data-shipment-summary=' + shipping.UUID + ']').each(function (i, el) {
        var $container = $(el);

        var $methodTitle = $container.find('.shipping-method-title');
        var $methodPrice = $container.find('.shipping-method-price');


        var selectedShippingMethod = shipping.selectedShippingMethod;

        if (selectedShippingMethod && order.containsStorePickup) {
            $methodTitle.text('Ship to Store');
            $methodPrice.text('$0.00');
        }

    });

}

/**
 * Create modal content
 * @param {string} selectedAddress selected address from address validatiom service
 * @param {string} enteredAddress address from input fields
* @param {string} shipmentID address id
 */
function getModalHtmlElement(selectedAddress, enteredAddress, shipmentID) {
    if ($('#editAddressModal').length !== 0) {
        $('#editAddressModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="editAddressModal" tabindex="-1" role="dialog">'
        + '<div class="modal-dialog" role="dialog" aria-labelledby="dialog-title" aria-describedby="dialog-description" style="max-width: 700px">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '<h5 class="modal-title"><p class="font-weight-bold">Address Verification</p> To ensure accurate delivery, consider the changes highlighted below:</h5>'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body">'
        + '<form>';
    selectedAddress.forEach(function (item) {
        htmlString += "<input type='radio' name='address' id='rec' class='rec mr-2' value='" + item.id + "' data-nocall='" + item.nocall + "' checked='checked'><span class='font-weight-bold text-success'> Recommended Options </span> - " + item.text + ""
            + "<br> </br>";
    });
    htmlString += '<input type="radio" name="address" id="new" class="new mr-2" value="new"><span class="font-weight-bold"> Original Address </span> - ' + enteredAddress + ''
        + '</form>'
        + '</div>'
        + '<div class="modal-footer d-flex flex-row justify-content-start">'
        + '<button type="button" class="btn btn-primary" id="confirm-edit" data-addressid="' + shipmentID + '" data-dismiss="modal">Confirm</button>'
        + '</div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}


function handleEDQModal(edqData, url, multishipForm, onlyValidation) {
    if (!$('.single-shipping').is(':visible') || !edqData.options) {
        return;
    }

    var form = multishipForm || $('.single-shipping').find('form');

    form.find("#shippingCountrydefault").val('US');

    var enteredAddress = form.find('input[name$=_address1]').val() + ' '
        + form.find('input[name$=_address2]').val() + ', '
        + form.find('input[name$=_city]').val() + ' '
        + form.find('select[name$=_stateCode] option:selected').text() + ' '
        + form.find('input[name$=_postalCode]').val();


    var shipmentID = form.find('.card').first().attr('data-shipment-uuid');
    getModalHtmlElement(edqData.options, enteredAddress, shipmentID);

    $('#editAddressModal').modal('show');

    $('#editAddressModal').on('shown.bs.modal', function () {

        $('#confirm-edit').on('click', function (e) {
            e.preventDefault();
            $('.shipping-address-block').attr('data-addressvalidedq', 'true');
            var addressID = $(this).attr('data-addressid');
            var card = $('.card[data-shipment-uuid="' + addressID + '"');

            if ($('input[id=rec]:checked').length > 0 && card) {
                var shippingForm = form;

                var rec = $('input[id=rec]:checked');
                if (rec.attr('data-nocall') && rec.attr('data-nocall') != 'undefined') {
                    var addressData = JSON.parse(rec.val());
                    if (addressData) {
                        shippingForm.find('.shippingAddressOne').val(addressData.address1);
                        shippingForm.find('.shippingAddressTwo').val(addressData.address2);
                        shippingForm.find('.shippingState').val(addressData.stateCode);
                        shippingForm.find('.shippingAddressCity').val(addressData.city);
                        shippingForm.find('.shippingZipCode').val(addressData.zipCode);
                        shippingForm.find('.shippingCountry, .billingCountry').val('US');
                    }
                    if (onlyValidation) {
                        $('.btn-save-address').trigger('click');
                    } else {
                        if (!$('#multiShipCheck').is(':checked')) {
                            $('.submit-shipping:visible').trigger('click')
                        } else {
                            shippingForm.find('.btn-save-multi-ship.save-shipment').first().trigger('click');
                        }
                    }

                    $('.modal-backdrop').remove();
                } else {
                    $.spinner().start();
                    $.ajax({
                        url: url,
                        type: 'get',
                        data: { recommendedAddr: rec.val() }
                    })
                        .done(function (data) {
                            if (data.address1 && data.postalCode) {
                                shippingForm.find('.shippingAddressOne').val(data.address1);
                                shippingForm.find('.shippingAddressTwo').val(data.address2);
                                shippingForm.find('.shippingState').val(data.stateCode);
                                shippingForm.find('.shippingAddressCity').val(data.city);
                                shippingForm.find('.shippingZipCode').val(data.postalCode);
                                shippingForm.find('.shippingCountry, .billingCountry').val('US');
                            }
                            $.spinner().stop();
                            if ($('#multiShipCheck') && !$('#multiShipCheck').is(':checked')) {
                                $('.submit-shipping:visible').trigger('click')
                            } else {
                                shippingForm.find('.btn-save-multi-ship.save-shipment').first().trigger('click');
                            }
                            $('.modal-backdrop').remove();
                        })
                        .fail(function () {
                            $.spinner().stop();
                        });
                }


            } else if (document.getElementById('new').checked && form && typeof form !== 'undefined') {
                $('#shippingAddressOnedefault').val(form.find('input[name$=_address1]').val())
                $('#shippingAddressTwodefault').val(form.find('input[name$=_address2]').val());
                $('#shippingStatedefault').val(form.find('select[name$=_stateCode] option:selected').val());
                $('#shippingAddressCitydefault').val(form.find('input[name$=_city]').val());
                $('#shippingZipCodedefault').val(form.find('input[name$=_postalCode').val());
                $('.shippingCountry, .billingCountry').val('US');
                if (!$('#multiShipCheck').is(':checked')) {
                    $('.submit-shipping:visible').trigger('click')
                } else {
                    shippingForm.find('.btn-save-multi-ship.save-shipment').first().trigger('click');
                }
                $('.modal-backdrop').remove();
            }
        })


    });
}

/**
 * Handle response from the server for valid or invalid form fields.
 * @param {Object} defer - the deferred object which will resolve on success or reject.
 * @param {Object} data - the response data with the invalid form fields or
 *  valid model data.
 */
function shippingFormResponse(defer, data) {
    var isMultiShip = $('#checkout-main').hasClass('multi-ship');
    clearVertexErrors();
    var formSelector = isMultiShip ? '.multi-shipping .active form' : '.single-shipping form';

    // highlight fields with errors
    if (data.error) {
        if (data.fieldErrors.length) {
            data.fieldErrors.forEach(function (error) {
                if (Object.keys(error).length) {
                    if ($(formSelector).attr('data-address-mode') == 'customer') {
                        $(formSelector).attr('data-address-mode', 'details');
                    }
                    formHelpers.loadFormErrors(formSelector, error);
                }
            });
            defer.reject(data);
        }

        if (data.severErrors && data.severErrors.length) {
            $.each(data.severErrors, function (index, element) {
                base.methods.createErrorNotification(element);
            });

            defer.reject(data);
        }

        if (data.cartError) {
            window.location.href = data.redirectUrl;
            defer.reject();
        }

        if (data.edq && !$('#editAddressModal').hasClass('show')) {

            var edqData = data.edq;
            var form = $('.single-shipping').find('form');
            var enteredAddress = form.find('input[name$=_address1]').val() + ','
                + form.find('input[name$=_address2]').val() + ','
                + form.find('input[name$=_city]').val() + ','
                + form.find('select[name$=_stateCode] option:selected').text() + ','
                + form.find('input[name$=_postalCode]').val();
            try {
                if (edqData.options[0] && edqData.options[0].id && edqData.options[0].id.indexOf('{') != -1) {
                    var defaultAddrObj = JSON.parse(edqData.options[0].id);
                    var defaultAddrStr = defaultAddrObj.address1 + ',' + defaultAddrObj.address2 + ',' + defaultAddrObj.city + ',' + defaultAddrObj.stateCode + ',' + defaultAddrObj.zipCode;
                    enteredAddress = enteredAddress.toUpperCase();
                    defaultAddrStr = defaultAddrStr.toUpperCase();
                    if (defaultAddrStr !== enteredAddress) {
                        handleEDQModal(data.edq, data.edqURL);
                        defer.reject(data);

                    }
                } else if (edqData.prompt) {
                    $('.edq-error').html(edqData.prompt).addClass('d-block');
                    defer.reject(data);
                }
            } catch (e) {
                console.log('invalid json');
            }

        }
    } else if (data.vertexError) {
        if (!data.vertexAddressSuggestions) {
            $('.vertexError').removeClass('d-none');
        } else {
            $('.vertexSuggestions').removeClass('d-none');
            $('#vertex-suggestion').html('');
            data.vertexAddressSuggestions.forEach(function (item, i, vertexAddressSuggestions) {
                var aid = item.UUID ? item.ID : item.key;
                if (i < 2) {
                    $('#vertex-suggestion').append(
                        $('<option></option>')
                            .attr('value', aid)
                            .text(
                                '(' +
                                aid +
                                ')' +
                                ' ' +
                                item.address1 +
                                ' ' +
                                item.city +
                                ' ' +
                                item.stateCode +
                                ' ' +
                                item.postalCode
                            )
                            .attr('data-address', JSON.stringify(item))
                    );
                }
            });
        }
    } else {
        // Populate the Address Summary
        $('.vertexError').addClass('d-none');
        var remainingPaymentAmount = data.remainingPaymentAmount;
        if (data.order.totals && data.order.totals.remainingPaymentAmount) {
            remainingPaymentAmount = data.order.totals.remainingPaymentAmount;
        }
        $('body').trigger('checkout:updateCheckoutView', {
            order: data.order,
            customer: data.customer,
            remainingPaymentAmount: remainingPaymentAmount
        });
        scrollAnimate($('.payment-form'));
        var multiShip = $('#multiShipCheck').length > 0 ? $('#multiShipCheck')[0].checked : false;
        if (!data.shippingBillingSame && !multiShip) {
            $(".shippingCountry, .billingCountry").val("US");
        }
        defer.resolve(data);
    }
}

function updateShippingSummaryEmail(shipping, order) {
    $('[data-shipment-summary=' + shipping.UUID + ']').each(function (i, el) {
        var $container = $(el);
        var $shippingEmail = $container.find('.address-email');
        if ($shippingEmail && order && order.orderEmail) {
            $shippingEmail.text(order.orderEmail);
        }
    });
}

/**
 * perform the proper actions once a user has clicked the multiship checkbox
 */
function shippingInfoUpdate() {
    var multiShipCheckBox = $('input[name="usingMultiShipping"]');
    if ($(multiShipCheckBox).is(':checked')) {
        var cards = $('.multi-shipping>.shipping-form>.card');
        var count = 1;
        $('.added-multiship-header').remove();
        cards.toArray().forEach(function (card) {
            var header = document.createElement('h3');
            $(header).addClass('added-multiship-header');
            $(header).addClass('card-header-custom');
            $(header).addClass('font-weight-bold');
            $(header).addClass('text-primary');
            $(header).addClass('text-dosis');
            if ($(card).hasClass('sts')) {
                $(header).text('Ship To Store #' + count);
            } else {
                $(header).text('Item #' + count);
            }
            $(card).find('.card-header>.product-line-item').prepend(header);
            count++;
        });

        $('.multi-shipping').removeClass('d-none');
        if ($('.mixcartLTL').length == 0) {
            $('.single-shipping>.shipping-form>.card>.card-body>.shipping-address').closest('.card').addClass('d-none');
        }
    } else {
        $('.single-shipping>.shipping-form>.card>.card-body>.shipping-address').closest('.card').removeClass('d-none');
        $('.multi-shipping').addClass('d-none');
        $('.added-multiship-header').empty();
    }
}

/* Does Ajax call to select shipping method
* @param {string} url - string representation of endpoint URL
* @param {Object} urlParams - url params
* @param {Object} el - element that triggered this call
*/
function selectShippingMethodAjax(url, urlParams, el) {
    $.spinner().start();
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: urlParams
    })
        .done(function (data) {
            if (data.error) {
                window.location.href = data.redirectUrl;
            } else {
                $('body').trigger('checkout:updateCheckoutView',
                    {
                        order: data.order,
                        customer: data.customer,
                        options: { keepOpen: true },
                        urlParams: urlParams
                    }
                );
                $('body').trigger('checkout:postUpdateCheckoutView',
                    {
                        el: el
                    }
                );
                if ($('.promise-dates').length > 0) {
                    $('#shippingStatedefault').trigger('change');
                }
            }
            $.spinner().stop();
        })
        .fail(function () {
            $.spinner().stop();
        });
}


var exports = {
    methods: {
        shippingFormResponse: shippingFormResponse,
        updateShippingSummaryEmail: updateShippingSummaryEmail,
        updateShippingAddressFormValues: updateShippingAddressFormValues,
        updateShippingInformation: updateShippingInformation,
        updatePLIShippingSummaryInformation: updatePLIShippingSummaryInformation,
        shippingInfoUpdate: shippingInfoUpdate,
        updateShippingAddressSelector: updateShippingAddressSelector,
        clearShippingForms: clearShippingForms,
        updateShippingMethodList: updateShippingMethodList
    },

    selectSingleShipAddress: function () {
        $('.single-shipping .addressSelector').on('change', function () {
            if ($('#checkout-main').data('customer-type') === 'guest') {
                return false;
            }
            var form = $(this).parents('form')[0];
            var selectedOption = $('option:selected', this);
            var attrs = selectedOption.data();
            var shipmentUUID = selectedOption[0].value;
            var originalUUID = $('input[name=shipmentUUID]', form).val();
            var element;
            Object.keys(attrs).forEach(function (attr) {
                element = attr === 'countryCode' ? 'country' : attr;
                $('[name$=' + element + ']', form).val(attrs[attr]);
            });
            $('[name$=stateCode]', form).trigger('change');
            if (shipmentUUID === 'new') {
                $(form).attr('data-address-mode', 'new');
                $(form).find('.shipping-address-block').removeClass('d-none');
            } else if (shipmentUUID === originalUUID) {
                $(form).attr('data-address-mode', 'customer');
                $('.shipment-selector-block').removeClass('d-none');
            } else if (shipmentUUID.indexOf('ab_') === 0) {
                $(form).attr('data-address-mode', 'customer');
            } else {
                $(form).attr('data-address-mode', 'edit');
            }
            if ($('.single-shipping-address').hasClass('mixcartLTL')) {
                $('.single-shipping-address').find('.btn-save-address').trigger('click');
            }

            $('.shipping-address-block').attr('data-addressvalidedq', 'true');
        });
    },

    bindClientSideValidationToMultiShipForm: function () {
        var multiShipCheck = $('input#multiShipCheck');
        multiShipCheck.off("change.multiShipCheck");
        multiShipCheck.on("change.multiShipCheck", function () {
            if (multiShipCheck.is(':checked')) {
                $('.btn-enter-multi-ship').off("click.multiShipCheck");
                $('.btn-enter-multi-ship').on("click.multiShipCheck", function () {
                    exports.formValidateShipping();
                });
            }
            else {
                exports.formValidateShipping();
            }
        });
    },

    formValidateShipping: function () {
        var formsSelected = $('[class*=shipping-address-block-index]')
        formsSelected.each(function () {
            clientSideValidation.init({
                continueSelector: 'button.submit-shipping',
                formSelector: "." + $(this).attr('class').replaceAll(' ', '.'),
                enableContinue: true,
                multipleForms: formsSelected.length > 1 ? true : false
            });
        });
    },

    selectSingleShipping: function () {
        $('body').on('shipping:selectSingleShipping', function () {
            $('.single-shipping .shipping-address').removeClass('d-none');
            $('.single-shipping .contact-content').removeClass('d-none');
            var url = $('#vertex-suggestions').data('suggest');
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: { clear: 'true' }
            });
        });
    },
    toggleMultiship: function () {
        var baseObj = this;
        shippingInfoUpdate();
        $('input[name="usingMultiShipping"]').on('change', function () {
            var elements = $('.line-item-option>span');
            var shipCardAddr = elements && elements.filter(function (elem) {
                if ($(elements[elem]).attr('id') && $(elements[elem]).attr('id').indexOf('shippingAddress-') > -1) {
                    return elem;
                }
            });
            shipCardAddr.empty();
            var shippingSection = $('.shipping-section');
            shippingSection.spinner().start();
            shippingInfoUpdate();
            var url = $('.multi-shipping-checkbox-block').attr('data-action');
            var usingMultiShip = this.checked;
            if (usingMultiShip) {
                $(".single-shipping-address").css("display", "none");
                $('#useShippingAsBilling').prop('checked', false).parent('span').css("display", "none");
            } else {
                $(".single-shipping-address").css("display", "block");
                $('.single-shipping>.shipping-form>.card>.card-body>.shipping-address').closest('.card').removeClass('d-none');
                $('#useShippingAsBilling').parent('span').css("display", "block");
            }

            var formShipping = $(this).parents('form') ? $(this).parents('form').serialize() : '';
            formShipping = formShipping + '&' + 'usingMultiShip=' + usingMultiShip;
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: formShipping,
                success: function (response) {
                    if (response.error) {
                        window.location.href = response.redirectUrl;
                    } else {
                        $('body').trigger('checkout:updateCheckoutView', {
                            order: response.order,
                            customer: response.customer
                        });

                        if ($('#checkout-main').data('customer-type') === 'guest') {
                        } else {
                            response.order.shipping.forEach(function (shipping) {
                                $('input[value=' + shipping.UUID + ']').each(function (formIndex, el) {
                                    var form = el.form;
                                    if (!form) return;

                                    $(form).attr('data-address-mode', 'edit');
                                    var addressSelectorDropDown = $(form).find('.addressSelector option[value="ab_' + shipping.matchingAddressId + '"]');
                                    $(addressSelectorDropDown).prop('selected', true);
                                    $('input[name$=_isGift]', form).prop('checked', false);
                                    $('textarea[name$=_giftMessage]', form).val('');
                                    $(form).find('.gift-message').addClass('d-none');
                                });
                            });
                        }

                        if (usingMultiShip) {
                            $('body').trigger('shipping:selectMultiShipping', { data: response });
                            $('.top-multiship-btn').removeClass('d-none');
                            $('.address-multiship-btn').addClass('d-none');
                        } else {
                            $('body').trigger('shipping:selectSingleShipping', { data: response });
                            $('.top-multiship-btn').addClass('d-none');
                            $('.address-multiship-btn').removeClass('d-none');
                        }
                    }

                    shippingSection.spinner().stop();
                },
                error: function () {
                    shippingSection.spinner().stop();
                },
                complete: function () {
                    if ($("#multiShipCheck").is(':checked')) {
                        $('.multi-shipping').find('.shipping-content').each(function () {
                            var shippingMethods = $(this).find('input[name$="_shippingAddress_shippingMethodID"]');
                            if (shippingMethods.length == 1 && !shippingMethods.is(':checked')) {
                                $(this).find('input[name$="_shippingAddress_shippingMethodID"]').trigger('click');
                            }
                        });
                    }
                }
            });
        });

        $(document).on('click', '#editShipping', function () {
            if ($("#multiShipCheck") && !$("#multiShipCheck").is(':checked') && $('.single-shipping-address').parents('.card').hasClass('d-none')) {
                $('.single-shipping-address').parents('.card').removeClass('d-none');
                $('.single-shipping-address').show();
            }
        });

        $(document).on('click', '.btn-save-address', function () {
            $('.multi-ship-error').addClass('d-none');

            //validate main address
            var form = $(this).closest('form');
            var $rootEl = $(this).closest('.shipping-content');
            var data = $(form).serialize();
            var url = $(this).attr('data-verifyurl');

            $rootEl.spinner().start();
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: data
            }).done(function (response) {
                formHelpers.clearPreviousErrors(form);
                if (response.error) {
                    if (response.edq) {
                        handleEDQModal(response.edq, response.edqURL, form, true);
                    }
                    if (response.fieldErrors) {
                        formHelpers.loadFormErrors(form, response.fieldErrors);
                    } else if (response.serverErrors && response.serverErrors.length) {
                        $.each(response.serverErrors, function (index, element) {
                            base.methods.createErrorNotification(element);
                        });
                    }
                } else {
                    var form = $('.single-shipping-address').parents('form');
                    var validForm = clientSideValidation.helperFunctions.validateFormBeforeSubmit($(form)[0]);
                    if (validForm) {
                        var singleShippingForm = $('.single-shipping-address').parents('form');
                        $(".btn-edit-multi-ship").parents('.shipping-content').each(function () {
                            var address = $(this).find('.shipping-address').not('.single-shipping-address');
                            if (address.length > 0) {
                                address.find('input[name$=_firstName]').val(singleShippingForm.find('input[name$=_firstName]').last().val());
                                address.find('input[name$=_lastName]').val(singleShippingForm.find('input[name$=_lastName]').last().val());
                                address.find('input[name$=_address1]').val(singleShippingForm.find('input[name$=_address1]').val());
                                address.find('input[name$=_address2]').val(singleShippingForm.find('input[name$=_address2]').val());
                                address.find('input[name$=_city]').val(singleShippingForm.find('input[name$=_city]').val());
                                address.find('input[name$=_postalCode]').val(singleShippingForm.find('input[name$=_postalCode]').val());
                                address.find('select[name$=_stateCode],input[name$=_stateCode]').val(singleShippingForm.find('select[name$=_stateCode],input[name$=_stateCode]').val());
                                address.find('select[name$=_country]').val(singleShippingForm.find('select[name$=_country]').val());
                                $(this).find('.btn-save-multi-ship').first().trigger('click');
                            }


                        });
                        setTimeout(function () {
                            $('.shipping-content, .multi-ship-msg').removeClass('d-none');
                        }, 2000);

                    } else {
                        if (form.find('.is-invalid')) {
                            var scrollTo = $('.single-shipping-address').parents('form').find('.is-invalid');
                            $('html,body').animate({ scrollTop: scrollTo.offset().top });
                        }
                    }
                }

                if (response.order && response.order.shippable) {
                    $('button.submit-shipping').attr('disabled', null);
                }

                $rootEl.spinner().stop();
            })
                .fail(function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    }

                    $rootEl.spinner().stop();
                });

            return false;

        });
    },
    editMultiShipInfo: function () {
        var baseObj = this;
        $('.btn-edit-multi-ship').on('click', function (e) {
            e.preventDefault();

            if (base.methods && base.methods.editOrEnterMultiShipInfo) {
                base.methods.editOrEnterMultiShipInfo($(this), 'edit');
            } else {
                editOrEnterMultiShipInfo($(this), 'edit');
            }
        });
    },

    selectShippingMethod: function () {
        $('.shipping-method-list').change(function () {
            var $shippingForm = $(this).parents('form');
            var methodID = $(':checked', this).val();
            var shipmentUUID = $shippingForm.find('[name=shipmentUUID]').val();
            var urlParams = addressHelpers.methods.getAddressFieldsFromUI($shippingForm);
            urlParams.shipmentUUID = shipmentUUID;
            urlParams.methodID = methodID;
            urlParams.isGift = $shippingForm.find('.gift').prop('checked');
            urlParams.giftMessage = $shippingForm.find('textarea[name$=_giftMessage]').val();

            var url = $(this).data('select-shipping-method-url');
            selectShippingMethodAjax(url, urlParams, $(this));
        });
    },
    saveMultiShipInfo: function () {
        var baseObj = this;

        $('.btn-save-multi-ship').on('click', function (e) {
            e.preventDefault();

            // Save address to checkoutAddressBook
            var form = $(this).closest('form');
            var $rootEl = $(this).closest('.shipping-content');
            var data = $(form).serialize();
            if ($('#phoneNumber').length > 0) {
                var inputNumber = $('#phoneNumber')
                data += '&' + inputNumber.attr('name') + '=' + inputNumber.val()
            }
            if ($('#email').length > 0) {
                var inputEmail = $('#email')
                data += '&' + inputEmail.attr('name') + '=' + inputEmail.val()
            }
            var url = $(form).attr('action');
            var vertexErrorBlock = $($rootEl)
                .parent()
                .find('.vertexError');
            var vertexSuggestionBlock = $($rootEl)
                .parent()
                .find('.vertexSuggestions');
            var vertexAddresses = $($rootEl)
                .parent()
                .find('#vertex-suggestion');

            clearVertexErrors();
            if (localStorage.getItem('eventFromLoad') && localStorage.getItem('eventFromLoad') != null) {
                data = data + '&skipvalidation=true';
                localStorage.removeItem('eventFromLoad');
            }
            form.find('.edq-error').html('').removeClass('d-block')
            $rootEl.spinner().start();
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: data
            }).done(function (response) {
                formHelpers.clearPreviousErrors(form);
                if (response.error) {
                    var EDQmodalFlag = true;
                    if (response && response.fieldErrors) {
                        EDQmodalFlag = false;
                    }
                    if (response.edq && EDQmodalFlag) {
                        handleEDQModal(response.edq, response.edqURL, form);
                    }
                    if (response.fieldErrors) {
                        formHelpers.loadFormErrors(form, response.fieldErrors);
                    } else if (response.serverErrors && response.serverErrors.length) {
                        $.each(response.serverErrors, function (index, element) {
                            base.methods.createErrorNotification(element);
                        });
                    }
                } else if (response.vertexError) {
                    if (!response.vertexAddressSuggestions) {
                        vertexErrorBlock.removeClass('d-none');
                    } else {
                        vertexSuggestionBlock.removeClass('d-none');
                        $(vertexAddresses).html('');
                        response.vertexAddressSuggestions.forEach(function (item, i, vertexAddressSuggestions) {
                            var aid = item.UUID ? item.ID : item.key;
                            if (i < 2) {
                                $(vertexAddresses).append(
                                    $('<option></option>')
                                        .attr('value', aid)
                                        .text(
                                            '(' +
                                            aid +
                                            ')' +
                                            ' ' +
                                            item.address1 +
                                            ' ' +
                                            item.city +
                                            ' ' +
                                            item.stateCode +
                                            ' ' +
                                            item.postalCode
                                        )
                                        .attr('data-address', JSON.stringify(item))
                                );
                            }
                        });
                    }
                } else {
                    // Update UI from response
                    $('body').trigger('checkout:updateCheckoutView', {
                        order: response.order,
                        customer: response.customer
                    });

                    if (baseObj.methods && baseObj.methods.viewMultishipAddress) {
                        baseObj.methods.viewMultishipAddress($rootEl);
                    } else {
                        viewMultishipAddress($rootEl);
                    }

                    if (response.order && response.order.hasMixLTLCart) {
                        $('.single-shipping-address').removeClass('d-none');
                    }
                }

                if (response.order && response.order.shippable) {
                    $('button.submit-shipping').attr('disabled', null);
                }

                $rootEl.spinner().stop();
            })
                .fail(function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    }

                    $rootEl.spinner().stop();
                });

            return false;
        });
    },

    updateShippingList: function () {
        var baseObj = this;
        $('.btn-edit-multi-ship')
            .on('click', function (e) {
                var targetForm = $(e.currentTarget.form);
                var startLines = targetForm.find('.card-body>.shipping-address>.shipping-method-block>.leading-lines>.start-lines')
                var endLines = targetForm.find('.card-body>.shipping-address>.shipping-method-block>.leading-lines>.end-lines')

                if (startLines && startLines.hasClass('d-none')) {
                    startLines.removeClass('d-none');
                }

                if (endLines && endLines.hasClass('d-none')) {
                    endLines.removeClass('d-none');
                }
            });

        $('select[name$="dwfrm_shipping_shippingAddress_addressFields_states_stateCode"]')
            .on('change', function (e) {
                if (baseObj.methods && baseObj.methods.updateShippingMethodList) {
                    baseObj.methods.updateShippingMethodList($(e.currentTarget.form));
                } else {
                    updateShippingMethodList($(e.currentTarget.form));
                }
            });
    },


    vertexSuggestions: function () {
        $('select[name="vertex-suggestion"]').change(function (item) {
            var form = $(this)
                .closest('.shipping-form')
            fillAddressSuggestion(
                $(this)
                    .find('option:selected')
                    .data('address'),
                form
            );
            // need to remember as is address2 so we will write it
            var address2AsIs = '';
            if (form.find('#shippingAddressTwo').val() !== '') {
                address2AsIs = form.find('#shippingAddressTwo').val();
            }
            var selectedAddress = $('select[name="vertex-suggestion"]').val();
            // if address was picked from service we blank address 2 line
            if (selectedAddress === 'Use Address As-Is') {
                $('#shippingAddressTwo').val(address2AsIs);
            } else {
                $('#shippingAddressTwo').val('');
            }
        });
    },

    poolLocationValueUpdate: function () {
        $('input:radio[name$=_poolLocation]').on('click', function () {
            var $poolLocations = $('input:radio[name$=_poolLocation]');
            var $selectedLocation = $(this).val();
            $poolLocations.each(function (locIndex) {
                if ($poolLocations[locIndex].value === $selectedLocation) {
                    $poolLocations[locIndex].setAttribute('checked', 'checked');
                } else {
                    $poolLocations[locIndex].setAttribute('checked', '');
                }
            });
        })
    },

    poolLocationSummaryUpdate: function () {
        $('body').on('shipping:updateShippingInformation', function () {
            var $poolLocations = $('input:radio[name$=_poolLocation]');
            $poolLocations.each(function (index) {
                if ($poolLocations[index].checked) {
                    $('span.pool-location').text($poolLocations[index].value + ' Pool');
                }
            })
        })
    },

    showPickupPersonForm: function () {
        $('.assign-pickup-person').on('click', function (e) {
            e.preventDefault();
            $('.pickup-person-form, .clear-pickup-btn').toggleClass('d-none');
            $(this).toggleClass('active');
            if ($(this).hasClass('active')) {
                $('.pickup-person-form input').attr('required', 'required')
            } else {
                $('.pickup-person-form input').removeAttr('required');
            }
        });
    },

    updatePickupPerson: function () {
        $('body').on('shipping:updateShippingInformation', function (e, data) {
            if ((data.order.containsShipToStore || data.order.containsStorePickup) && (data.order.authorizedPickupPersonFirstName || data.order.authorizedPickupPersonLastName)) {
                $('.pickup-person-name').html(data.order.authorizedPickupPersonFirstName + ' ' + data.order.authorizedPickupPersonLastName);
                $('.pickup-person-col').removeClass('d-none');
            } else {
                $('.pickup-person-col').addClass('d-none');
            }
        });

        $('.clear-pickup-btn').on('click', function () {
            $('.pickup-person-form input').val('');
            $('.invalid-feedback.d-block').empty();
            $('.invalid-feedback#phoneInvalidMessage').empty();
            $('.shippingPickUpFirstName').removeClass('is-invalid');
            $('.shippingPickupLastName').removeClass('is-invalid');
            $('.pickupEmail').removeClass('is-invalid');
            $('.pickupPhone').removeClass('is-invalid');

        });

        $('#pickupPhoneNumber').focus(function () {
            var phoneNum = ($(this).val());
            var res = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(phoneNum);
            if (res) {
                $(this).removeClass('is-invalid');
                $('.submit-shipping').prop('disabled', false);
                $(this).closest('.form-group').find('#phoneInvalidMessage').text('');

            }
        }).blur(function () {
            var phoneNum = ($(this).val());
            var res = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(phoneNum);
            if (!res) {
                $(this).addClass('is-invalid');
                $('.submit-shipping').prop('disabled', true);
                $(this).closest('.form-group').find('#phoneInvalidMessage').text('Please enter a valid Phone number.');
            }
        });

        $('#shippingPickUpFirstNamedefault, #shippingPickupLastNamedefault, #pickupEmail').focus(function () {
            $(this).closest('.form-group').find('.invalid-feedback').removeClass('d-block');
            $(this).closest('.form-group').find('.form-control').removeClass('is-invalid');
            $('.submit-shipping').prop('disabled', false);
        }).blur(function (e) {
            var res = /^[a-zA-Z]+$/.test($(this).val());
            if (!res) {
                e.preventDefault();
                $(this).closest('.form-group').find('.form-control').addClass('is-invalid');
                $(this).closest('.form-group').find('.invalid-feedback').addClass('d-block');
                $('.submit-shipping').prop('disabled', true);
            }
        });
    },

    edqAddressValidationShipping: function () {
        $('body').on('keyup', 'input[name$=_address1], input[name$=_address2],input[name$=_city],input[name$=_postalCode],select[name$=_stateCode]', function () {
            $('.shipping-address-block').attr('data-addressvalidedq', 'false');
        });
    },

    checkForSavedMethodsOnLoad: function () {
        $('body').spinner().start();
        if (window.location.href.indexOf('payment') > -1) {
            $('.omni-card-ship-addr').toArray().forEach(function (shipAddr) {
                $(shipAddr).remove();
            });
        }

        window.addEventListener('load', function () {
            $(".btn-edit-multi-ship").parents('.shipping-content').each(function () {
                var address = $(this).find('.shipping-address').not('.single-shipping-address');
                var addressSelector = address.find('.addressSelector').not('#shipmentSelector-default');
                if (address.length > 0 && address.find('.shippingFirstName').length > 0 && address.find('.shippingFirstName').val() != '' && $('#multiShipCheck').is(':checked')) {
                    localStorage.setItem('eventFromLoad', true);
                    $(this).find('.btn-save-multi-ship').first().trigger('click');
                } else if (address && address.find('.shippingFirstName').length > 0 && address.find('.shippingFirstName').val() == '' && address.hasClass('mixcartLTL')) {
                    address.parents('.shipping-content').addClass('d-none');
                }
            });

            $('.omni-card-ship-addr').toArray().forEach(function (shipAddr) {
                $(shipAddr).remove();
            });

            if ($('#shipmentSelector-default').val() != 'new') {
                $('#shipmentSelector-default').trigger('change');
            }
        });
        $('body').spinner().stop();
    },

    sanitizeShippingForm: function () {
        $('body').on('checkout:serializeShipping', function (e, data) {
            var serializedForm = cleave.serializeFormData(data.form);
            data.callback(serializedForm);
        });
    },
};


function checkForSavedMethods(isToggle) {
    var isExistingShipment = $('.shipment-selector-block>.form-group>.addressSelector').not('#shipmentSelector-default');
    var count = 0;
    var savedAddresses = isExistingShipment && isExistingShipment.toArray() && isExistingShipment.toArray().forEach(function (opt) {
        var shipAddr = $(opt.closest('.shipping-address'));
        var csa = completedShipAddress(shipAddr) || false;
        if (!!shipAddr && (!!isToggle === false)) {
            var shipAttr = [];
            if (shipAddr) {
                if (csa) {
                    shipAddr.addClass('d-none');
                    $(shipAddr).closest('.shipping-content').find('.btn-save-multi-ship').trigger('click');
                    $(shipAddr).closest('.shipping-content').find('.btn-save-multi-ship').addClass('d-none');
                } else if (shipAddr.hasClass('d-none') || isToggle) {
                    shipAddr.removeClass('d-none');
                }
            }
        } else if (isToggle && !completedShipAddress(shipAddr)) {
            $(shipAddr).closest('.shipping-content').find('.btn-enter-multi-ship').trigger('click');
        }
        count++;
        if (count >= isExistingShipment.length) {
            return;
        }
        $(shipAddr).closest('.card-body.shipping-content').find('.multi-ship-action-buttons>.btn-enter-multi-ship').addClass('d-none');
        $(shipAddr).closest('.card-body.shipping-content').find('.multi-ship-action-buttons>.btn-save-multi-ship.save-shipment').removeClass('d-none');
        if (csa) {
            $(shipAddr).closest('.shipping-content').find('.btn-save-multi-ship').addClass('d-none');
        }
    });

}

function completedShipAddress(shipAddr) {
    var rtnVal = false;
    var fn = shipAddr && shipAddr.find('input.shippingFirstName') && shipAddr.find('input.shippingFirstName').val();
    var ln = shipAddr && shipAddr.find('input.shippingLastName') && shipAddr.find('input.shippingLastName').val();
    var addr = shipAddr && shipAddr.find('input.shippingAddressOne') && shipAddr.find('input.shippingAddressOne').val();
    var country = "US";
    var state = shipAddr && shipAddr.find('select.shippingState') && shipAddr.find('select.shippingState').val();
    var city = shipAddr && shipAddr.find('input.shippingAddressCity') && shipAddr.find('input.shippingAddressCity').val();
    var zip = shipAddr && shipAddr.find('input.shippingZipCode') && shipAddr.find('input.shippingZipCode').val();
    rtnVal = !!(fn && fn.trim().length && fn.trim().length > 0 && ln && ln.trim().length && ln.trim().length > 0 && addr && addr.trim().length && addr.trim().length > 0
        && country && country.trim().length && country.trim().length > 0 && state && state.trim().length && state.trim().length > 0 && city
        && city.trim().length && city.trim().length > 0 && zip && zip.trim().length && zip.trim().length > 0);

    return rtnVal;
}

function fillAddressSuggestion(address, form) {
    $('.btn-show-details').trigger('click');

    var url = $('#vertex-suggestions').data('suggest');
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json'
    });
    $(form)
        .find('input[name$=_address1]')
        .val(address.address1);
    if (address.address2) {
        $(form)
            .find('input[name$=_address2]')
            .val(address.address2);
    }
    $(form)
        .find('input[name$=_city]')
        .val(address.city);
    $(form)
        .find('input[name$=_postalCode]')
        .val(address.postalCode);
    $(form)
        .find('select[name$=_stateCode],input[name$=_stateCode]')
        .val(address.stateCode);
    $(".shipping-form")
        .find('.shippingCountry')
        .val('US');
}

function clearVertexErrors() {
    $('.vertexError').addClass('d-none');
    $('.vertexSuggestions').addClass('d-none');
}
function closeAllAddressSuggestions() {
    var edqSuggestionBoxLength = document.querySelectorAll("#edq-verification-suggestion-box").length;
    for (var i = 0; i < edqSuggestionBoxLength; i++) {
        var edqSuggestionBox = document.querySelector("#edq-verification-suggestion-box");
        if (edqSuggestionBox) {
            edqSuggestionBox.parentNode.removeChild(edqSuggestionBox);
        }
    }
}

$(document).ready(function () {


    //multi shipping
    $(document).on('focus', '.shippingAddressOne', function () {
        // set default country
        var shippingForm = document.getElementsByClassName("shipping-form");
        if (shippingForm.length) {
            var shippingCountry = shippingForm[0].getElementsByClassName("shippingCountry");
            if (shippingCountry.length) {
                shippingCountry[0].value = "US";
            }
        }

        var form = $(this).parents('form');
        var head = document.getElementsByTagName("head")[0];
        var unicorn = document.createElement("script");

        unicorn.setAttribute("type", "text/javascript");
        unicorn.setAttribute("src", "https://edqprofservus.blob.core.windows.net/assets/dev/global-intuitive-unicorn.js");
        head.appendChild(unicorn);

        window.EdqConfig.GLOBAL_INTUITIVE_MAPPING = [
            {
                field: document.querySelector('.shippingAddressOne'),
                elements: ['address.addressLine1']
            },
            {
                field: document.querySelector('.shippingAddressTwo'),
                elements: ['address.addressLine2']
            },
            {
                field: document.querySelector('.shippingAddressCity'),
                elements: ['address.locality']
            },
            {
                field: document.querySelector('.shippingState'),
                elements: ['address.province']
            },
            {
                field: document.querySelector('.shippingZipCode'),
                elements: ['address.postalCode']
            },
        ];
        if (typeof GlobalIntuitiveUnicorn !== "undefined") {
            try {
                var GlobalIntuitive = new GlobalIntuitiveUnicorn(window.EdqConfig || {});
                GlobalIntuitive.activateValidation(document.getElementById(form.find('.shippingAddressOne').attr('id')));
            } catch (err) {
                console.log("Failure to load class GlobalIntuitiveUnicorn for address suggestions", err);
            }
        }
    });
    //fix suggestion box staying
    $('.shippingAddressOne').focusout(closeAllAddressSuggestions);
    $('.shippingCountry, .shippingState, .shippingAddressCity, .shippingZipCode').focusin(closeAllAddressSuggestions);
    $('.submit-shipping').click(closeAllAddressSuggestions);
});

$(window).on('load', function () {
    //Payment Method Suggestion
    $(document).on('focus', '.billingAddressOne', function () {
        var form = $(this).parents('form');
        $('.billingCountry').val('US');
        window.EdqConfig.GLOBAL_INTUITIVE_MAPPING = [
            {
                field: document.getElementById(form.find('.billingAddressOne').attr('id')),
                elements: ['address.addressLine1']
            },
            {
                field: document.getElementById(form.find('.billingAddressTwo').attr('id')),
                elements: ['address.addressLine2']
            },
            {
                field: document.getElementById(form.find('.billingAddressCity').attr('id')),
                elements: ['address.locality']
            },
            {
                field: document.getElementById(form.find('.billingState').attr('id')),
                elements: ['address.province']
            },
            {
                field: document.getElementById(form.find('.billingZipCode').attr('id')),
                elements: ['address.postalCode']
            },
        ];
        window.EdqConfig.GLOBAL_INTUITIVE_ELEMENT = document.getElementById(form.find('.billingAddressOne').attr('id'));
        if (typeof GlobalIntuitiveUnicorn !== "undefined") {
            var GlobalIntuitive = new GlobalIntuitiveUnicorn(window.EdqConfig || {});
            GlobalIntuitive.activateValidation(document.getElementById(form.find('.billingAddressOne').attr('id')));
        }
    });
});

//removing error when changing the address for registred user
$(".addressSelector").change(function(){
    var form = $('.single-shipping-address').parents('form');
    $(form).find('.form-control.is-invalid').removeClass('is-invalid');
    $(form).find('.form-group.custom-invalidation').removeClass('custom-invalidation');
                
});

var shipping = $.extend(true, {}, base, exports);

module.exports = shipping;
