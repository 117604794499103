'use strict';
var defaultOptions = require('./../config/globalCarousel.json');
var carouselSelector = '.js-global-carousel';
var carouselHelper = require('./carouselHelper')

/**
 * Gets scrollbar width
 * @param {jQuery} $element - $element
 * @returns {Object} options
 */
function getOption($element) {
    return $.extend(true, {}, defaultOptions, $element.data('carouselOptions') || {});
}

/**
 * @description Inits one carousel instance
 * @param {jQuery} $carousel, carousel jQuery object
 */
function initCarousel($carousel) {
    var options = getOption($carousel);
    if ($carousel.length) {
        carouselHelper.preInit($carousel);
        $carousel.slick(options);
        carouselHelper.initEvents($carousel);
    }
}

/**
 * init all carousels
 * @param {jQuery} $container - $element
 */
function initAllCarousels($container) {
    $($container || carouselSelector).each(function () {
        initCarousel($(this));
    });
}

/**
 * init events for carousels
 * @param {jQuery} $element - $element
 */
function initEvents() {
    $(document).on('carousel:reinit', function (event, data) {
        initAllCarousels(data.$container);
    });
}

/**
 * init carousel
 */
function init() {
    if ($('.js-global-carousel').is('*')) {
        initAllCarousels();
        initEvents();
    }
}

module.exports = {
    initAllCarousels: initAllCarousels,
    init: init
};
