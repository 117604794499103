'use strict';

var scrollAnimate = require('base/components/scrollAnimate');

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
function displayMessage(data, button) {
    $.spinner().stop();
    var status;
    if (data.success) {
        status = 'alert-success';
        $('body').trigger('email:subscribeSuccess');
    } else {
        status = 'alert-danger';
        $('.email-signup').attr('data-error', data.msg);
        $('body').trigger('email:subscribeError');
    }

    if ($('.email-signup-message').length === 0) {
        $('body').append('<div class="email-signup-message"></div>');
    }
    $('.email-signup-message').append(
        '<div class="email-signup-alert text-center ' + status + '">' + data.msg + '</div>'
    );

    setTimeout(function () {
        $('.email-signup-message').remove();
        button.removeAttr('disabled');
    }, 3000);
}

module.exports = function () {
    $('.back-to-top').click(function () {
        scrollAnimate();
    });

    $('.subscribe-email').on('click', function (e) {
        e.preventDefault();
        var url = $(this).data('href');
        var button = $(this);
        var emailId = $('input[name=hpEmailSignUp]').val();
        $.spinner().start();
        $(this).attr('disabled', true);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                emailId: emailId
            },
            success: function (data) {
                displayMessage(data, button);
                $('body').trigger('formSubmit:submit', !data.success);
                if (crdl) {
                    let auth_data = { email: emailId };
                    let data = { 'channels.email.subscribeStatus': 'subscribed' };
                    crdl('contact', auth_data, data);
                }
            },
            error: function (err) {
                displayMessage(err, button);
            }
        });
    });
    $('body').on('keyup', '.carousel-inner .carousel-item a, .carousel-inner a', function (e) {
        var code = e.keyCode ? e.keyCode : e.which;
        if (code == 9) {
            $(this).parents('.carousel').carousel('pause');
        }
    });

    setTimeout(function () {
        $('body iframe').removeAttr('frameborder').css('border', 'none');
    }, 1000);
};
