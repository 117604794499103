'use strict';

module.exports = {
    applyAffirm: function () {
        $(document).on('affirm:update', function () {
            try {
                if (window.affirm && window.affirm.ui && typeof window.affirm.ui.refresh !== 'undefined') {
                    window.affirm.ui.refresh();
                }
            } catch (error) {
                //ie does not support affirm.ui.refresh() and causes checkout to freeze
                window.location.reload();
            }
        });
    }
};
