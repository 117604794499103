var base = require('../product/base');
/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    var messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast
    if (response.newBonusDiscountLineItem && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else {
        if ($('.add-to-cart-messages').length === 0) {
            $('body').append('<div class="add-to-cart-messages"></div>');
        }

        $('.add-to-cart-messages').append(
            '<div class="alert ' +
            messageType +
            ' add-to-basket-alert text-center" role="alert">' +
            response.message +
            '</div>'
        );

        setTimeout(function () {
            $('.add-to-basket-alert').remove();
        }, 5000);
    }
}

module.exports = {
    addToCart: function () {
        $('body').on('click', '.js-quick-buy-add-to-cart', function () {
            $('body').trigger('product:beforeAddToCart', this);
            $('.qb-btn').dropdown('hide');

            var addToCartUrl = $(this).data('action') + '?isQuickBuy=true';

            var pid = $('.js-quick-buy-sku').val();

            var qty = $('.js-quick-buy-qty').val();

            var form = {
                pid: pid,
                quantity: qty,
                options: '[]'
            };

            $(this).trigger('updateAddToCartFormData', form);

            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success: function (data) {
                        handlePostCartAdd(data);

                        if (!data.error) {
                            $('body').trigger('product:afterAddToCart', data);
                            base.miniCartReportingUrl(data.reportingURL);
                            $('.js-quick-buy-qty').val(1);
                            $('.js-quick-buy-sku').val('');
                        }
                        $.spinner().stop();
                        if ($('.js-page.page').length && $('.js-page.page').data('action') !== undefined && $('.js-page.page').data('action') == 'Cart-Show') {
                            location.reload();
                        }
                    },
                    error: function (res) {
                        console.log(res);
                        $.spinner().stop();
                        // spit out generic fail message
                        if ($('.add-to-cart-messages').length === 0) {
                            $('body').append('<div class="add-to-cart-messages"></div>');
                        }

                        $('.add-to-cart-messages').append(
                            '<div class="alert alert-danger add-to-basket-alert text-center" role="alert">' +
                            'Product ID not found. Please check product ID and try again.' +
                            '</div>'
                        );

                        setTimeout(function () {
                            $('.add-to-basket-alert').remove();
                        }, 5000);
                    }
                });
            }
        });
    }
};
