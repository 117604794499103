'use strict';
var formValidation = require('base/components/formValidation');

$(document).on('click', '#requestPasswordResetModal .close', e => {
    $('#requestPasswordResetModal').modal('hide');
    $('#loyaltySignInModalCheckout').css({'overflow-x':'hidden','overflow-y':'auto'});
});
module.exports = {
    loginFlyout: function () {
        var closeFlyout = function (e) {
            $('.flyout-sidenav').removeClass('show-flyout');
            $("body").removeClass("stop-scroll");
            $(".show-overlay").css({ width: "0", opacity: "0" });
        };
        var openFlyout = function (elementId) {
            $("body").addClass("stop-scroll");
            $('.flyout-sidenav').removeClass('show-flyout');
            $(elementId).addClass("show-flyout");
            $(".show-overlay").css({ width: "100%", opacity: "0.8" });
        };
        $(document).ready(function () {
            let isDesktopView = $(window).width() >= 768;
            if (isDesktopView) {
                let headerHt = $('header').outerHeight();
                $('.flyout-sidenav').css({ 'top': headerHt, 'height': 'calc(100% - ' + headerHt + 'px)' });
            }
        });
        $(document).on("click", '.open-flyout-login a, .open-flyout-login button, .open-flyout-login a, .open-flyout-login button, .header-flyout-login, .flyout-back-btn, .js-cart-signin-btn, .js-checkout-signin-btn, .js-cart-signin-btn', e => {
            e.preventDefault();
            if ($('#loginFlyoutContainer').length > 0) {
                openFlyout('#loginFlyoutContainer');
            } else if ($('.login-flyout-wrapper').length > 0) {
                const loginUrl = $('.header-flyout-login').data('url');
                $.spinner().start();
                $.ajax({
                    url: loginUrl,
                    type: 'get',
                    dataType: 'html',
                    success: function (data) {
                        $.spinner().stop();
                        $('.login-flyout-wrapper').html(data);
                        openFlyout('#loginFlyoutContainer');
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        });
        $(document).on("click", '#register-flyout-open', e => {
            e.preventDefault();
            openFlyout('#registerFlyoutContainer');
            $('body').trigger('formOpener:open', 'create account');
        });
        $(document).on("click", '.close-flyout-btn', e => {
            e.preventDefault();
            closeFlyout(e);
        });
        $('#loginFlyoutOverlay').on("click", e => {
            e.preventDefault();
            closeFlyout(e);
        });
        $(document).on("change", '#flyout-smsOptIn', e => {
            e.preventDefault();
            $('.flyout-registration').find('.flyout-sms-mobile-field').toggleClass('d-none').toggleClass('required');
            if ($('.flyout-registration').find('.flyout-sms-mobile-field').hasClass('required')) {
                $('.flyout-registration').find('#flyout-registration-form-phone').attr('required', 'required');
            } else {
                $('.flyout-registration').find('#flyout-registration-form-phone').removeAttr('required');
            }
        });
    },
    register: function () {
        $(document).on("submit", 'form.flyout-registration', e => {
            var form = $('form.flyout-registration');
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            form.trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    $('body').trigger('formSubmit:submit', !data.validForm);
                    if (!'success' in data || !data.success) {
                        formValidation(form, data);
                        $('body').trigger('register:error');
                    } else {
                        let gtmStartedEvent = window.dataLayer.find(element => element['gtm.start']);
                        if (typeof gtag != 'undefined' && window.gtagEnabled && gtmStartedEvent && gtmStartedEvent['gtm.uniqueEventId']) {
                            var gtagSendTo = 'AW-1067780753/W3sQCLzr4ZAYEJGVlP0D';
                            if (window.gtagSendToLogin && window.gtagSendToLogin != 'null') {
                                gtagSendTo = window.gtagSendToLogin;
                            }

                            gtag('event', 'conversion', {
                                'send_to': gtagSendTo,
                                'event_callback': function () {
                                    console.log('gtag login');
                                    window.location.reload(true);
                                }
                            });
                        } else {
                            window.location.reload(true);
                        }

                    }
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
                    }

                    form.spinner().stop();
                }
            });
            return false;
        });
    },
    login: function () {
        $(document).on("submit", 'form.flyout-login', e => {
            var form = $('form.flyout-login');
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            form.find('.btn-primary').attr('disabled', 'true');
            form.trigger('login:submit', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {

                    if (!'success' in data || !data.success) {
                        formValidation(form, data);
                        form.trigger('login:error', data);
                        form.spinner().stop();
                        form.find('.btn-primary').removeAttr('disabled');

                    } else {
                        form.trigger('login:success', data);
                        window.location.reload(true);
                    }
                },
                error: function (data) {
                    form.find('.btn-primary').removeAttr('disabled');
                    if (data.responseJSON.redirectUrl) {
                        window.location.href = data.responseJSON.redirectUrl;
                    } else {
                        form.trigger('login:error', data);
                        form.spinner().stop();
                    }
                }
            });
            return false;
        });
    },
    forgotPassword: function () {
        $(document).off('click', 'form.reset-password-form button.btn-primary');
        $(document).on('click', 'form.reset-password-form button.btn-primary', function (e) {
            var form = $('form.reset-password-form');
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('form.reset-password-form').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                    } else {
                        $('.request-password-title').text(data.receivedMsgHeading);
                        $('.request-password-body').empty()
                            .append('<p>' + data.receivedMsgBody + '</p>');
                        $('.send-email-btn').empty()
                            .html('<a href="'
                                + data.returnUrl
                                + '" class="btn btn-primary btn-block">'
                                + data.buttonText + '</a>'
                            );

                    }
                },
                error: function () {
                    form.spinner().stop();
                }
            });
            return false;
        });
    }
};
