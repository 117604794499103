'use strict';

var cart = require('../cart/cart');


module.exports = function () {
    cart();

    $('.minicart').on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity').text(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
        }
    });

    $('.minicart').on('mouseenter focusin touchstart', function () {
        var updateMiniCart = $('.minicart.btn').hasClass('update');
        if ($('.search:visible').length === 0) {
            return;
        }
        var url = $('.minicart').data('action-url');
        var count = parseInt($('.minicart .minicart-quantity').text(), 10);

        if (count !== 0 && $('.minicart .popover.show').length === 0) {
            if (!updateMiniCart) {
                $('.minicart .popover').addClass('show');
                return;
            }
            var miniCart = $('.minicart .popover');

            $('.minicart .popover').addClass('show');
            $('.minicart .popover')
                .spinner()
                .start();
            $('.minicart.btn').removeClass('update');


            var omniForm = $('.omni-form .form-control');
            var radio = omniForm.toArray().filter(function (radioBtn) {
                var isChecked = Array.from($(radioBtn)[0].attributes).filter(function (atrName) {
                    return (atrName.name === 'checked');
                });
                return isChecked.length;
            });
            var selectedShipment = $(radio);

            $.ajax({
                url: url,
                type: 'get',
                dataType: 'html',
                success: function (data) {
                    if (!data.error) {
                        $('.minicart.btn').removeClass('update');
                    }
                },
                error: function (err) {
                    $.spinner().stop();
                },
                complete: function (data) {
                    var minicartUpdated = $('.minicart .popover');
                    var sthCard = (data && data.responseText && $(data.responseText).find('#hidden-sth-date') && $(data.responseText).find('#hidden-sth-date').data('sth-flag') && ($(data.responseText).find('#hidden-sth-date')));
                    var sthFlag = (sthCard && sthCard.data('sth-flag') && sthCard.data('sth-flag') === true) || false;
                    if (sthFlag) {
                        var updatedData = data;
                        var deliveryCards = $(data.responseText)[0].getElementsByClassName('hidden-sth-date');
                        var finalCards = [];
                        Array.from(deliveryCards).forEach(function (card) {
                            var cardObj = $(card.outerHTML);
                            if ($(card).data('sth-flag') === true) {
                                var classes = $(cardObj).find('.line-item-availability').attr('class');
                                var qryClasses = classes;
                                qryClasses = '.' + classes;
                                qryClasses = qryClasses.replace(' ', ' .');


                                var newCard = $(updatedData.responseText).find(qryClasses);
                                var tempData = $(updatedData.responseText);

                                qryClasses = qryClasses.replace(' ', '');
                                qryClasses = qryClasses + '>.bopis-mini-cart';
                                var finalCard = tempData.find(qryClasses).html($(newCard)[0].outerHTML);
                                console.log($(finalCard).find('.bopis-mini-cart>.text-success'));
                                finalCards.push(finalCard);
                            }
                        });
                        var miniCart = $(data.responseText);
                        finalCards.forEach(function (sthDeliveryCard) {
                            var classes = $(sthDeliveryCard[0].innerHTML).attr('class');
                            var qryClasses = '.' + classes;
                            qryClasses = qryClasses.replace(' ', ' .');
                            qryClasses = qryClasses.replace(' ', '');
                            qryClasses = qryClasses + '>.bopis-mini-cart';
                            miniCart.find(qryClasses).html(sthDeliveryCard.html());
                            //

                        });
                        $('.minicart .popover').empty();
                        $('.minicart .popover').html(miniCart.html());
                    } else {
                        $('.minicart .popover').empty();
                        $('.minicart .popover').html(data.responseText);
                    }

                    var sthCards = $('.bopis-mini-cart').toArray().filter(function (item) {
                        return $(item).text().indexOf('at') === -1
                    });

                    if (!sthCards.length) {
                        sthCards = $('.bopis-mini-cart').toArray().filter(function (item) {
                            return ($(item).text().indexOf('Estimated') === -1)
                        });
                    }

                    sthCards.forEach(function (card) {
                        var miniCartCard = card;
                        var shipmentType = document.createElement('span');
                        var lineBreak = document.createElement('br');
                        var shipmentETA = document.createElement('span');
                        $(shipmentType).addClass('font-weight-bold mb-0 text-success');
                        $(shipmentType).text('Ship To Home');
                        $(shipmentETA).addClass('small');
                        $(shipmentETA).text('Usually ships in 24-48 hours');
                        $(miniCartCard).empty();
                        $(miniCartCard).append(shipmentType);
                        $(miniCartCard).append(lineBreak);
                        $(miniCartCard).append(shipmentETA);
                    });

                    var promiseDateUrl = $('.mini-cart-wrapper').attr('data-promise-url');
                    if (promiseDateUrl) {
                        $.when(sthAjax(promiseDateUrl), stsAjax(promiseDateUrl)).done(function (a1, a2) {
                            $(document).trigger('promiseDates:loaded');
                        });
                        function sthAjax(promiseDateUrl) {
                            return $.ajax({
                                url: promiseDateUrl,
                                method: 'GET',
                                success: function (response) {
                                    if (response && response.promiseDates) {
                                        $('.mini-cart-wrapper .product-summary .card').each(function (idx, elem) {
                                            var uuidKey = $(elem).attr('data-uuid');
                                            if (response.promiseDates[uuidKey] && $(elem).find('.minicart-sth-dates-wrapper').length) {
                                                $(elem).find('.minicart-sth-dates-wrapper .wrapper-promise-dates').attr('data-rawdate', response.promiseDates[uuidKey].promiseDate);
                                                $(elem).find('.estimate-date-wrapper').addClass('d-none');
                                                $(elem).find('.minicart-promise-dates-wrapper').removeClass('d-none');
                                            }
                                        });
                                    }
                                }
                            });
                        }
                        function stsAjax(promiseDateUrl) {
                            return $.ajax({
                                url: promiseDateUrl + '?isSTS=true',
                                method: 'GET',
                                success: function (response) {
                                    if (response && response.promiseDates) {
                                        $('.mini-cart-wrapper .product-summary .card').each(function (idx, elem) {
                                            var uuidKey = $(elem).attr('data-uuid');
                                            if (response.promiseDates[uuidKey] && $(elem).find('.minicart-sts-dates-wrapper').length) {
                                                $(elem).find('.minicart-sts-dates-wrapper .wrapper-promise-dates').attr('data-rawdate', response.promiseDates[uuidKey].promiseDateSTS);
                                                $(elem).find('.estimate-date-wrapper').addClass('d-none');
                                                $(elem).find('.minicart-promise-dates-wrapper').removeClass('d-none');
                                            }
                                        });
                                    }
                                }
                            })
                        }
                        $.spinner().stop();
                    }
                }
            })
        }
    });

    $('body').on('touchstart click', function (e) {
        if ($('.minicart').has(e.target).length <= 0) {
            $('.minicart .popover').removeClass('show');
        }
    });
    $('.minicart').on('mouseleave focusout', function (event) {
        if (
            (event.type === 'focusout' && $('.minicart').has(event.target).length > 0) ||
            (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity')) ||
            $('body').hasClass('modal-open')
        ) {
            event.stopPropagation();
            return;
        }
        $('.minicart .popover').removeClass('show');
    });
    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });
    $('body').on('product:afterAddToCart', function () {
        $('.minicart.btn').addClass('update');
    });
    $('body').on('cart:update', function () {
        $('.minicart.btn').addClass('update');
    });
};
