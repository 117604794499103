'use strict';

var BrTrkHandler = require('./helperPixel');

(function () {
    if (window.resources.bloomreach.pixel === 'true') {
        var widgetID = $('.bloomreach-properties').data('widgetid');
        var responseID = $('.bloomreach-properties').data('rid');
        var typeRecommendation = $('.bloomreach-properties').data('typerecommendation');
        var widget_view_data = {
            wrid: responseID,
            wid: widgetID,
            wty: typeRecommendation
        }

        BrTrkHandler.brtrkHandle(widget_view_data, "Event", 'widget', 'widget-view');
    }
})();
