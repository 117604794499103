'use strict';

var Cleave = require('cleave.js').default;

module.exports = {
    handleCreditCardNumber: function (cardFieldSelector, cardTypeSelector, cardTypeOptionsWrapper, securitySelector) {
        $(cardFieldSelector)
            .toArray()
            .forEach(function (field) {
                var cleave = new Cleave(field, {
                    creditCard: true,
                    onCreditCardTypeChanged: function (type) {
                        var creditCardTypes = {
                            visa: 'Visa',
                            mastercard: 'Master Card',
                            amex: 'Amex',
                            discover: 'Discover',
                            unknown: 'Unknown'
                        };

                        var cardType =
                            creditCardTypes[Object.keys(creditCardTypes).indexOf(type) > -1 ? type : 'unknown'];
                        $(cardTypeSelector).val(cardType);
                        var currentCard = cardType.split(' ').join('').toLowerCase();
                        if (cardTypeOptionsWrapper === '#cardOptions') {
                            if (currentCard === 'unknown') {
                                $('.credit-card-icon').removeClass('d-none');
                                $('#cardOptionsIcon .card-option').addClass('d-none');
                            } else {
                                $('.credit-card-icon').addClass('d-none');
                                $('#cardOptionsIcon .card-option').addClass('d-none');
                                if ($('#cardOptionsIcon .card-option-' + currentCard)) {
                                    $('#cardOptionsIcon .card-option-' + currentCard).removeClass('d-none');
                                }
                            }
                        } else {
                            $(cardTypeOptionsWrapper)
                                .find('.card-option')
                                .each(function () {
                                    if (currentCard === 'unknown' || $(this).data('type') === currentCard) {
                                        $(this).fadeTo('slow', 1);
                                    } else {
                                        $(this).fadeTo('slow', 0.5);
                                    }
                                });
                        }
                        if (type === 'visa' || type === 'mastercard' || type === 'discover') {
                            $(securitySelector).attr('maxlength', 3);
                        } else {
                            $(securitySelector).attr('maxlength', 4);
                        }
                    }
                });

                $(field).data('cleave', cleave);
            });
    },

    serializeData: function (form) {
        var serializedArray = $('#dwfrm_billing')
            .serializeArray()
            .filter(function (formItem) {
                if (formItem.value !== '') {
                    return formItem;
                }
            });

        serializedArray.forEach(function (item) {
            if (item.name.indexOf('creditCardFields_cardNumber') > -1) {
                if ($('#cardNumber').data('cleave')) {
                    item.value = $('#cardNumber').data('cleave').getRawValue(); // eslint-disable-line
                }
            }
        });

        return $.param(serializedArray);
    },
    serializeFormData: function (form) {
        var serializedArray = form.serializeArray();

        serializedArray.forEach(function (item) {
            if (item.name.indexOf('phone') > -1) {
                let cleavePhoneEle = $('.js-cleave-phone');
                if (cleavePhoneEle.length > 0) {
                    if (cleavePhoneEle.data('cleave')) {
                        item.value = cleavePhoneEle.data('cleave').getRawValue(); // eslint-disable-line
                    }
                }
            } else if (item.name.indexOf('cardNumber') > -1) {
                item.value = $('#cardNumber').data('cleave').getRawValue(); // eslint-disable-line
            }
        });

        return $.param(serializedArray);
    },
    handleMobileNumber: function () {
        $(document).ready(function () {
            let mobileCleaveConfig = {
                delimiters: ['-'],
                blocks: [3, 3, 4]
            };
            let cleavePhoneEle = $('.js-cleave-phone');
            if (cleavePhoneEle.length > 0) { // Checkout & Profile
                let customerPhoneCleave = new Cleave('.js-cleave-phone', mobileCleaveConfig);
                cleavePhoneEle.data('cleave', customerPhoneCleave);
            }
        });
    },
    handleCreditCardExpiryDate: function () {
        $(document).ready(function () {
            let expDateEle = $('#expirationDate');
            if (expDateEle.length > 0) {
                let expCleave = new Cleave('#expirationDate', {
                    date: true,
                    delimiters: ['/'],
                    datePattern: ['m', 'Y']
                });
                expDateEle.data('cleave', expCleave);
            }
        });
    }
};
