'use strict';

var $body = $('body');
var formValidation = require('../components/formValidation');
var clientSideValidation = require('../components/clientSideValidation');
var calendarCarousel = require('../components/globalCarousel');
var jsonUtils = require('../components/jsonUtils');

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateDom($results, selector) {
    var $updates = $results.find(selector);
    $(selector).empty().html($updates.html());
}

function calendarCarouselInit(data) {
    if (/js-global-carousel/.test(data)) {
        calendarCarousel.init();

        var $slider = $('.js-global-carousel');
        const $checkedInput = $slider.find('.js-schedule-appointment-time:checked');
        const selectedSlide = $checkedInput.closest('.slick-slide').attr('data-slick-index');
        if (!!selectedSlide) {
            $slider.slick('slickGoTo', parseInt(selectedSlide));
        }
    }
}

function datepickerCustomInit($datepickerWrapper) {
    // add background month
    if ($datepickerWrapper.find('.js-schedule-appointment-styled-month').length) {
        return;
    }

    $datepickerWrapper.find('.ui-datepicker-month').each(function () {
        var $month = $(this);
        var $copyMonth = $('<div>', {
            class: 'schedule-appointment-styled-month js-schedule-appointment-styled-month'
        }).text($month.text());
        $month.parent('.ui-datepicker-title').append($copyMonth);
    });
}

function appointmentPrePopulation() {
    return {
        "firstname": $('.appointment-data-section').find('#fname').val() || '',
        "lastname": $('.appointment-data-section').find('#lname').val() || '',
        "email": $('.appointment-data-section').find('#email').val() || '',
        "phone": $('.appointment-data-section').find('#phone').val() || '',
        "postalCode": $('.appointment-data-section').find('#zipCode').val() || '',
        "city": $('.appointment-data-section').find('#city').val() || '',
        "stateCode": $('.appointment-data-section').find('#state').val() || '',
        "address1": $('.appointment-data-section').find('#address1').val() || '',
        "address2": $('.appointment-data-section').find('#address2').val() || '',
        "storeAssociate": $('.appointment-data-section').find('#storeAssociate').val() || '',
        "storeId": $('.appointment-data-section').find('#storeId').val() || '',
        "workDesc": $('.appointment-data-section').find('#workDesc').val() || '',
        "transactionId": $('.appointment-data-section').find('#transactionId').val() || '',
        "serviceType": $('.appointment-data-section').find('#serviceTypeH').val() || ''
    };
}

function scheduleFormInit(formClass) {
    var $form = $(formClass);

    $form.submit(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $('.js-next-schedule-appointment-btn').attr('disabled', true);
        var invalidFields = false;
        $('.modal-content input.form-control').each(function () {
            if (
                (($(this).is('[required]') && $(this).is('[readonly]')) || $(this).is('[disabled]')) &&
                this.id != 'service-list-modal'
            ) {
                if (!$(this).val()) {
                    invalidFields = true;
                    return false;
                }
            }
        });

        if (invalidFields) {
            var uri = $('.continue-button').data('check-invalid-appointment');
            $.spinner().start();
            $.ajax({
                type: 'GET',
                url: uri,
                success: function (response) {
                    var error = response.error[0];
                    $('.appointment-error').html(error);
                    $('.appointment-error').removeClass('d-none');
                },
                error: function (response) { },
                complete: function () {
                    $.spinner().stop();
                }
            });

            return false;
        }

        var validForm = clientSideValidation.helperFunctions.validateFormBeforeSubmit(this);
        var invalidService = !!$('.js-schedule-appointment-form').find('.is-invalidated').length > 0;
        if (!validForm || invalidService) {
            $('.js-schedule-appointment-form').find('.is-invalidated').addClass('is-invalid');
            if ($('#service-list-modal').hasClass('is-invalid')) {
                $('.js-schedule-appointment-form')
                    .find('#service-list-error')
                    .html("The selected service isn't avaliable in your ZIP Code");
            }

            return false;
        }

        var $form = $(this);
        var url = $form.attr('action');
        var reschedulingProcess = $form.find('#reschedulingProcess').val();
        var requestData;

        if (reschedulingProcess == 'true') {
            if ($form.data('step') == '2') {
                url = $form.data('schedulecontact');
            }
            $form.find('select').attr('disabled', false);
            requestData = $form.serialize();
            $form.find('select').attr('disabled', true);
        } else {
            requestData = $form.serialize();
        }

        if ($('#isRescheduleModal').val() == 'true') {
            requestData += '&isReschedule=' + $('#isRescheduleModal').val();
        }

        if ($('#isRescheduleModal').val() == 'true' || $('#isScheduleAgainModal').val() == 'true') {
            requestData += '&selectedServiceType=' + $('#selectedServiceType').val();
        }

        // calling modal
        appointmentModal(url, requestData);
    });

    $form.on('click', '.js-back-schedule-appointment-btn', function (e) {
        e.preventDefault();
        e.stopPropagation();
        var $this = $(this);
        var $form = $this.parent('form');
        var url = $form.attr('data-back-action');
        var step = $form.attr('data-step');
        var prevStep =
            step == 4 && ($('#isRescheduleModal').val() == 'true' || $('#isScheduleAgainModal').val() == 'true')
                ? step - 2
                : step - 1;

        var requestData = $form.serialize();
        var appointmentData = appointmentPrePopulation();
        requestData += '&step=' + prevStep;
        requestData += '&appointmentPrePopulation=' + JSON.stringify(appointmentData);
        requestData += '&storedData=true';

        // calling modal
        appointmentModal(url, requestData);
    });

    $form.on('click', '.js-schedule-appointment-change-your-date-btn', function (e) {
        e.preventDefault();
        $('.js-schedule-appointment-change-your-date').toggleClass('d-none');
    });

    $form.on('change', '.js-schedule-appointment-time', function (e) {
        var $this = $(this);
        var $changeYourDate = $('.js-schedule-appointment-change-your-date');

        $('.js-full-your-date').html($this.attr('data-full-your-date'));
        $changeYourDate.datepicker('setDate', $this.attr('data-short-your-date'));

        datepickerCustomInit($changeYourDate);
    });
}

function addAddressSelectEvent() {
    $('#addressSelector').change(function () {
        var $this = $(this);
        var value = $this.val();
        var $addressSelected = $('#addressSelector option:selected');
        if (value) {
            $('#schedule-form-address1').val($addressSelected.data('address1'));
            $('#schedule-form-address2').val($addressSelected.data('address2'));
            $('#schedule-form-city').val($addressSelected.data('city'));
            $('#schedule-form-postalCode').val($addressSelected.data('postal-code'));
            $('#schedule-form-states').val($addressSelected.data('state-code'));
            $('#schedule-form-states').change();
        }
    });
}

$('body').on('click', '.closeAppointmentModal', function () {
    var scheduleStep = $('.scheduleStepFour').length;
    if (scheduleStep) {
        window.location.reload();
    }
});

var appointmentModal = function (targetElementUrl, requestData, afterSuccessCallback) {
    var $modal = $('body').find('#appointmentActionModal');

    $.spinner().start();

    // removing modal if we'll render another content
    $.ajax({
        url: targetElementUrl,
        type: 'POST',
        data: requestData || {},
        success: function (data) {
            // append modal template into body section
            $.spinner().stop();

            if (data.action) {
                var $form = $('.js-schedule-appointment-form');
                formValidation($form, data);

                return;
            }

            var $modal = $('body').find('#appointmentActionModal');

            if ($modal.length) {
                var $data = $(data);

                var $updateModal = $data.find('.modal-dialog');
                $modal.find('.modal-dialog').empty().html($updateModal.html());
                calendarCarouselInit(data);
            } else {
                $body.append(data);
                calendarCarouselInit(data);

                $modal = $('body').find('#appointmentActionModal');

                var additionalDataContainer = $('.modal-content');
                if (additionalDataContainer) {
                    var additionalData = additionalDataContainer.attr('data-error');
                    if (additionalData) {
                        try {
                            var additionalDataObject = JSON.parse(additionalData);
                            var $form = $('.js-schedule-appointment-form');
                            formValidation($form, additionalDataObject);
                        } catch (e) { }
                    }
                }
                // display modal
                $modal.modal({ backdrop: 'static', keyboard: false });

                // warning: be careful with the browser debugger because BOOTRAP has css-transition event listeners
                // and the debugger-veil hidden triggers the hide modal event
                $modal.on('hidden.bs.modal', function () {
                    var $modal = $(this);
                    $('body').find('#appointmentActionModal').modal('dispose').remove();
                });
            }

            if (
                requestData.appointmentActionType === 'reschedule' ||
                $('.js-schedule-appointment-form').find('#reschedulingProcess').val() == 'true' ||
                requestData.appointmentActionType === 'scheduleagain' ||
                $('#isRescheduleModal').val() == 'true' ||
                $('#isScheduleAgainModal').val() == 'true'
            ) {
                $('.js-schedule-appointment-form input').prop('readonly', true);
                $('.js-schedule-appointment-form select').attr('disabled', true);
                if (requestData.jobTypes) {
                    $('#service-list-modal').val(requestData.jobTypes).change();
                    $('#jobTypes').val(requestData.jobTypes);
                }

                if (requestData.serviceType) {
                    $('#service-list-modal').val(requestData.serviceType).change();
                }

                if ($('#selectedServiceType').val() != '') {
                    $('#service-list-modal').val($('#selectedServiceType').val()).change();
                    $('#service-list-modal').addClass('d-none');
                    $('#choose-service-label').addClass('d-none');
                    $('#selected-service-label').removeClass('d-none');
                    $('#selected-service').removeClass('d-none');
                    $('#selected-service').text($('#selectedServiceType').val());
                    $('input[name=serviceType]').val($('#selectedServiceType').val());
                }
            }

            if ($('#service-list-lp').length && $('#service-list-lp').val() != '') {
                $('#service-list-modal').val($('#service-list-lp').val()).change();
            }

            clientSideValidation.init({
                continueSelector: '.js-next-schedule-appointment-btn',
                formSelector: '.js-schedule-appointment-form',
                enableContinue: true
            });
            scheduleFormInit('.js-schedule-appointment-form');

            if ($('.scheduleAppointmentQuestions').length) {
                clientSideValidation.init({
                    continueSelector: '.js-next-schedule-appointment-btn',
                    formSelector: '.scheduleAppointmentQuestions',
                    enableContinue: true
                });

                $('.scheduleAppointmentQuestions').submit(function () {
                    var validForm = clientSideValidation.helperFunctions.validateFormBeforeSubmit(this);
                    if (!validForm) return false;
                });
            }

            addAddressSelectEvent();
            if (afterSuccessCallback) {
                var jsonParams = $modal.attr('data-params');
                var params = jsonUtils.parse(jsonParams, {});

                afterSuccessCallback(params);
            }

            $('#service-list-modal').on('change', function () {
                var zipCode = $('[name=zipCode]').val();
                var serviceSelected = $('#service-list-modal').val();
                $.spinner().start();
                $.ajax({
                    type: 'POST',
                    url: $('#service-list-modal').data('modal-zipcode-validation-url'),
                    data: 'zipcode=' + zipCode.toString() + '&serviceSelected=' + serviceSelected,
                    success: function (response) {
                        if (response.result == false) {
                            $('#service-list-modal').addClass('is-invalidated');
                        } else {
                            $('#service-list-modal').removeClass('is-invalid');
                            $('#service-list-modal').removeClass('is-invalidated');
                            $('.js-schedule-appointment-form').find('#service-list-error').html('');
                        }
                    },
                    error: function (response) {
                        console.log('Error');
                    },
                    complete: function () {
                        $.spinner().stop();
                    }
                });
            });

            if ($('.continue-button').attr('disabled')) {
                $('.modal-content input.form-control').each(function () {
                    if ($(this).is('[required]') && $(this).is('[readonly]')) {
                        if (!$(this).val()) {
                            var uri = $('.continue-button').data('check-invalid-appointment');
                            $.spinner().start();
                            $.ajax({
                                type: 'GET',
                                url: uri,
                                success: function (response) {
                                    var error = response.error[0];
                                    $('.appointment-error').html(error);
                                    $('.appointment-error').removeClass('d-none');
                                },
                                error: function (response) { },
                                complete: function () {
                                    $.spinner().stop();
                                }
                            });
                            return false;
                        }
                    }
                });
            }

            $('.js-next-schedule-appointment-btn').attr('disabled', false);
        },

        error: function () {
            $.spinner().stop();
            $('.js-next-schedule-appointment-btn').attr('disabled', false);
        }
    });
};

module.exports = {
    // schedule appointment modal
    scheduleModal: function () {
        $body.on('click', '.js-schedule-appointment-modal-btn', function (e) {
            e.preventDefault();
            var $this = $(this);
            var url = $this.attr('data-url');
            var step = $this.attr('data-step');
            var orderId = $this.attr('data-order-number');
            var pliId = $this.attr('data-pli-id');
            var productName = $this.attr('data-product-name');
            var $appointmentWrapper = $this.closest('.js-appointment-wrapper');
            var appointmentActionType = $this.attr('data-action-type');
            var appointmentId = $this.attr('data-appointment-id');
            var appointmentActionNumber = $this.attr('data-appointment-action-number');
            var appointmentPrePopulation = $this.attr('data-pre-population');
            var appointmentTileObj = $this.data('appointment-tile') || $appointmentWrapper.data('appointment-tile');
            var jobTypes = $('#jobTypes').val();

            var requestData = {
                reqAppointmentTile:
                    $this.attr('data-appointment-tile') || $appointmentWrapper.attr('data-appointment-tile'),
                orderId: orderId,
                pliId: pliId,
                productName: productName,
                appointmentActionType: appointmentActionType,
                appointmentId: appointmentId,
                appointmentActionNumber: appointmentActionNumber,
                step: step,
                appointmentPrePopulation: appointmentPrePopulation,
                reschedulingProcess: $('#reschedulingProcess').val(),
                jobTypes: appointmentTileObj ? appointmentTileObj.allJobTypes : jobTypes ? jobTypes : null,
                serviceType: $('#serviceType').length ? $('#serviceType').val() : $('#service-list-lp').val(),
                selectedServiceType: $('#selectedServiceType').val()
            };

            if (appointmentActionType == 'reschedule') {
                $('#isRescheduleModal').val('true');
                $('#selectedServiceType').val(appointmentTileObj.jobType);
            } else if (appointmentActionType == 'scheduleagain') {
                $('#isScheduleAgainModal').val('true');
                $('#selectedServiceType').val(appointmentTileObj.jobType);
            } else {
                $('#isRescheduleModal').val('false');
                $('#isScheduleAgainModal').val('false');
                $('#selectedServiceType').val('');
            }

            if ($('#service-list-modal').hasClass('d-none')) {
                var zipCode = $('[name=zipCode]').val();
                var serviceSelected = $('#selectedServiceType').val();
                $.spinner().start();
                $.ajax({
                    type: 'post',
                    url: $('#service-list-modal').data('modal-zipcode-validation-url'),
                    data: 'zipcode=' + zipCode.toString() + '&serviceSelected=' + serviceSelected,
                    success: function (response) {
                        if (response.result == false) {
                            $('#service-list-modal').addClass('is-invalid');
                            $('#service-list-error').html("This service is'nt avaliable in your ZIP Code");
                            $('.cotinue-button-service-check').attr('disabled', 'disabled');
                            return false;
                        } else {
                            $('#service-list-modal').removeClass('is-invalid');
                            $('#service-list-error').html('');
                            $('.cotinue-button-service-check').removeAttr('disabled');
                            $('#service-list-modal').attr(
                                'action',
                                "${URLUtils.url('Appointments-SubmitScheduleContact')}"
                            );
                            appointmentModal(url, requestData);
                        }
                    },
                    error: function (response) {
                        console.log('Error');
                    },
                    complete: function () {
                        $.spinner().stop();
                    }
                });
            } else {
                // calling modal
                appointmentModal(url, requestData);
            }
        });
        $(document).on('change', '.schedule-radio-question', function () {
            if ($(this).parents('.scheduleAppointmentQuestions').length > 0) {
                if (!$('.scheduleAppointmentQuestions').find('.custom-invalidation')) {
                    $('.js-next-schedule-appointment-btn').removeAttr('disabled');
                } else {
                    let isValidated = true;
                    $('.scheduleAppointmentQuestions').find('.custom-invalidation').each(function() {
                        if ($(this).find('.invalid-feedback') && !$(this).find('.invalid-feedback').is(':empty')) {
                            isValidated = false;
                        }
                    });
                    if (isValidated) {
                        $('.js-next-schedule-appointment-btn').removeAttr('disabled');
                    }
                }
            }
        });

        $('body').on('scheduleAppointment:datepickerInit', function (e, initData) {
            var $changeYourDate = $('.js-schedule-appointment-change-your-date');
            $changeYourDate
                .datepicker({
                    dateFormat: 'yy-mm-dd',
                    minDate: initData.minDate || 0,
                    maxDate: initData.maxDate || 0,
                    numberOfMonths: 2,
                    monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
                    onSelect: function (date) {
                        var $form = $('.js-schedule-appointment-form');
                        var url = $form.attr('data-modal-action');
                        var step = $form.attr('data-step');

                        var requestData = $form.serialize();
                        var appointmentData = appointmentPrePopulation();
                        requestData += '&step=' + step;
                        requestData += '&appointmentPrePopulation=' + JSON.stringify(appointmentData);
                        requestData += '&storedData=true';
                        requestData += '&yourDate=' + date;
                        requestData += '&maxYourDate=' + initData.maxDate;
                        requestData += '&reschedulingProcess=' + $form.find('#reschedulingProcess').val();
                        requestData += '&jobTypes=' + $form.find('#jobTypes').val();
                        /*  requestData += '&serviceType=' + $('#selectedServiceType').val(); */

                        if ($('#serviceWebBeautyName').length) {
                            requestData += '&serviceWebBeautyName=' + $('#serviceWebBeautyName').val();
                        }

                        if ($('#serviceURL').length) {
                            requestData += '&serviceURL=' + $('#serviceURL').val();
                        }

                        if ($('#questionsAnswer').length) {
                            requestData += '&questionsAnswer=' + $('#questionsAnswer').val();
                        }

                        $('.js-schedule-appointment-change-your-date').addClass('d-none');

                        // calling modal
                        appointmentModal(url, requestData);
                    },
                    onChangeMonthYear: function () {
                        setTimeout(function () {
                            datepickerCustomInit($changeYourDate);
                        }, 0);
                    }
                })
                .datepicker('setDate', initData.date);

            datepickerCustomInit($changeYourDate);
        });

        $('#service-list-lp').on('change', function () {
            var zipCode = $('#your-zipcode').val();
            var serviceSelected = $('#service-list-lp').val();
            if (!zipCode) {
                $('#your-zipcode').addClass('is-invalid');
                $('#zipCode-confirmation-error').html('You must enter a valid ZIP Code');
                $(this).parents('form').submit();
            } else {
                $('#your-zipcode').removeClass('is-invalid');
                $('#zipCode-confirmation-error').html('');
                $.spinner().start();
                $.ajax({
                    type: 'POST',
                    url: $('#service-list-lp').data('service-validation-url'),
                    data: 'zipcode=' + zipCode.toString() + '&serviceSelected=' + serviceSelected,
                    success: function (response) {
                        if (response.result == false) {
                            $('#service-list-lp').addClass('is-invalid');
                            $('#service-list-error').html("The selected service isn't avaliable in your ZIP Code");
                            $('#request-service-submit').attr('disabled', 'disabled');
                        } else {
                            $('#service-list-lp').removeClass('is-invalid');
                            $('#service-list-error').html('');
                        }
                    },
                    error: function (response) {
                        console.log('Error');
                    },
                    complete: function () {
                        $.spinner().stop();
                    }
                });
            }
        });

        $('.contactFormForm *').on('blur', function () {
            if ($('.contactFormForm').find('.is-invalid').length) {
                $('#request-service-submit').attr('disabled', 'disabled');
            } else {
                $('#request-service-submit').removeAttr('disabled');
            }
        });
    },

    confirmScheduleModal: function () {
        var $btn = $('.js-schedule-appointment-modal-btn[data-confirm-schedule-modal-timer]');
        var url = $btn.attr('data-url-modal');
        var orderId = $btn.attr('data-order-number');
        var pliId = $btn.attr('data-pli-id');
        var productName = $btn.attr('data-product-name');
        var timer = $btn.attr('data-confirm-schedule-modal-timer');
        if (url && orderId && pliId && timer) {
            setTimeout(
                function (url, orderId, pliId, productName) {
                    var requestData = {
                        orderId: orderId,
                        pliId: pliId,
                        productName: productName
                    };
                    appointmentModal(url, requestData);
                },
                parseInt(timer) || 0,
                url,
                orderId,
                pliId,
                productName
            );

            $body.on('click', '.js-confirm-later-btn', function (e) {
                e.preventDefault();
                var $this = $(this);
                var url = $this.attr('data-url');
                var scheduleLater = $this.attr('data-schedule-later');
                // TODO: need to refact
                var orderId = $('.js-schedule-appointment-modal-btn').attr('data-order-number').replace(/[^\d]/g, '');
                var requestData = {
                    scheduleLater: scheduleLater,
                    orderId: orderId
                };

                // calling modal
                appointmentModal(url, requestData);
            });
        }
    },

    changeSubmitButtonType: function () {
        $('#service-list-lp, #service-list-asset').on('change', function () {
            var disableModalServiceTypes = $('#disableModalServiceTypes').val().split('|');
            var value = $(this).val();
            var match = false;

            disableModalServiceTypes.forEach(function (service) {
                if (service == value) {
                    match = true;
                }
            });

            if (match) {
                $('.contactFormForm').removeClass('openAppointmentModal');
            } else {
                $('.contactFormForm').addClass('openAppointmentModal');
            }
        });
    },

    updateSelectedServiceType: function () {
        $('body').on('change', '#service-list-modal', function () {
            $('input[name=serviceType]').val($(this).val());
        });
    },
    methods: {
        appointmentModal: appointmentModal
    },
    showRescheduleConfirmationModal: function () {
        $('body').on('click', '.reschedule-appointment', function () {
            var $this = $(this);
            var $confirmRescheduleButton = $('.confirmRescheduleButton');

            $confirmRescheduleButton.attr('data-pli-id', $this.attr('data-pli-id'));
            $confirmRescheduleButton.attr('data-appointment-id', $this.attr('data-appointment-id'));
            $confirmRescheduleButton.attr(
                'data-appointment-action-number',
                $this.attr('data-appointment-action-number')
            );
            $confirmRescheduleButton.attr('data-pre-population', $this.attr('data-pre-population'));
            $confirmRescheduleButton.attr(
                'data-appointment-tile',
                $this.closest('.js-appointment-wrapper').attr('data-appointment-tile')
            );
            $('#rescheduleConfirmationModal').modal('show');
        });
    },
    closeRescheduleConfirmationModal: function () {
        $('body').on('click', '.confirmRescheduleButton', function () {
            $('#rescheduleConfirmationModal').modal('hide');
        });

        $('body').on('click', '.closeRescheduleConfirmationModal', function () {
            $('#rescheduleConfirmationModal').modal('hide');
        });
    },
    copyServiceSelectionOnModalToForm: function () {
        $('body').on('change', '#service-list-modal', function () {
            $('#service-list-lp').val($(this).val()).change();
        });
    },
    closeAppointmentModal: function () {
        $('body').on('click', '.close-appointment-modal', function () {
            $('.modal-close-appointment-modal').modal('show');
        });

        $('body').on('click', '.confirm-exit-modal', function () {
            var $this = $(this);
            var url = $this.data('add-note-url');
            var step = $('.js-schedule-appointment-form').attr('data-step');

            if (step == '4') {
                $.ajax({
                    url: url,
                    type: 'POST',
                    success: function () {
                        $('.appointment-action-modal').modal('hide');
                        $('.modal-close-appointment-modal').modal('hide');
                    }
                });
            } else {
                $('.appointment-action-modal').modal('hide');
                $('.modal-close-appointment-modal').modal('hide');
            }
        });

        $('body').on('click', '.confirm-keep-modal', function () {
            $('.modal-close-appointment-modal').modal('hide');
        });
    }
};
