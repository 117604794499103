module.exports = function () {
    $('.offline-chat-button').on('click', function (e) {
        e.preventDefault();

        var $offlineChatForm = $('.offline-chat-form');
        $offlineChatForm.removeClass('d-none');
        $('html, body').animate(
            {
                scrollTop: $offlineChatForm.offset().top
            },
            2000
        );
    });
};
