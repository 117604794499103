'use strict';

var base = require('base/storeLocator/storeLocator');
var googleMapsHelpers = require('googleMaps/googleMaps');
var scrollAnimate = require('refArch/components/scrollAnimate');

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    if (newUrl && newUrl !== undefined) {
        newUrl +=
            (newUrl.indexOf('?') !== -1 ? '&' : '?') +
            Object.keys(params)
                .map(function (key) {
                    return key + '=' + encodeURIComponent(params[key]);
                })
                .join('&');
    }
    return newUrl;
}

function handleMarkers(storeLocatorMap) {
    var markers = document.querySelectorAll("img[src='https://maps.gstatic.com/mapfiles/transparent.png']");
    var mapDiv = storeLocatorMap || document.querySelector('.store-locator-page .map-canvas');
    if (!markers.length || !mapDiv) return;
    var mapPosition = mapDiv.getBoundingClientRect();
    var element;
    var count = 0;

    markers.forEach(function (el) {
        element = el.getBoundingClientRect();
        if (
            element.x >= mapPosition.left &&
            element.x <= mapPosition.right &&
            element.y >= mapPosition.top &&
            element.y <= mapPosition.bottom
        ) {
            return;
        }
        count++;
    });
    if (count == markers.length) {
        $('.store-search-input').removeClass('d-none');
    } else {
        $('.store-search-input').addClass('d-none');
    }
}

/**
 * Logic intended for  Alternative Bundle Item. 
 * When we switch radio button from Ship to Pickup and vice versa the product Id keeps switching Example from 14694 to B-14679-6 
 * This change will ensure consitent PID for such Alternative Bundle Item.
 * 
 * BM value for this site preference productPairsUsedForAltSubstitution will be
 * {   "ship" : ["14694"],
 *    "pickup": ["B-14679-6"]
 * }
 * 
 * If we pass "B-14679-6" this method would return 14694
 * 
 * @param {*} pid 
 * @returns 
 */
function findCorrespondingShipProduct(pid) {
    let matchPid = pid;
    let altProductAttribute = $('[data-alt-substitution-pairs]');
    let hasAltSub = altProductAttribute.length > 0;
    if (hasAltSub) {
        const data = JSON.parse(altProductAttribute.attr('data-alt-substitution-pairs'));
        const shipArray = data.ship || [];
        const pickupArray = data.pickup || [];
        const index = pickupArray.indexOf(pid);
        if (index !== -1) {
            matchPid = shipArray[index];
        }
    }
    return matchPid;
}

/**
 * Uses google maps api to render a map
 */
function maps($mapParent, pickupOnly, lat, lng) {
    if (!$mapParent || $mapParent.length == 0) {
        return false;
    }
    var map;
    // Init U.S. Map in the center of the viewport
    var zoom = $mapParent.hasClass('mobile-map') ? 5 : 4;
    var latlng = new google.maps.LatLng(lat || 37.09024, lng || -95.712891);
    var mapOptions = {
        scrollwheel: false,
        zoom: lat && lng ? zoom * 3 : zoom,
        center: latlng
    };
    map = new google.maps.Map($mapParent.find('.map-canvas')[0], mapOptions);
    var mapdiv = $mapParent.find('.map-canvas').attr('data-locations');

    mapdiv = mapdiv ? JSON.parse(mapdiv) : [];
    var resultsLength = mapdiv.length;
    var bounds = new google.maps.LatLngBounds();

    // Customized google map marker icon with svg format
    var markerImg = {
        path:
            resultsLength > 1
                ? 'M 11.692059,31.744898 C 11.334861,31.368939 10.185677,29.766538 8.9468342,27.917007 5.443555,22.686783 3.1655806,17.958552 2.3388887,14.201361 2.1234759,13.222341 2.1006803,12.958949 2.1006803,11.44898 c 0,-1.6022585 0.019224,-1.7552548 0.345841,-2.7523814 C 2.7647355,7.7251243 3.2732213,6.7613441 3.8868452,5.9666193 4.3034606,5.427047 5.1586405,4.5664922 5.6875232,4.1546239 6.80242,3.2863963 8.4159976,2.5417354 9.8952381,2.2127767 10.354542,2.110635 10.470243,2.104807 12.023129,2.1055921 c 1.636656,8.273e-4 1.64402,0.00127 2.190261,0.1305514 1.464365,0.3465883 2.848861,0.978174 3.92564,1.7908159 0.59491,0.4489767 1.648955,1.505118 2.086899,2.0910489 0.813781,1.0887672 1.479019,2.6772579 1.647926,3.9349987 0.07199,0.536058 0.05946,2.232797 -0.02065,2.796345 -0.355484,2.50071 -2.141414,6.772667 -4.732728,11.320716 -1.399812,2.456829 -4.017095,6.617067 -4.721813,7.505442 -0.171373,0.216035 -0.21193,0.242857 -0.367221,0.242857 -0.141586,0 -0.20571,-0.03278 -0.339382,-0.173469 z'
                : 'M11.9772 2H12.0227C17.524 2 21.9998 6.21338 22 11.3924C22 14.1996 20.3683 18.5 17.1502 24.1739C14.7819 28.3497 12.3814 31.7689 12.326 31.8478L12.3252 31.8489C12.2605 31.9406 12.1527 31.9968 12.036 31.9999L12.0262 32C11.9131 32 11.8063 31.9495 11.7385 31.8638C11.7142 31.8331 9.28002 28.7433 6.87952 24.7091C3.64171 19.2675 2 14.7872 2 11.3924C2 6.21345 6.47567 2 11.9772 2ZM11.9998 15.9803C14.5519 15.9803 16.628 14.0252 16.628 11.6219C16.628 9.21871 14.5519 7.26355 11.9998 7.26355C9.4479 7.26355 7.37176 9.21871 7.37176 11.6219C7.37176 14.0252 9.4479 15.9803 11.9998 15.9803Z',
        fillColor: '#005db2',
        fillOpacity: 1,
        scale: 1.25,
        strokeColor: 'white',
        strokeWeight: 1,
        anchor: new google.maps.Point(13, 30),
        labelOrigin: new google.maps.Point(12, 12)
    };

    var skipItemsLength = 0;
    var oneStoreReturned = Object.keys(mapdiv).length === 1;
    var infowindow = new google.maps.InfoWindow();

    google.maps.event.addListener(infowindow, 'closeclick', function () {
        $('.store-result').removeClass('highlighted-result');
    });

    Object.keys(mapdiv).forEach(function (key) {
        var item = mapdiv[key];
        var pickup = (pickupOnly && item.pickup) || !pickupOnly;
        if (!item.skip && pickup) {
            if (item.service === true) {
                markerImg.fillColor = '#e6526f';
            } else {
                markerImg.fillColor = '#005db2';
            }

            var lable = parseInt(key, 10) + 1;
            var storeLocation = new google.maps.LatLng(item.latitude, item.longitude);
            var marker = new google.maps.Marker({
                position: storeLocation,
                map: map,
                title: item.name,
                icon: markerImg,
                label:
                    resultsLength > 1
                        ? { text: lable.toString(), color: 'white', fontSize: '16px' }
                        : { text: lable.toString(), color: '#00000000', fontSize: '16px' }
            });

            google.maps.event.addListener(marker, 'click', function () {
                infowindow.close();
                infowindow.setContent(item.infoWindowHtmlV2);
                infowindow.open(map, marker);
            });

            map.addListener("click", () => {
                infowindow.close();
                $('.store-result').removeClass('highlighted-result');
            });

            marker.addListener('click', function () {
                var $resultParent = $mapParent.hasClass('store-locator-page') ? $mapParent : $('.header-results-panel');
                var $resultDiv = $resultParent.find('.store-result[data-store-id="' + item.storeID + '"]');
                $('.store-result').removeClass('highlighted-result');

                if (
                    $resultDiv.length > 0 &&
                    !$resultDiv.parents('.additional-result').hasClass('d-none') &&
                    $resultDiv.closest('.locator').length &&
                    $mapParent.hasClass('store-locator-page')) {
                    $resultDiv.addClass('highlighted-result');
                    var scroll = $resultDiv.offset().top - $('.store-list-panel-wrapper .results').offset().top + $resultDiv.outerHeight(true) / 2 - $('.store-list-panel-wrapper').outerHeight(true) / 2;
                    scrollAnimate($resultDiv, scroll, '.store-list-panel-wrapper');
                }
            });

            // Create a minimum bound based on a set of storeLocations
            bounds.extend(marker.position);
        } else if (item.skip) {
            skipItemsLength++;
        }
    });
    // Fit the all the store marks in the center of a minimum bounds when any store has been found.
    if (mapdiv && mapdiv.length !== 0 && mapdiv.length !== skipItemsLength) {
        map.fitBounds(bounds);
        window.locatorMap = map;
    }

    var storeLocatorMap = document.querySelector('.store-locator-page .map-canvas');
    google.maps.event.addListenerOnce(map, 'tilesloaded', function () {
        if (oneStoreReturned) {
            map.setZoom(10);
        }
        if (storeLocatorMap) {
            handleMarkers(storeLocatorMap);
            map.addListener('idle', function (event) {
                handleMarkers(storeLocatorMap);
                window.locatorMap = map;
            });
        }
    });
}

/** Adds error positioning classes
 */
function addErrorPositioning() {
    $('.input-border').addClass('is-invalid-input-border');
    $('.map-container').addClass('map-canvas-error-positioning');
    $('.radius').addClass('radius-error-positioning');
    $('.search-positioning').addClass('search-error-positioning');
    $('.services-container').addClass('services-error-positioning');
    $('.location-icon').addClass('error-coloring');
}

/** Removes error positioning classes
 */
function removeErrorPositioning() {
    $('.input-border').removeClass('is-invalid-input-border');
    $('.map-container').removeClass('map-canvas-error-positioning');
    $('.radius').removeClass('radius-error-positioning');
    $('.search-positioning').removeClass('search-error-positioning');
    $('.services-container').removeClass('services-error-positioning');
    $('.location-icon').removeClass('error-coloring');
    $('.store-locator-labels .invalid-feedback').empty();
}

/**
 * Renders the results of the search and updates the map
 * @param {Object} data - Response from the server
 */
function updateStoresResults(element, data) {
    var $parent = element.parents('.store-locator-page').length
        ? $('.store-locator-page')
        : $('.select-store-dropdown-menu');
    var $resultsDiv = $parent.find('.results');
    var $mapDiv;
    if ($parent.hasClass('store-locator-page')) {
        $mapDiv = $parent.find('.map-canvas');
    } else if (window.matchMedia('(max-width: 767px)').matches) {
        $mapDiv = $('.mobile-map').find('.map-canvas');
    } else {
        $mapDiv = $('.page-wide-map').find('.map-canvas');
    }
    var hasResults = data.stores.length > 0;

    $resultsDiv
        .empty()
        .data('has-results', hasResults)
        .data('radius', data.radius)
        .data('search-key', data.searchKey)
        .data('results-length', data.stores.length);

    $resultsDiv.removeClass('d-none');
    $('.js-geolocation-alert').addClass('d-none');
    $mapDiv.attr('data-locations', JSON.stringify(data.locations));

    if ($parent.find('.select-different-store').length) {
        var tempRadius = $parent.find('select[name="radiusHeader"]').val();
        if (tempRadius) {
            tempRadius + '';
            var newStr = tempRadius.slice(0, tempRadius.indexOf('Mile'));

            var tempRadiusStr = 'Stores within ' + newStr + ' miles of ' + $parent.find('input[name="location"]').val();
            $parent.find('.selected-zip').text(tempRadiusStr);
            $parent.find('.selected-zip').addClass('font-weight-bold');
            $parent.find('.select-different-store').removeClass('d-none');
        } else {
            $parent.find('.selected-zip').text($parent.find('input[name="location"]').val());
            $parent.find('.select-different-store').removeClass('d-none');
        }
    }

    if ($mapDiv.data('has-google-api')) {
        var $mapParent = $parent.hasClass('store-locator-page')
            ? $parent
            : window.matchMedia('(max-width: 767px)').matches
                ? $('.mobile-map')
                : $('.page-wide-map');
        maps($mapParent, !!data.productHtml, data.searchKey.lat, data.searchKey.long);
    } else {
        $parent.find('.store-locator-no-apiKey').show();
    }

    if (data.storesResultsHtml) {
        $resultsDiv.append(data.storesResultsHtml);
        if ($('.continue-store') && $('.continue-store').length > 0) {
            var continue_btn = $('.continue-store').clone().removeClass('d-none');
            var selectedStoreId = $('.selected-store-card').data('store-id');
            $('.card.results-card>.card-body').each(function (idx, elem) {
                if (selectedStoreId === $(elem).data('store-id') && $(elem).find('.select-my-store').length > 0) {
                    $(elem).find('.select-my-store').after(continue_btn[0]);
                    $(elem).find('.select-my-store').remove();
                }
            });
        }
    }

    var btnClass;
    var storesAmount = 0;
    if ($parent.find('.btn-show-less').length) {
        $parent.find('.btn-show-less').addClass('d-none').data('show', 'less').text('Show less results');
        btnClass = '.btn-show-all';
        storesAmount = $parent.find('.locator').length;
    } else {
        btnClass = '.btn-show-more';
        storesAmount = $parent.find('.result-col').length;
    }

    if (data.showMore && data.stores && data.stores.length > storesAmount) {
        $parent.find(btnClass).removeClass('d-none');
    } else {
        $parent.find(btnClass).addClass('d-none');
    }

    if ($parent.hasClass('select-store-dropdown-menu') && hasResults) {
        $parent.addClass('has-results');
        $('.toggle-map-btn').removeClass('d-none').addClass('d-flex');
    } else {
        var $mapParent = window.matchMedia('(max-width: 767px)').matches ? $('.mobile-map') : $('.page-wide-map');
        $parent.removeClass('has-results');
        $('.toggle-map-btn').addClass('d-none').removeClass('d-flex');
        $mapParent.css({
            visibility: 'hidden',
            opacity: '0'
        });
        $mapParent.removeClass('show');
        $parent.find('.show-map').text('Show map');
    }

    if (data.productHtml) {
        $('.dropdown-results').addClass('show-pickup-only');
        $('.toggle-pickup-only').removeClass('d-none');
        $('.header-results-panel').addClass('product-pickup-search').removeClass('normal-search');
        $('.header-results-panel .selected-product-info').html(data.productHtml);
        $('.header-results-panel .product-info-container').removeClass('d-none');
        $('.store-dropdown-title').removeClass('my-local select-store').addClass('check-other');
    }
    if (data.pinnedStoreHtml) {
        $('.pinned-result').html(data.pinnedStoreHtml);
    }

    if ($('.total-available-container').length > 0) {
        var totalAvailableHtml =
            parseInt(data.totalAvailable) > 0 && !!data.totalAvailableHtml ? data.totalAvailableHtml : '';
        $('.selected-product-info').data('total', data.totalAvailable).attr('data-total-html', totalAvailableHtml);
    }

    if (!$('.toggle-pickup-only').hasClass('d-none')) {
        $('.toggle-pickup-btn').prop('checked', false);
        $('.toggle-pickup-btn').trigger('change');
    }

    $('.timeout-geolocation-alert').addClass('d-none');
    $('.omni-disabled').closest('div.result-col').remove();
}

/**
 * Updates dropdown results after a new store is selected
 * @param {element} $results The results container div
 * @param {element} $selectedStore The div containing the selected store details
 * @param {object} data The data returned from the AJAX call
 */
function updateStoreDropdown($results, $selectedStore, data) {
    var pageAction = $('.page').data('action');
    if (pageAction === 'Product-Show') {
        if (data.omniEnable) {
            $('.omni-availability-container').removeClass('d-none');
        } else {
            $('.omni-availability-container').addClass('d-none');
        }
    } else if (pageAction === 'Cart-Show') {
        if (!data.omniEnable) {
            if ($('.change-store-error').length === 0) {
                $('body').append('<div class="change-store-error"></div>');
            }

            $('.change-store-error').append(
                '<div class="alert alert-danger' +
                ' change-store-alert text-center" role="alert">' +
                data.errorMessage +
                '</div>'
            );

            setTimeout(function () {
                $('.change-store-alert').remove();
            }, 5000);
            return;
        }
    }
    if ($('.select-store-title').text().indexOf('Select') > -1 && !data.deliveryMessage) {
        $('.header-sub-font-size').removeClass('d-none');
        $('.select-store-title').text('My Local Store');
    }
    $('.store-result').find('.fa-check').addClass('d-none');
    // $results.prepend($selectedStore.parents('.result-col'));
    $selectedStore.addClass('selected-result');
    $selectedStore.find('.fa-check').removeClass('d-none');
    $('.store-result:lt(3)').parents('.result-col').removeClass('additional-result');
    $('.store-result').eq(3).parents('.result-col').addClass('additional-result');
    var pinnedClass;
    if ($('.selected-product-info').data('pid')) {
        pinnedClass = '.pinned-local-store-container';
    } else {
        pinnedClass = '.pinned-result';
    }
    $(pinnedClass)
        .html($selectedStore.clone().removeClass('card-body'))
        .removeClass('d-none')
        .addClass('selected-result');
    $selectedStore.find('.select-my-store').addClass('disabled');

    if ($('.leslies-header-store-selected').length > 0) {
        var currentSelectesStore = $selectedStore.clone();
        var currentSelectesStoreStaticText =
            '<p class="store-static-text">' + $('.leslies-header-store-selected').data('top-text') + '</p>';
        var currentSelectesStoreStaticTextAnother =
            '<p class="store-static-another">' + $('.leslies-header-store-selected').data('top-text-another') + '</p>';
        $(currentSelectesStoreStaticText).insertAfter(currentSelectesStore.find('.store-name-container'));
        $(currentSelectesStoreStaticTextAnother).insertBefore(currentSelectesStore.find('.dropdown-address-styling'));
        currentSelectesStore
            .find(
                '.select-my-store, .storelocator-phone, .store-services-container, .store-marker, .fa-check, .pickup-and-details, .additional-details, .store-detail-expanded, .select-store-span'
            )
            .remove();
        currentSelectesStore.find('.header-visit-store-page').removeClass('d-none');
        $('.leslies-header-store-selected').html(currentSelectesStore);
        $('.leslies-header-store-selected').find('.store-map-container-inner').removeClass('d-none');
        $('.leslies-header-store-selected').find('.store-info-container').removeClass('mb-4');
        $('.leslies-header-store-selected').find('.store-details-container-bottom').removeClass('d-none');
        $('.leslies-header-store-selected').find('.selected-result').removeClass('flex-column').addClass('flex-wrap');
        var mapId = $('.leslies-header-store-selected').find('.store-map-container').attr('id');
        var ltd = $('.leslies-header-store-selected').find('.store-map-container').data('latitude');
        var lng = $('.leslies-header-store-selected').find('.store-map-container').data('longitude');
        var latlng = { lat: ltd, lng: lng };
        var map = new google.maps.Map(document.getElementById(mapId), {
            zoom: 12,
            center: latlng
        });
        var marker = new google.maps.Marker({
            position: latlng,
            map: map
        });

        $('.leslies-header-store-selected').addClass('leslies-header-store-selected-active');
    }

    if (data.referrer === 'cart-search') {
        $('.uuid-' + data.uuid + ' .form-control[data-delivery-type="pickup"]')
            .removeClass('d-none')
            .prop('checked', true)
            .trigger('cart:omniUpdate', data);
    } else {
        $('.header-store-nickname').text(data.storeName);
        if (data.referrer.indexOf('product-search') > -1) {
            $('body').trigger('product:updateDeliveryCard', data);
        }
    }
    if (data.referrer === 'locator') {
        scrollAnimate($selectedStore);
    } else if (data.referrer === 'dropdown') {
        $('.header-results-panel').animate({ scrollTop: '0' }, 'slow');
    } else {
        $('.select-store-dropdown').trigger('hide.bs.dropdown');
    }
}
function getRadiusOfGoogleMapBounds() {
    var bounds = window.locatorMap.getBounds();

    var center = bounds.getCenter();
    var northEast = bounds.getNorthEast();

    // r = radius of the earth in statute miles
    var r = 3963.0;

    // Convert lat or lng from decimal degrees into radians (divide by 57.2958)
    var lat1 = center.lat() / 57.2958;
    var lon1 = center.lng() / 57.2958;
    var lat2 = northEast.lat() / 57.2958;
    var lon2 = northEast.lng() / 57.2958;

    // distance = circle radius from center to Northeast corner of bounds
    var radius =
        r * Math.acos(Math.sin(lat1) * Math.sin(lat2) + Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1));
    return radius;
}

function prepareGeolocationSearch($detectLocationButton, $parent, position) {
    var url = null;
    if ($detectLocationButton) {
        url = $detectLocationButton.data('action') || $detectLocationButton.attr('href');
    }
    if (!$parent) {
        return;
    }
    if (!url) {
        url = $('.deliveryto-wrapper').data('url');
    }
    var radius = $parent.find('.results').data('radius');
    if ($detectLocationButton.hasClass('store-search-input')) {
        if ($('.maxSearchRadiusStoreLocatorPage').val()) {
            var maxSearchRadius = parseInt($('.maxSearchRadiusStoreLocatorPage').val());
            radius = maxSearchRadius;
            var boundsRadius = getRadiusOfGoogleMapBounds();
            if (boundsRadius && boundsRadius < maxSearchRadius) {
                radius = boundsRadius;
            }
        }
    }
    if ($detectLocationButton.hasClass('detect-location')) {
        if ($parent.hasClass('store-locator-page') && $('.storeLocatorPageRadius').val()) {
            var storeLocatorPageRadius = parseInt($('.storeLocatorPageRadius').val());
            radius = storeLocatorPageRadius;
        } else if ($('.defaultLocationRadius').val()) {
            var defaultLocationRadius = parseInt($('.defaultLocationRadius').val());
            radius = defaultLocationRadius;
        }
    }

    var urlParams = {
        radius: radius,
        lat: position ? position.coords.latitude : '',
        long: position ? position.coords.longitude : '',
        pools: $('[name="installationService"]').prop('checked'),
        service: $('[name="commercialServiceCenter"]').prop('checked'),
        countryCode: 'US',
        referrer: $detectLocationButton.closest('.store-locator').data('referrer')
    };
    if (
        $('.header-results-panel .selected-product-info').data('pid') &&
        $('.header-results-panel .selected-product-info').data('uuid')
    ) {
        urlParams.pid = $('.header-results-panel .selected-product-info').data('pid');
        urlParams.uuid = $('.header-results-panel .selected-product-info').data('uuid');
    }
    return appendToUrl(url, urlParams);
}

/**
 * Search for stores with new zip code
 * @param {HTMLElement} element - the target html element
 * @returns {boolean} false to prevent default event
 */
function search(element, definedRadius) {
    var dialog = element.closest('.in-store-inventory-dialog');
    var spinner = dialog.length ? dialog.spinner() : $.spinner();
    spinner.start();
    var $form = element.closest('.store-locator');
    var radius = $form.hasClass('header-store-form')
        ? parseInt($('.dropdown-radius').val())
        : parseInt($('.store-locator-radius').val());
    if (definedRadius) {
        radius = definedRadius;
    }
    var url = $form.attr('action') ? $form.attr('action') : $form.data('action');
    var isPdp =
        $('div.js-product-detail-page') &&
        $('div.js-product-detail-page').length &&
        $('div.js-product-detail-page').length > 0;
    var urlParams = { radius: radius, isPdp: !!isPdp };
    if (
        $('.header-results-panel .selected-product-info').data('pid') &&
        $('.header-results-panel .selected-product-info').data('uuid')
    ) {
        if ($('.header-results-panel .selected-product-info').data('pid')) {
            urlParams.pid = $('.header-results-panel .selected-product-info').data('pid');
        }
        if ($('.header-results-panel .selected-product-info').data('uuid')) {
            urlParams.uuid = $('.header-results-panel .selected-product-info').data('uuid');
        }
    }
    let isSubstitutionBundleForStore = $('[data-substitution-bundle="true"]').length > 0;
    if (isSubstitutionBundleForStore || $('.header-results-panel .selected-product-info').data('pid')) {
        urlParams.pid = $('.header-results-panel .selected-product-info').data('pid');
    }

    var referrer = $form.data('referrer');
    if (urlParams.uuid && !urlParams.isPdp) {
        referrer = 'cart-search';
    }
    urlParams.referrer = referrer;
    var payload = $form.is('form')
        ? $form.serialize()
        : {
            postalCode: $form.find('[name="postalCode"]').val(),
            pools: $form.find('[name="installationService"]').prop('checked'),
            service: $form.find('[name="commercialServiceCenter"]').prop('checked'),
            countryCode: 'US'
        };

    if (!$('input.location-input-field')[0].validity.valid) {
        $('input[name="postalCode"]').addClass('is-invalid');
        $('.zip-invalid').text('Invalid zipcode');
        $('body').trigger('storeLocator:error');
    } else {
        // payload.referrer = referrer;
    }

    var regExp = /(^\d{5}(-\d{4})?$)|(^[abceghjklmnprstvxyABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Za-z]{1} *\d{1}[A-Za-z]{1}\d{1}$)/;
    var $location = $form.find('[name="location"]');

    if (regExp.test($location.val())) {
        if (typeof payload == 'string') {
            payload += '&postalCode=' + $location.val();
        } else {
            payload.postalCode = $location.val();
        }

        url = appendToUrl(url, urlParams);

        $.ajax({
            url: url,
            type: $form.attr('method'),
            data: payload,
            dataType: 'json',
            success: function (data) {
                updateStoresResults(element, data);
                if (data.prevRadius && data.prevRadius < data.radius) {
                    handleRadiusExpand(data);
                } else {
                    $('.js-far-away').addClass('d-none');
                }
                $('.select-store').prop('disabled', true);
                removeErrorPositioning();
                $('.delivery-to-pdp').text($location.val());
                spinner.stop();
            },
            error: function (data) {
                spinner.stop();
                $('.input-border').addClass('.is-invalid');
                addErrorPositioning();
            }
        });
        return false;
    } else {
        googleMapsHelpers.getGeolocationWithAddress(
            $form.data('mapsApiKey'),
            $location.val(),
            function (data) {
                if (typeof payload == 'string') {
                    payload += '&lat=' + data.lat + '&long=' + data.long;
                } else {
                    payload.lat = data.lat;
                    payload.long = data.long;
                }
                url = appendToUrl(url, urlParams);

                $.ajax({
                    url: url,
                    type: $form.attr('method'),
                    data: payload,
                    dataType: 'json',
                    success: function (data) {
                        spinner.stop();
                        updateStoresResults(element, data);
                        $('.select-store').prop('disabled', true);
                    },
                    error: function (data) {
                        spinner.stop();
                    }
                });
                return false;
            },
            function (data) {
                spinner.stop();
                element.find('input[name="location"]').addClass('is-invalid');
                element.find('.invalid-feedback').text('No results found. Please enter a new value and try again.');
                return false;
            }
        );
    }
}

function prepareSearch(form, definedRadius) {
    var $location = $(form).find('input[name="location"]');
    if (!$location[0].validity.valid) {
        $location.addClass('is-invalid');
        $(form).find('.invalid-feedback').text($location[0].validationMessage);
        addErrorPositioning();
        $('body').trigger('storeLocator:error');
    } else {
        $location.removeClass('is-invalid');
        removeErrorPositioning();
        search($(form), definedRadius);
    }
}

function closeDropdown($mapDiv) {
    $('.show-map').text('Show map');
    $('.modal-background').hide();
    $mapDiv.css({
        visibility: 'hidden',
        opacity: '0'
    });
    $mapDiv.removeClass('show');
    $('.header').removeClass('store-dropdown-show');
    $('.store-dropdown-toggle').removeClass('store-menu-show');
    $('.local-store-border-horizontal').addClass('d-none');
    $('.local-store-border-vertical').addClass('d-none');
    $('.modal-background').css('z-index', '');
}

function addResults($element, $parent, from, to) {
    var isStoreLocatorPage = $parent.hasClass('store-locator-page') ? true : false;
    var dialog = $element.closest('.in-store-inventory-dialog');
    var spinner = dialog.length ? dialog.spinner() : $.spinner();
    spinner.start();
    var $resultsDiv = $element.parent().siblings('.results');
    var mapdiv = $parent.find('.map-canvas').attr('data-locations');
    if (mapdiv) {
        mapdiv = JSON.parse(mapdiv);
        if (!to || to >= mapdiv.length) {
            to = mapdiv.length;
            if ($resultsDiv.siblings('.show-more-container').length) {
                $resultsDiv.siblings('.show-more-container').find('.btn-show-more').addClass('d-none');
            }
        }
        if ($('.toggle-pickup-btn').is(':checked')) {
            var hasMoreInStock = false;
            for (var j = from; j < mapdiv.length; j++) {
                if (mapdiv[j].available) {
                    hasMoreInStock = true;
                }
            }
            if (!hasMoreInStock) {
                $resultsDiv.siblings('.show-more-container').find('.btn-show-more').addClass('d-none');
            }
        }
        var aligment = $('div.result-col').hasClass('px-xl-1') ? 'px-xl-1' : 'px-xl-3';
        for (var i = from; i < to; i++) {
            var $col = $('<div/>');
            if (!isStoreLocatorPage) {
                $col.addClass(`col-12 result-col  ${aligment} additional-result`);
            }
            var $card = $('<div/>').addClass('card results-card store-locator-card-height');
            var $cardBody = $('<div/>').addClass('card-body d-flex flex-column justify-content-between store-result');
            $cardBody.attr({
                'data-store-id': mapdiv[i].storeID,
                id: mapdiv[i].storeID
            });
            $cardBody.html(mapdiv[i].infoWindowHtml);
            $cardBody.find('.store-marker').text(i + 1 + '.');
            $card.html($cardBody);
            $col.html($card);

            if ($cardBody.children('.no-pickup-detail').length || !mapdiv[i].available) {
                $card.addClass('no-pickup-result');
            }

            $resultsDiv.append($col);
        }
    }
}

function storeSearch(position) {
    $('.timeout-geolocation-alert').addClass('d-none');
    var url = prepareGeolocationSearch(
        $('.header-results-panel .detect-location'),
        $('.header-results-panel'),
        position
    );
    url += '&setDefault=true&isGeolocation=true';
    $.ajax({
        url: url,
        dataType: 'json',
        type: 'get',
        success: function (data) {
            if (data.store && data.pinnedHtml) {
                $('.header-store-nickname').text(data.store.storeNickname);
                $('.select-store-title').text(data.myStoreMessage);
                $('.header-sub-font-size').removeClass('d-none');
                $('.pinned-local-store-container .pinned-result').removeClass('d-none').html(data.pinnedHtml);
                if (data.customerZipCode && $('.delivery-to-code').length > 0) {
                    $('.delivery-to-code').text(data.customerZipCode);
                }
            }
            $('.local-store-border-vertical').removeClass('no-local-store-border-vertical-height');
            $('.local-store-border-horizontal').removeClass('no-local-store-border-horizontal-height');
            $('.select-store-dropdown').spinner().stop();
            window.localStorage.setItem('hasCheckedGeolocation', true);
            var tries = window.localStorage.getItem('countTriesGeolocation');
            window.localStorage.setItem('countTriesGeolocation', tries + 1);
        },
        error: function (e) {
            $('.local-store-border-vertical').removeClass('no-local-store-border-vertical-height');
            $('.local-store-border-horizontal').removeClass('no-local-store-border-horizontal-height');
            $('.select-store-dropdown').spinner().stop();
            var tries = window.localStorage.getItem('countTriesGeolocation');
            window.localStorage.setItem('countTriesGeolocation', tries + 1);
        }
    });
}

function handleLocationPermissions(permissionStatus) {
    if (permissionStatus.state !== 'blocked' && permissionStatus.state !== 'denied' && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            function (position) {
                storeSearch(position);
            },
            function (e) {
                $('.select-store-dropdown').spinner().stop();
                storeSearch(null);
            },
            {
                timeout: 10000
            }
        );
    } else {
        storeSearch(null);
        $('.select-store-dropdown').spinner().stop();
    }
}

function init() {
    if ($('.map-canvas').data('has-google-api')) {
        if ($('.store-locator-page').is('*')) {
            maps($('.store-locator-page'));
        } else if ($('.store-details-page-container').is('*')) {
            maps($('.store-details-page-container'));
        } else if ($('.store-loc-dropdown-map').is('*')) {
            maps($('.store-loc-dropdown-map'));
        }
    } else {
        $('.store-locator-no-apiKey').show();
    }
    if (!$('.results').data('has-results')) {
        $('.store-locator-no-results').show();
    }

    if ($('.store-map-container-inner').length > 0 && $('.store-map-container-inner').hasClass('lpm-store')) {
        var lat = $('.store-map-container-inner').find('.store-map-container').data('latitude');
        var lng = $('.store-map-container-inner').find('.store-map-container').data('longitude');
        var latlng = { lat: lat, lng: lng };
        var map = new google.maps.Map(document.getElementById('store-header-map-container'), {
            zoom: 12,
            center: latlng
        });
        var marker = new google.maps.Marker({
            position: latlng,
            map: map
        });
    }

    $('.store-dropdown-toggle').on('click', function (e) {
        if (!$('.store-locator-modal-content').length) {
            $.spinner().start();
            $.ajax({
                url: $('.select-store-dropdown').data('url'),
                method: 'GET',
                success: function (response) {
                    $('.select-store-dropdown-menu').html(response);
                },
                complete: function () {
                    $.spinner().stop();
                    if (!$('.select-store-dropdown-menu').hasClass('show') && $(window).width() > 768) {
                        e.preventDefault();
                        e.stopPropagation();
                        e.stopImmediatePropagation();
                        $('.store-dropdown-toggle').attr('data-toggle', '');
                        $('.leslies-header-store-selected').removeClass('d-flex');
                        $('.leslies-header-triangle').removeClass('d-block');
                        $('.store-dropdown-toggle').attr('data-toggle', 'dropdown').dropdown('toggle');
                    }
                    $('.select-store-dropdown').trigger('show.bs.dropdown');
                }
            })
        } else {
            if (
                $('.leslies-header-store-selected').length > 0 &&
                $('.leslies-header-store-selected').hasClass('leslies-header-store-selected-active') &&
                !$('.select-store-dropdown-menu').hasClass('show') &&
                $(window).width() > 768
            ) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
                $('.store-dropdown-toggle').attr('data-toggle', '');
                $('.leslies-header-store-selected').removeClass('d-flex');
                $('.leslies-header-triangle').removeClass('d-block');
                if ($('.store-dropdown-toggle').attr('newSearchNeeded')) {
                    // Do a new search in the store modal if the store locator page search was different
                    prepareSearch($('.dropdown .store-locator'));
                    $('.store-dropdown-toggle').removeAttr('newSearchNeeded');
                }
                $('.store-dropdown-toggle').attr('data-toggle', 'dropdown').dropdown('toggle');
                if ($('.results').data('result-length') === 0) {
                    $('.store-list-panel').removeClass('d-none');
                }
            }
        }
    });

    $('#maincontent, .main-menu').on('click', function () {
        if ($('.leslies-header-store-selected').hasClass('d-flex')) {
            $('.store-dropdown-toggle').attr('data-toggle', '');
            $('.leslies-header-store-selected').removeClass('d-flex');
            $('.leslies-header-triangle').removeClass('d-block');
        }
    });

    $('body').on('click', '.store-details-container-bottom-link', function (e) {
        e.preventDefault();
        e.stopPropagation();

        // Clean up store locator when buy now is closed
        if ($('.header-results-panel').hasClass('buy-now-store-locator')) {
            $('nav .header, .secondary-header-styling').removeAttr('style');
        }
        if (!$('.buy-now-header-store-locator').hasClass('d-none')) {
            $('.buy-now-header-store-locator').addClass('d-none');
        }
        // Finish buy now treatments

        $('.leslies-header-store-selected').removeClass('d-flex');
        $('.leslies-header-triangle').removeClass('d-block');
        $('.store-dropdown-toggle').attr('data-toggle', 'dropdown').dropdown('toggle');
    });

    $('body').on('click', '#store-locator-close-button', function (e) {
        e.preventDefault();
        $('.dropdown-menu.dropdown-menu-right').removeClass('show');
        $('.modal-background').removeAttr('style');
    });

    // update mobile header store information (cache issue)
    var storeInfoDropdownHeader = $('#selectStoreDropdownToggle .header-store-nickname');
    var storeId = storeInfoDropdownHeader.data('store-id');
    var storeName = storeInfoDropdownHeader.text();

    if (storeId && storeName) {
        $('#sg-navbar-collapse .header-store-nickname').attr('data-store-id', storeId);
        $('#sg-navbar-collapse .header-store-nickname').text(storeName);
    }
}

function handleTotalAvailable() {
    if ($('.total-available-container').length) {
        var totalAvailable = $('.selected-product-info').data('total');
        if (parseInt(totalAvailable) > 0) {
            var totalAvailableHtml = $('.selected-product-info').attr('data-total-html');
            $('.total-available-msg').text(totalAvailableHtml);
        }
    }
}

function getCookie(name) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function checkLocation() {
    var hasCheckedGeolocation = window.localStorage.getItem('hasCheckedGeolocation');
    var locationPrompFlag = getCookie('loc_prompt');
    var customerZipCode = getCookie('customerZipCode');

    var tries = window.localStorage.getItem('countTriesGeolocation');
    if (!tries) {
        window.localStorage.setItem('countTriesGeolocation', 0);
        tries = 0;
    }
    if (
        (!hasCheckedGeolocation && !$('.store-locator-page').is('*') && !locationPrompFlag && tries < 2) ||
        !customerZipCode
    ) {
        $('.select-store-dropdown').spinner().start();
        var expires = '';
        var date = new Date();
        var year = date.getFullYear();
        date.setFullYear(++year);
        date.setTime(date.getTime());
        expires = '; expires=' + date.toUTCString();
        document.cookie = 'loc_prompt' + '=' + 't' + expires + '; path=/';
        if (navigator.permissions && navigator.permissions.query) {
            navigator.permissions.query({ name: 'geolocation' }).then(function (permissionStatus) {
                handleLocationPermissions(permissionStatus);
                permissionStatus.onchange = function () {
                    $('.select-store-dropdown').spinner().start();
                    handleLocationPermissions(permissionStatus);
                };
            });
        } else {
            $('.select-store-dropdown').spinner().stop();
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    function (position) {
                        storeSearch(position);
                    },
                    function (err) {
                        //error
                    },
                    {
                        timeout: 1000
                    }
                );
            } else {
                storeSearch(null);
            }
        }
    }

    if (
        $('.store-locator-page').is('*') &&
        $('.location-input-field').length > 0 &&
        $('.location-input-field').val() == ''
    ) {
        if ($('.delivery-to-code') && $('.delivery-to-code').first().text() != '') {
            $('.location-input-field').val($('.delivery-to-code').first().text());
            $('.btn-storelocator-search').trigger('click');
        }
    }
}

function handleStoreBtns($that, data, isStoreLocatorPage, isStoreModalOnPage) {
    var storeDetailsContainer = $that.closest('.store-details-container');
    var defaultMsg = storeDetailsContainer.data('select-as-my-store-message');
    var myStoreMsg = $('.js-is-my-store-html').html();
    $('.is-my-store').each(function () {
        if ($(this).data('store-id') !== data.storeID) {
            $(this).removeClass('is-my-store').removeClass('disabled').text(defaultMsg);
        }
    });
    $that.addClass('is-my-store').html(myStoreMsg);

    // Specific cases for Store Locator Page Quick fixes
    try {
        if (isStoreLocatorPage) {
            var $otherStoreButton = $('.dropdown-results .select-my-store[data-store-id="' + data.storeID + '"]');
            if ($otherStoreButton.length) {
                $otherStoreButton.addClass('is-my-store').html(myStoreMsg);
            } else {
                $('.dropdown .location-input-field').val($('.store-locator-input').val());
                $('.store-dropdown-toggle').attr('newSearchNeeded', true);
            }
        }
        if (isStoreModalOnPage) {
            var $otherStoreButton = $('.locator .select-my-store[data-store-id="' + data.storeID + '"]');
            if ($otherStoreButton.length) {
                $otherStoreButton.addClass('is-my-store').html(myStoreMsg);
            } else {
                window.location.reload();
            }
        }
    } catch (e) {
        $.spinner().stop();
    }
}

function handleRadiusExpand(storesData) {
    var radius = storesData.radius;
    var radiusConfigs = storesData.radiusConfigs;
    var noStoreRadius = radiusConfigs.storeLocatorPageRadius;

    if (radiusConfigs) {
        if (storesData.prevRadius && storesData.prevRadius < storesData.radius) {
            $('.js-far-away').removeClass('d-none');
        } else {
            $('.js-far-away').addClass('d-none');
        }

        if (radius === radiusConfigs.maxSearchRadiusStoreLocatorPage) {
            noStoreRadius = radiusConfigs.defaultLocationRadius;
        }

        if (!storesData.stores.length) {
            noStoreRadius = radiusConfigs.maxSearchRadiusStoreLocatorPage;
        }

        $('.js-far-away-distance').text(noStoreRadius);

        if (radius === radiusConfigs.defaultLocationRadius) {
            $('.js-expand-stores').removeClass('d-none');
            $('.js-expand-stores-radius').text(radiusConfigs.defaultLocationRadius);
            expandSearchRadius(radiusConfigs.defaultLocationRadius);
        } else {
            $('.js-expand-stores').addClass('d-none');
        }
    }
}

function expandSearchRadius(radius) {
    $('.js-expand-stores-search').on('click', function (e) {
        e.preventDefault();
        var form = $('.btn-storelocator-search[type="button"]').parents('.store-locator');
        prepareSearch(form, radius);
    });
}

function handleStoreDetails(open, $this) {
    var isDropdown = $($this).closest('.store-locator-modal-content').length;
    var $storeDiv = $($this).parents('.store-result');
    var label = '';
    var action = open ? 'close' : 'open';

    if (open) {
        $($this).addClass('opened');
        $storeDiv.find('.additional-details').removeClass('d-none');
        $storeDiv.find('.store-detail-expanded').removeClass('d-none').addClass('d-flex');
    } else {
        $($($this)).removeClass('opened');
        $storeDiv.find('.additional-details').addClass('d-none');
        $('.store-detail-expanded').removeClass('d-flex').addClass('d-none');
    }

    label = !!isDropdown ? $($this).attr('data-text-sl-modal-' + action) : $($this).attr('data-text-sl-page-' + action);
    $($this).find('.js-store-detail-toggle-lable').text(label);
}

var exports = {
    init: init,
    checkLocation: checkLocation,
    handleTotalAvailable: handleTotalAvailable,
    search: function () {
        $(document).on('submit', '.store-locator-container form.store-locator', function (e) {
            e.preventDefault();
            prepareSearch(this);
        });
        $(document).on('click', '.store-locator-container .btn-storelocator-search[type="button"]', function (e) {
            e.preventDefault();
            prepareSearch($(this).parents('.store-locator'));
            scrollAnimate();
        });
        $(document).on('blur', '.store-locator-container .location-input-field', function () {
            if ($(this).attr('id') !== 'store-location-panel') {
                prepareSearch($(this).parents('.store-locator'));
            }
        });
        $(document).on('keypress', '.store-locator-container .location-input-field', function (e) {
            var keycode = e.keyCode ? e.keyCode : e.which;
            if (keycode == '13') {
                prepareSearch($(this).parents('.store-locator'));
            }
        });
        $(document).on('click', '.store-results-container .btn-show-all', function (e) {
            e.preventDefault();
            var dialog = $(this).closest('.in-store-inventory-dialog');
            var spinner = dialog.length ? dialog.spinner() : $.spinner();
            spinner.start();
            addResults($(this), $('.store-locator-page'), 6);
            $('.omni-disabled').closest('div.result-col').remove();
            $(this).addClass('d-none');
            spinner.stop();
        });
        $(document).on('click', '.store-results-container .btn-show-less', function () {
            if ($(this).data('show') === 'less') {
                $('.additional-result').addClass('d-none');
                $(this).text('Show more results').data('show', 'all');
                $('.map-canvas').get(0).scrollIntoView();
            } else {
                $('.additional-result').removeClass('d-none');
                $(this).text('Show less results').data('show', 'less');
            }
            setTimeout(function () {
                distancesHandler();

            }, 500);
        });
    },
    dropdownSearch: function () {
        var closeModal = true;
        $(document).on('mousedown', function (e) {
            if (
                $(e.target).parents('.select-store-drop-item').length &&
                (!$(e.target).hasClass('select-my-store') || $(e.target).hasClass('is-my-store'))
            ) {
                closeModal = false;
            } else {
                closeModal = true;
            }
        });
        $('.select-store-dropdown').on('hide.bs.dropdown', function (e) {
            var $mapDiv = window.matchMedia('(max-width: 767px)').matches ? $('.mobile-map') : $('.page-wide-map');
            if (
                !e.clickEvent ||
                $(e.clickEvent.target).hasClass('close-select-store') ||
                $(e.clickEvent.target).parents('.store-locator-close-button').length
            ) {
                closeDropdown($mapDiv);
                return true;
            }

            var hide =
                !(
                    $(e.clickEvent.target).parents('.select-store-drop-item').length &&
                    !$(e.clickEvent.target).hasClass('select-my-store')
                ) && closeModal;
            if (hide) {
                closeDropdown($mapDiv);
            }
            return hide;
        });
        $('.select-store-dropdown').on('show.bs.dropdown', function (e) {
            if ($('.store-locator-modal-content').length) {
                $('.modal-background').show();
                $('.modal-background').css({ 'z-index': 2 })
                $('.local-store-border-horizontal').removeClass('d-none');
                $('.local-store-border-vertical').removeClass('d-none');
                $('.header').addClass('store-dropdown-show');
                $('.store-dropdown-toggle').addClass('store-menu-show');
                if (($(this).find('.store-info-container').length < 2 && !$('.store-locator-page').is('*')) || ($('.store-dropdown-toggle').attr('data-force-refresh') === 'true')) {
                    $('.store-dropdown-toggle').attr('data-force-refresh', 'false');
                    $('.omni-disabled').closest('div.result-col').remove()
                    if ($('.pinned-result .postal-code').is('*')) {
                        var zipcode = $('.pinned-result').find('.postal-code').text();
                        $(this).find('input[name="location"]').val(zipcode.slice(0, 5));
                        $('.header-store-form .btn-storelocator-search[type="button"]').trigger('click');
                    } else {
                        if (navigator.permissions && navigator.permissions.query) {
                            navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
                                if (result.state !== 'denied' && result.state !== 'blocked') {
                                    $('.header-store-form .detect-location').trigger('click');
                                }
                            });
                        } else if (navigator.geolocation) {
                            navigator.geolocation.getCurrentPosition(
                                function () {
                                    $('.header-store-form .detect-location').trigger('click');
                                },
                                function (err) {
                                    //error
                                },
                                {
                                    timeout: 1000
                                }
                            );
                        }
                    }
                } else if ($('.selected-product-container').children().length) {
                    var $mapParent = window.matchMedia('(max-width: 767px)').matches ? $('.mobile-map') : $('.page-wide-map');
                    if (
                        $mapParent.find('.store-locator-no-apiKey').is('*') &&
                        $mapParent.find('.map-canvas').data('has-google-api')
                    ) {
                        maps($mapParent, true);
                    }
                }
            }
        });
        $('body').on('click', '.store-detail-open:not(.opened)', function (e) {
            e.preventDefault();

            handleStoreDetails(true, this);
        });
        $('body').on('click', '.store-detail-open.opened', function (e) {
            e.preventDefault();

            handleStoreDetails(false, this);
        });
        $('.select-store-dropdown').on('click', '.btn-show-more', function () {
            var dialog = $(this).closest('.in-store-inventory-dialog');
            var spinner = dialog.length ? dialog.spinner() : $.spinner();
            spinner.start();
            var length = $('.dropdown-results').find('.store-result').length;
            var $parent = window.matchMedia('(max-width: 767px)').matches ? $('.mobile-map') : $('.page-wide-map');
            addResults($(this), $parent, length, length + 6);
            $('.omni-disabled').closest('div.result-col').remove();
            var selectAsMyStoreBtn = $('.select-my-store').not(
                '.selected-result.pinned-result .select-my-store, .is-my-store'
            );
            var storeContainer = $('.store-details-container[data-is-product-search-init]');
            if (selectAsMyStoreBtn.length && storeContainer.length) {
                var isSelectAStore = $('#bopis-unselected').length && !$('#bopis-unselected').hasClass('d-none');
                var dataMessage = isSelectAStore ? 'select-this-store-msg' : 'select-as-my-store-message';
                selectAsMyStoreBtn.text(storeContainer.data(dataMessage));
            }
            spinner.stop();
        });
        $(document).on('click', '.toggle-map-btn', function () {
            var $mapDiv = window.matchMedia('(max-width: 767px)').matches ? $('.mobile-map') : $('.page-wide-map');
            if ($mapDiv.hasClass('show')) {
                $mapDiv.css({
                    visibility: 'hidden',
                    opacity: '0'
                });
                $mapDiv.removeClass('show');
                $(this).find('.show-map').text('Show map');
            } else {
                $mapDiv.css({
                    visibility: 'visible',
                    opacity: '1'
                });
                if (window && window.locatorMap && window.locatorMap.getZoom() <= 5) {
                    window.locatorMap.setZoom(10);
                }
                $mapDiv.addClass('show');
                $(this).find('.show-map').text('Hide map');
            }
        });
        $(document).on('change', '.toggle-pickup-btn', function () {
            var $mapParent = window.matchMedia('(max-width: 767px)').matches ? $('.mobile-map') : $('.page-wide-map');
            var pickupOnly;
            if ($(this).is(':checked')) {
                $('.dropdown-results').addClass('show-pickup-only');
                if ($('.store-details-container').not('.no-pickup-detail').length <= 3) {
                    $('.btn-show-more').addClass('d-none');
                }
                $('.store-pickup-info')
                    .toArray()
                    .forEach(function (element) {
                        if ($(element).data('instock') != true) {
                            $(element)
                                .closest('.store-info-container')
                                .closest('.store-locator-card')
                                .addClass('d-none');
                            var card = $(element).closest('.store-info-container');
                            $(card).closest('.card.results-card.store-locator-card-height').addClass('d-none');
                        }
                    });
                if ($('.unable-store-message').length > 0) {
                    $('.unable-store-message').parents('.results-card').parent().addClass('d-none');
                }
                pickupOnly = true;
            } else {
                var resultsLength = parseInt($('.results').data('results-length'));
                $('.dropdown-results').removeClass('show-pickup-only');

                if (resultsLength > 3 && !($('.results-card.store-locator-card-height').length === resultsLength)) {
                    $('.btn-show-more').removeClass('d-none');
                }
                pickupOnly = false;
                $('.store-pickup-info')
                    .toArray()
                    .forEach(function (element) {
                        if ($(element).data('instock') != true) {
                            $(element)
                                .closest('.store-info-container')
                                .closest('.store-locator-card')
                                .addClass('d-none');
                            var card = $(element).closest('.store-info-container');
                            $(card).closest('.card.results-card.store-locator-card-height').removeClass('d-none');
                        }
                    });
                if ($('.unable-store-message').length > 0) {
                    $('.unable-store-message').parents('.results-card').parent().removeClass('d-none');
                }
            }

            maps($mapParent, pickupOnly);
        });
        $(document).on('click', '.change-location', function () {
            $('.dropdown-store-locator-container').removeClass('d-none');
        });
        $(document).on('click', '.check-stores', function (e) {
            if (!$('.store-locator-modal-content').length) {
                e.preventDefault();
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
            $('.leslies-header-store-selected').removeClass('d-flex');
            $('.leslies-header-triangle').removeClass('d-block');
        });
        $('body').on('click', '.js-init-map', function (e) {
            e.preventDefault();
            var $storeDiv = $(this).parents('.store-result');
            if ($storeDiv.find('.store-time-locator').length) {
                $(this).removeClass('js-init-map');
                maps($storeDiv, true);
            }
        });
    },
    detectLocation: function () {
        // clicking on detect location.
        $(document).on('click', '.detect-location', function (e) {
            e.preventDefault();
            var dialog = $(this).closest('.in-store-inventory-dialog');
            var spinner = dialog.length ? dialog.spinner() : $.spinner();
            spinner.start();
            if (navigator.permissions && navigator.permissions.query) {
                navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
                    if (result.state === 'denied' || result.state === 'blocked') {
                        $('.js-geolocation-alert').removeClass('d-none');
                        $('.results').addClass('d-none');
                        $('.show-more-container .btn-show-more').addClass('d-none');
                        $('.store-results-container .btn-show-all').addClass('d-none');
                        spinner.stop();
                        return;
                    } else if (result.state === 'prompt') {
                        navigator.geolocation.getCurrentPosition(
                            function () {
                                $('.js-geolocation-alert').addClass('d-none');
                                $('.results').removeClass('d-none');
                            },
                            function () {
                                $('.js-geolocation-alert').removeClass('d-none');
                                $('.results').addClass('d-none');
                                $('.show-more-container .btn-show-more').addClass('d-none');
                                spinner.stop();
                            },
                            {
                                timeout: 1000
                            }
                        );
                    } else {
                        $('.js-geolocation-alert').addClass('d-none');
                        $('.results').removeClass('d-none');
                    }
                });
            } else {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        function () {
                            $('.js-geolocation-alert').removeClass('d-none');
                            $('.results').addClass('d-none');
                            $('.show-more-container .btn-show-more').addClass('d-none');
                            spinner.stop();
                        },
                        function () {
                            $('.js-geolocation-alert').addClass('d-none');
                            $('.results').removeClass('d-none');
                        },
                        {
                            timeout: 1000
                        }
                    );
                } else {
                    $('.js-geolocation-alert').removeClass('d-none');
                    $('.results').addClass('d-none');
                    spinner.stop();
                }
            }

            $('input[name="location"]').removeClass('is-invalid').val('');
            var $detectLocationButton = $(this);
            var $parent = $detectLocationButton.parents('.dropdown').length
                ? $('.select-store-dropdown-menu')
                : $('.store-locator-page');

            $('.timeout-geolocation-alert').addClass('d-none');

            $('.local-store-border-vertical').addClass('no-local-store-border-vertical-height');
            $('.local-store-border-horizontal').addClass('no-local-store-border-horizontal-height');
            $('.timeout-geolocation-alert').removeClass('d-none');
            $.spinner().stop();

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    function (position) {
                        $('.timeout-geolocation-alert').addClass('d-none');
                        var url = prepareGeolocationSearch($detectLocationButton, $parent, position);
                        var urlParams = {
                            customerLocation: true
                        };
                        url = appendToUrl(url, urlParams);
                        spinner.start();
                        $.ajax({
                            url: url,
                            type: 'get',
                            dataType: 'json',
                            success: function (data) {
                                updateStoresResults($detectLocationButton, data);
                                $('.select-store').prop('disabled', true);
                                removeErrorPositioning();
                                if (
                                    $parent.hasClass('select-store-dropdown-menu') &&
                                    !$('.header-results-panel').hasClass('product-pickup-search') &&
                                    !$parent.find('.pinned-local-store-container .pinned-result').children().length
                                ) {
                                    $parent.find('.select-my-store').first().trigger('click');
                                }
                                if ($parent.find('.location-input-field').length) {
                                    googleMapsHelpers.getZipCode(
                                        position.coords.latitude,
                                        position.coords.longitude,
                                        $parent.find('.store-locator').data('mapsApiKey'),
                                        function (data) {
                                            $parent.find('.location-input-field').val(data.zipcode);
                                            spinner.stop();
                                        },
                                        function (data) {
                                            spinner.stop();
                                        }
                                    );
                                } else {
                                    spinner.stop();
                                }
                            }
                        });
                    },
                    function (err) {
                        //error
                        spinner.stop();
                    },
                    {
                        timeout: 1000
                    }
                );
            }
        });
    },
    changeRadius: function () {
        $(document).on('change', '.store-locator-container .radius', function () {
            var element = $(this);
            var radius = $(this).val();
            var $parent = element.parents('.store-locator-page').length
                ? $('.store-locator-page')
                : $('.select-store-dropdown-menu');
            var searchKeys = $parent.find('.results').data('search-key');
            var url = $(this).data('actionUrl');
            var urlParams = {};
            if (typeof url == 'undefined') {
                return false;
            }
            if (searchKeys.postalCode) {
                urlParams = {
                    radius: radius,
                    postalCode: searchKeys.postalCode,
                    pools: $('[name="installationService"]').prop('checked'),
                    service: $('[name="commercialServiceCenter"]').prop('checked'),
                    countryCode: 'US',
                    referrer: element.closest('.store-locator').data('referrer')
                };
            } else if (searchKeys.lat && searchKeys.long) {
                urlParams = {
                    radius: radius,
                    lat: searchKeys.lat,
                    long: searchKeys.long,
                    pools: $('[name="installationService"]').prop('checked'),
                    service: $('[name="commercialServiceCenter"]').prop('checked'),
                    countryCode: 'US',
                    referrer: element.closest('.store-locator').data('referrer')
                };
            }
            url = appendToUrl(url, urlParams);
            var dialog = $(this).closest('.in-store-inventory-dialog');
            var spinner = dialog.length ? dialog.spinner() : $.spinner();
            spinner.start();
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    spinner.stop();
                    updateStoresResults(element, data);
                    $('.select-store').prop('disabled', true);
                }
            });
        });
    },
    selectStore: function () {
        $('body').on('click', '.select-my-store', function (e) {
            e.preventDefault();
            if ($(this).hasClass('is-my-store')) {
                return;
            }
            if (!$('#bopis-unselected').hasClass('d-none')) {
                $('#bopis-unselected').addClass('d-none');
            }
            $('#bopis-selected').removeClass('d-none');
            $.spinner().start();
            var $selectedStore;
            var $results;
            var url = $(this).data('action');
            var $that = $(this);

            if ($(this).parents('.dropdown').length || $(this).parents('.store-locator-page').length) {
                var isStoreLocatorPage = $(this).parents('.store-locator-page').length;
                var isStoreModalOnPage = $(this).parents('.dropdown').length && $('.store-locator-page').length;
                $selectedStore = $(this).parents('.store-result');
                $results = $(this).parents('.results');
                var $productDiv = $('.header-results-panel').find('.selected-product-info');
                if (
                    $(this).parents('.dropdown').length &&
                    $productDiv.data('uuid') !== '' &&
                    $productDiv.data('pid') !== ''
                ) {
                    var productId = findCorrespondingShipProduct($productDiv.data('pid'));
                    var radius = $(document).find('.dropdown-store-locator-container').find('.dropdown-radius').val();
                    radius = radius.replace(' Mile Radius', '');
                    url = appendToUrl(url, {
                        uuid: $productDiv.data('uuid'),
                        pid: productId,
                        qty: $productDiv.data('qty'),
                        radius: radius
                    });
                }

                var timezone = new Date()
                    .toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'short' })
                    .substring(4);
                if (timezone && timezone != 'undefined') {
                    url = appendToUrl(url, { timezone: timezone });
                }

                var timezone = new Date()
                    .toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'short' })
                    .substring(4);
                if (timezone && timezone != 'undefined') {
                    url = appendToUrl(url, { timezone: timezone });
                }

                var timezone = new Date()
                    .toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'short' })
                    .substring(4);
                if (timezone && timezone != 'undefined') {
                    url = appendToUrl(url, { timezone: timezone });
                }
                if (Window.buyNowSelectStores) {
                    url = appendToUrl(url, { isBuynow: true });
                }
            }
            Window.buyNowStoreSelected = true;
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                success: function (data) {
                    if ($('.page').attr('data-action') === 'Account-LocalStore') {
                        window.location.reload();
                    }
                    if ($('.page').attr('data-action') === 'Cart-Show') {
                        window.location.reload();
                    }
                    let isSubstitutionBundleForStore = $('[data-substitution-bundle="true"]').length > 0;
                    if (data.isProductWithSameSTS || isSubstitutionBundleForStore) {
                        window.location.reload();
                    } else if ($selectedStore) {
                        var storeZipCode = $that.parents('.store-details-container ').find('.postal-code').text();
                        if (storeZipCode.length > 0 && storeZipCode.indexOf('-') != -1) {
                            storeZipCode = storeZipCode.split('-')[0];
                        }
                        $('.delivery-to-code').text(storeZipCode);
                        updateStoreDropdown($results, $selectedStore, data);
                        $('body').trigger('product:updateDeliveryCard', data);
                        if ($('#deliveryToPostalPdp').length > 0 && data.postalCode) {
                            $('#deliveryToPostalPdp').val(data.postalCode);
                            $('.submit-pdp-postal').trigger('click');
                        }

                        if ($('.buynow-button-plp').length > 0 && !Window.buyNowSelectStores) {
                            location.reload();
                        }
                        handleStoreBtns($that, data, isStoreLocatorPage, isStoreModalOnPage);
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    }
                    if (
                        (window.location.href.indexOf('cart') > -1 && !Window.buyNowSelectStores) ||
                        (data && data.queryString && data.queryString.indexOf('referrer=details') > -1)
                    ) {
                        window.location.reload();
                    }
                    if ($('.js-page').attr('data-action') == 'Search-Show') {
                        window.location.reload();
                    }
                    let productId = $('.product-id').length > 0 ? $($('.product-id')[0]).text() : '';
                    if ($('.js-page').attr('data-action') == 'Product-Show') {
                        $('body').trigger('gtm:pdpSelectStore', {
                            'productName': $('.product-name').text().trim(),
                            'productId': productId,
                            'storeInfo': data.storeName
                        });
                    }

                    if (Window.buyNowSelectStores) {
                        $('body').trigger('buynow:reloadModal');
                    }
                    $.spinner().stop();
                },
                error: function (data) {
                    $.spinner().stop();
                }
            });
        });

        $('body').on('click', '.continue-store', function (e) {
            $('.dropdown-menu.dropdown-menu-right').removeClass('show');
            $('.modal-background').removeAttr('style');
            if (Window.buyNowSelectStores) {
                $('body').trigger('buynow:reloadModal');
            }
        });
    },

    deliveryToSection: function () {
        $(document).on('click', '.update-store-link', function (e) {
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
            $('.pinned-result').find('.postal-code').text($('#deliveryToZip').val());
            $($('.store-details-container-bottom-link').get(0)).trigger('click');
            $('.delivery-to-tooltip .tooltip').addClass('d-none');
        });
    },
    handleMenuDropDown: function () {
        $('body').on('click', '.check-stores', function () {
            var $this = $(this);
            if (!$('.store-locator-modal-content').length) {
                $.spinner().start();
                $.ajax({
                    url: $('.select-store-dropdown').data('url'),
                    method: 'GET',
                    success: function (response) {
                        $('.select-store-dropdown-menu').html(response);
                    },
                    complete: function () {
                        $.spinner().stop();
                        $('.modal-background').show();
                        $('.modal-background').css('opacity', '0.75');
                        if ($this.data('buynow')) {
                            Window.buyNowSelectStores = true;
                            Window.buyNowStoreSelected = false;
                        }
                        $('body').trigger('storelocator:loaded', { elem: $this });
                    }
                })
            } else {
                $('.modal-background').show();
                $('.modal-background').css('opacity', '0.75');
                if ($this.data('buynow')) {
                    Window.buyNowSelectStores = true;
                    Window.buyNowStoreSelected = false;
                }
                $('body').trigger('storelocator:loaded', { elem: $this });
            }
        });

        $('.select-store-dropdown')
            .off('hidden.bs.dropdown')
            .on('hidden.bs.dropdown', function () {
                if (Window.buyNowSelectStores && Window.buyNowStoreSelected != true) {
                    $('body').trigger('buynow:reloadModal');
                }
            });
    },
    searchStoresByMap: function () {
        if (!$('.store-locator-page').length) return;
        $('body').on('click', '.store-search-input', function (e) {
            e.preventDefault();
            var $parent = $('.store-locator-page');
            var spinner = $parent.spinner();
            var $detectLocationButton = $(this);
            var position = {
                coords: {
                    latitude: window.locatorMap.center.lat(),
                    longitude: window.locatorMap.center.lng()
                }
            };
            var url = prepareGeolocationSearch($detectLocationButton, $parent, position);
            spinner.start();
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    updateStoresResults($detectLocationButton, data);
                    $('.select-store').prop('disabled', true);
                    removeErrorPositioning();
                    if (
                        $parent.hasClass('select-store-dropdown-menu') &&
                        !$('.header-results-panel').hasClass('product-pickup-search') &&
                        !$parent.find('.pinned-local-store-container .pinned-result').children().length
                    ) {
                        $parent.find('.select-my-store').first().trigger('click');
                    }
                    if (data.locations && data.locations.length) {
                        maps($parent);
                    }
                    spinner.stop();
                }
            });
        });
    },
    handleMarkers: handleMarkers,
    prepareGeolocationSearch: prepareGeolocationSearch
};

module.exports = $.extend({}, base, exports);
