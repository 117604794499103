import $ from 'jquery';

var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('./components/menu'));
    processInclude(require('base/components/cookie'));
    processInclude(require('refArch/components/consentTracking'));
    processInclude(require('./components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('base/components/search'));
    processInclude(require('./components/search'));
    processInclude(require('./components/clientSideValidation'));
    //processInclude(require('base/components/countrySelector'));
    processInclude(require('refArch/components/toolTip'));
    processInclude(require('./components/imageModal'));
    processInclude(require('./components/productTileCarousel'));
    processInclude(require('./components/liveChat'));
    // processInclude(require('./product/quickView')); // Commented for DEV-9232
    processInclude(require('googleTagManager/googleTagManager'));
    processInclude(require('./components/quickBuy'));
    processInclude(require('./customForm/customForm'));
    processInclude(require('./storeLocator/storeLocator'));
    processInclude(require('./components/affirmUI'));
    processInclude(require('./promiseDates'));
    processInclude(require('./bloomreach'));
    processInclude(require('./partsFinder'));
    // TODO: make this page scoped:
    // processInclude(require('./speedPumpCalculator/speedPumpCalculator')); // Commented for DEV-9232
    // processInclude(require('oneDayDelivery/components/oneDayDeliveryHeader'));
    processInclude(require('refArch/login/loginFlyout'));
});

require('./thirdParty/lazysizes.min.js');
require('./thirdParty/bootstrap');
require('base/components/spinner');
require('slick-carousel');
// require('jquery.waitforimages'); // Commented for DEV-9232
require('./thirdParty/polyfill');
