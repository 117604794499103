'use strict';

module.exports = {
    partsFinder: function () {
        $(document).on('click', '.open-parts-finder a', function () {
            $('.partsFinder').removeClass('d-none');
            $('.open-parts-finder').addClass('d-none').removeClass('d-block');
        });

        $(document).on('click', '.parts-finder-close-button', function () {
            $('.partsFinder').addClass('d-none');
            $('.open-parts-finder').removeClass('d-none').addClass('d-block');
        });

        $(document).on('change', '#diagramProduct', function () {
            $('.unfinished-finder-message').addClass('d-none');
        });
        $(document).on('change', '#filter1', function () {
            $('.unfinished-finder-message').addClass('d-none');
            var filter1 = $('#filter1').val();
            $('#filter2').find('option:not(:first)').remove();
            $('#diagramProduct').find('option:not(:first)').remove();
            if (filter1) {
                $('#filter2').removeAttr('disabled');
                $('.filter-2-wrapper').removeClass('disabled');
                var filterMap = $('.partsFinder').data('map');
                var filter2Obj = filterMap[filter1];
                var filter2DOM = $('#filter2')[0];
                Object.keys(filter2Obj).forEach(function (filter2, idx) {
                    filter2DOM.options[idx + 1] = new Option(filter2, filter2);
                });
            } else {
                $('#filter2').attr('disabled', 'disabled');
                $('.filter-2-wrapper').addClass('disabled');
                $('#diagramProduct').attr('disabled', 'disabled');
            }
        });

        $(document).on('change', '#filter2', function () {
            $('.unfinished-finder-message').addClass('d-none');
            var filter1 = $('#filter1').val();
            var filter2 = $('#filter2').val();
            $('#diagramProduct').find('option:not(:first)').remove();
            if (filter2) {
                $('#diagramProduct').removeAttr('disabled');
                $('.filter-3-wrapper').removeClass('disabled');
                var filterMap = $('.partsFinder').data('map');
                var productObj = filterMap[filter1][filter2];
                var productDom = $('#diagramProduct')[0];
                productObj.forEach(function (product, idx) {
                    productDom.options[idx + 1] = new Option(product.name, product.url);
                });
            } else {
                $('.filter-3-wrapper').addClass('disabled');
                $('#diagramProduct').attr('disabled', 'disabled');
            }
        });

        $(document).on('click', '.js-see-diagram', function () {
            if ($('#diagramProduct').val() != '') {
                window.location = $('#diagramProduct').val();
            } else {
                $('.unfinished-finder-message').removeClass('d-none');
            }
        });

        $(document).on('click', '.js-clear-filter', function () {
            $('.diagramFilters').val('');
            $('#diagramProduct').val('');
            $('.diagramFilters').trigger('change');
        });
    }
}
