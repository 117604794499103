'use strict';

module.exports = function () {
    $('.info-icon:not(.delivery-to-tooltip)').on('mouseenter focusin', function () {
        $(this).find('.tooltip').removeClass('d-none');
    });

    $('.info-icon:not(.delivery-to-tooltip)').on('mouseleave focusout', function () {
        $(this).find('.tooltip').addClass('d-none');
    });
};
