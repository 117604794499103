'use strict';

var productTileCarousel = require('./components/productTileCarousel');
var BrTrkHandler = require('bloomreach/helperPixel');

$(document).ready(function () {
    bloomReachRecomendationsLoad();
    recommendationsWidgetClick();
});

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * Load bloomReach product recomendations
 */
function bloomReachRecomendationsLoad() {
    var $bloomReachRecomendations = $('.bloomreach-recommendations-wrapper');
    var ajaxPromises = [];

    $bloomReachRecomendations.each(function () {
        var $this = $(this);
        var url = $this.data('rel');
        var pid = encodeURI($this.data('pid'));
        var widgetID = encodeURI($this.data('widget-id'));
        var widgetType = encodeURI($this.data('widget-type'));
        var catID = encodeURI($this.data('cat-id'));
        if ((widgetType === 'category' && !catID) ||
            (widgetType === 'item' && !pid)) {
            return;
        }
        if (widgetType === 'global' && resources && resources.bloomreach && resources.bloomreach.categoryID) {
            catID = '';
        }
        var urlParams = {
            url: window.location.href,
            pid: pid,
            widgetID: widgetID,
            widgetType: widgetType,
            catID: catID,
            refURL: window.location.href
        };

        url = appendToUrl(url, urlParams);

        var ajaxPromise = $.ajax({
            url: url,
            type: 'get',
            dataType: 'html'
        }).then(function (data) {
            $this.html(data);

            if (!window.BrTrk) {
                (function () {
                    var br_data = br_data || {};
                    if (window.resources.bloomreach.testData === 'true') {
                        br_data.test_data = window.resources.bloomreach.testData;
                    }
                    var brtrk = document.createElement('script');
                    brtrk.type = 'text/javascript';
                    brtrk.async = true;
                    brtrk.src = '//cdn.brcdn.com/v1/br-trk-' + window.resources.bloomreach.accountID + '.js';
                    var s = document.getElementsByTagName('script')[0];
                    s.parentNode.insertBefore(brtrk, s);
                })();
            }

            if (typeof yotpo !== 'undefined') {
                var api = new Yotpo.API(yotpo);
                api.refreshWidgets();

                setTimeout(function () {
                    $('.recommendations').find('.bottomLine').each(function () {
                        if ($(this).find('.yotpo-icon-star').length == 0) {
                            $(this).parent().find('.zero-stars').removeClass('d-none');
                        } else {
                            $(this).parent().find('.zero-stars').addClass('d-none');
                        }
                    });
                }, 2000);
            }
        });

        ajaxPromises.push(ajaxPromise);
    });

    $.when.apply($, ajaxPromises).always(function () {
        // Call the initBloomreachRecommendations() function after all AJAX promises are resolved
        productTileCarousel.initBloomreachRecommendations();
        // show recommendations after carousel is initialised
        $bloomReachRecomendations.css('height', 'auto');
    });
}

/**
 * Handle recommendations widget click
 */
function recommendationsWidgetClick() {
    $('body').on('click', '.recommendations .bloomreach-carousel a', function (e) {
        if (window.resources.bloomreach.pixel === 'true') {
            var bloomReachResources = window.resources.bloomreach;
            var productElement = $(this).closest('.product');
            var productId = productElement.length && productElement.data('pid') ? productElement.data('pid') : '';
            var widgetID = $('.bloomreach-properties').data('widgetid');
            var responseID = $('.bloomreach-properties').data('rid');
            var typeRecommendation = $('.bloomreach-properties').data('typerecommendation');
            var widget_data = {
                wrid: responseID,
                wid: widgetID,
                wty: typeRecommendation,
                item_id: productId
            };
            if (bloomReachResources.testData === 'true') {
                widget_data.test_data = bloomReachResources.testData;
            }

            BrTrkHandler.brtrkHandle(widget_data, 'Event', 'widget', 'widget-click');
        }
    });
}
