/**
 * @description Loads additional slides
 * @param {jQuery} $carousel - carousel jQuery object
 * @param {Object} slick - slick object
 */
function ajaxSlidesLoading($carousel, slick) {
    if ($carousel.hasClass('js-ajax-loading')) {
        return;
    }

    var lastSlideIndex = slick.$slides.length - 1;
    var $lastSlide = $(slick.$slides[lastSlideIndex]);
    if (!$lastSlide.hasClass('slick-active') || $lastSlide.find('.js-show-more-carousel').length === 0) {
        return;
    }

    var $carouselSpinner = $lastSlide.find('.js-show-more-carousel').spinner();
    var url = $carousel.attr('data-ajax-url');
    var ajaxCount = parseInt($carousel.attr('data-ajax-count')) || 0;
    var ajaxNumber = parseInt($carousel.attr('data-ajax-number')) || 0;

    if (!url || ajaxCount >= ajaxNumber) {
        return;
    }

    var requestData = {
        count: ++ajaxCount
     };
    $carousel.attr('data-ajax-count', ajaxCount);

    $carouselSpinner.start();
    $carousel.addClass('js-ajax-loading');

    $.ajax({
        url: url,
        type: 'POST',
        data: requestData,
        success: function (data) {
            if (!data.action) {
                $carousel.slick('slickAdd', data, lastSlideIndex, true);

                if (ajaxCount >= ajaxNumber) {
                    lastSlideIndex = slick.$slides.length - 1;
                    $carousel.slick('slickRemove', lastSlideIndex);
                }
            }
            $carouselSpinner.stop();
            $carousel.removeClass('js-ajax-loading');
        },
        error: function () {
            $carouselSpinner.stop();
            $carousel.removeClass('js-ajax-loading');
        }
    });
}

/**
 * @description Shadow processing
 * @param {jQuery} $carousel - carousel jQuery object
 * @param {Object} slick - slick object
 * @param {number} currentSlide - current slide
 */
function shadowProcessing($carousel, slick, currentSlide) {
    if(currentSlide === 0) {
        $carousel.removeClass('carousel-shadow-left').removeClass('carousel-shadow-center');
        $carousel.addClass('carousel-shadow-right');
    } else if($(slick.$slides[slick.$slides.length-1]).hasClass('slick-active')){
        $carousel.removeClass('carousel-shadow-right').removeClass('carousel-shadow-center');
        $carousel.addClass('carousel-shadow-left');
    } else {
        $carousel.removeClass('carousel-shadow-left').removeClass('carousel-shadow-right')
        $carousel.addClass('carousel-shadow-center');
    }

}

/**
 * @description Init carousel afterChange event
 * @param {jQuery} $carousel, carousel jQuery object
 */
function afterChangeEvent ($carousel) {
    $carousel.on('afterChange', function(event, slick, currentSlide) {
        var $this = $(this);

        ajaxSlidesLoading($this, slick);

        shadowProcessing($this, slick, currentSlide);
    });
}

module.exports = {
    preInit: function ($carousel) {
        if ($carousel.is('*') && $carousel.hasClass('js-ajax-append-carousel') && parseInt($carousel.attr('data-ajax-number')) > 0) {
            $carousel.append('<div class="js-show-more-carousel"></div>');
        }
    },
    initEvents: function ($carousel) {
        if ($carousel.is('*') && $carousel.hasClass('js-shadow-carousel')) {
            afterChangeEvent($carousel);
        }
    }
}

