'use strict';

var _ = require('lodash');
var base = require('base/components/clientSideValidation');

/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validateForm(event) {
    var valid = true;
    if (this.checkValidity && !this.checkValidity()) {
        // safari
        valid = false;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        $(this).find('input, select').each(function () {
            if (!this.validity.valid) {
                $(this).trigger('invalid', this.validity);
            }
        });
        if ($(this).find('.is-invalid').length && $(this).find('.is-invalid').first().parents('.form-group').length) {
            $(this).find('.is-invalid').first().parents('.form-group').get(0).scrollIntoView();
        }
    }
    return valid;
}

var hasEmptyRequired = function ($requiredInputs) {
    // filter out only the visible fields
    var requiredValues = $requiredInputs.filter('[required]:visible').map(function () {
        return $(this).val();
    });
    return _(requiredValues).includes('');
};

var validateFormBeforeSubmit = function (form) {
    if (form) {
        var valid = true;
        if (form.checkValidity && !form.checkValidity()) {
            // safari
            valid = false;
            $(form)
                .find('input, select')
                .each(function () {
                    if (!this.validity.valid) {
                        $(this).trigger('invalid', this.validity);
                    }
                });
        }
        return valid;
    }
};

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function clearForm(form) {
    $(form).find('.form-control.is-invalid').removeClass('is-invalid');
    $(form).find('.form-group.custom-invalidation').removeClass('custom-invalidation');
}

var dimensionIsValid = function (currentStep) {
    if (currentStep === 'dimensions') {
        var $skirtInput = $('.js-scb-dimension-skirt-input');
        if ($skirtInput) {
            var $skirtInputValue = parseFloat($skirtInput.val());
            if (($skirtInputValue < 1.5 && $skirtInputValue > 0) || $skirtInputValue > 96) {
                return false;
            }
        }
    }
    return true;
};

/* Function for Strap length validation */
var safetyIsValid = function (currentStep) {
    if (currentStep === 'dimensions') {
        var $skirtInput = $('.js-scb-dimension-skirt-input');
        var $safetyInput = $('.js-scb-dimension-safety-strap-input');
        if ($skirtInput && $safetyInput) {
            var $skirtInputValue = parseFloat($skirtInput.val());
            var $safetyInputValue = parseFloat($safetyInput.val());
            if (!($skirtInputValue + 1 <= $safetyInputValue)) {
                return false;
            }
        }
    }
    return true;
};

var exports = {
    init: function (opts) {
        if (!opts || !opts.formSelector || !opts.continueSelector) {
            // no form and/or continue action selectors
            return;
        }

        var $continue = $(opts.continueSelector);
        var $form = opts.multipleForms ? $(opts.formSelector) : $($(opts.formSelector)[0]);
        var $inputs = $form.find(':input.form-control');

        $('body').on('keyup', '.contactFormForm :input.form-control', function () {
            $(this).trigger('change');
        });

        $inputs.off('change.clientSideValidation blur.clientSideValidation');
        $inputs.on('change.clientSideValidation blur.clientSideValidation', function (e) {
            if (
                !hasEmptyRequired($inputs) &&
                $form.find('.form-control.is-invalid').length < 1 &&
                this.validity.valid
            ) {
                $continue.removeAttr('disabled');
            }

            var isPoBox = false;
            if ($(this).hasClass('no-po-box')) {
                var pattern = new RegExp('\\b[p]*(ost)*\\.*\\s*[o|0]*(ffice)*\\.*\\s*b[o|0]x\\b', 'i');
                isPoBox = $(this).val().match(pattern);
            }

            var isCardInvalid = false;
            if ($(this).hasClass('cardNumber')) {
                var cardType = $('#cardType').val();
                if ((cardType === '' || cardType.toLowerCase() === 'unknown') && $(this).val().length >= 17) {
                    isCardInvalid = true;
                }
            }

            if (!this.validity.valid || isPoBox || isCardInvalid) {
                if (!opts.enableContinue) {
                    $continue.attr('disabled', 'disabled');
                }
                $(this).trigger('invalid', this.validity);
            } else {
                $(this).removeClass('is-invalid');
                $(this).parents('.form-group').removeClass('custom-invalidation');
            }

            $("#newPassword, #newPasswordConfirm").trigger('password:verification');

        });

        if (hasEmptyRequired($inputs) || !opts.enableContinue) {
            $continue.attr('disabled', 'disabled');
        } else {
            $continue.removeAttr('disabled');
        }
    },
    dimensionIsValid: dimensionIsValid,
    safetyIsValid: safetyIsValid,
    submit: function () {
        $('form').on('submit', function (e) {
            return validateForm.call(this, e);
        });
    },
    invalid: function () {
        $('body').on('invalid blur', 'form input, form select', function (e) {
            var $this = $(this);
            e.preventDefault();
            this.setCustomValidity('');
            var poBoxError = $(this).data('pobox-error');
            var cardInvalidError = $(this).data('card-invalid');
            var expirationDateInvalid = false;
            if ($(this).hasClass('expirationDate') && this.validity.valid) {
                var today = new Date();
                var startDate = new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0, 0);
                var expDate = $(this).val();
                var separatorIndex = expDate.indexOf('/');
                expDate = expDate.substr(0, separatorIndex) + '/1' + expDate.substr(separatorIndex);
                if (Date.parse(startDate) > Date.parse(expDate)) {
                    expirationDateInvalid = true;
                    this.setCustomValidity($this.data('expired'));
                }
            }

            if (!this.validity.valid || poBoxError || cardInvalidError || expirationDateInvalid) {
                var validationMessage = this.validationMessage;
                $this.addClass('is-invalid');
                if (this.validity.patternMismatch && $this.data('pattern-mismatch')) {
                    validationMessage = $this.data('pattern-mismatch');
                }
                if ((this.validity.rangeOverflow || this.validity.rangeUnderflow) && $this.data('range-error')) {
                    validationMessage = $this.data('range-error');
                }
                if ((this.validity.tooLong || this.validity.tooShort) && $this.data('range-error')) {
                    validationMessage = $this.data('range-error');
                }
                if (this.validity.valueMissing && $this.data('missing-error')) {
                    validationMessage = $this.data('missing-error');
                }
                if (this.validity.valid && poBoxError) {
                    validationMessage = poBoxError;
                }
                if ($(this).hasClass('cardNumber') && cardInvalidError) {
                    validationMessage = cardInvalidError;
                }
                if ($(this).parents('.form-group').siblings('.invalid-feedback').length > 0) {
                    $(this)
                        .parents('.form-group')
                        .siblings('.invalid-feedback')
                        .text(validationMessage)
                        .addClass('alert alert-danger');
                    $(this).parents('.form-group').addClass('custom-invalidation');
                } else if ($(this).parents('.form-group').find('.invalid-feedback').length > 0) {
                    $(this).parents('.form-group').find('.invalid-feedback').text(validationMessage);
                    $(this).parents('.form-group').addClass('custom-invalidation');
                }
                $.spinner().stop();
            }
        });
    },
    buttonClick: function () {
        $('form button[type="submit"], form input[type="submit"]').on('click', function () {
            $(this).parents('form').attr('novalidate', 'novalidate');
        });
    },
    checkAndClearInvalidFields: function () {
        $('.is-invalid').each(function (i, element) {
            if ($(element)[0].checkValidity()) {
                $(element).removeClass('is-invalid');
                $(element).parents('.form-group').removeClass('custom-invalidation');
            }
        });
    },
    helperFunctions: {
        validateFormBeforeSubmit: validateFormBeforeSubmit
    },
    functions: {
        validateForm: function (form, event) {
            validateForm.call($(form), event || null);
        },
        clearForm: clearForm
    },

    /**
     * Clears the validation errors of an invalidated form element after a change occurs
     */
    clearErrorsOnChange: function () {
        $('body').on('change', ':input, .form-control, .custom-select', function () {
            if (!$(this).parents('.customValidation')) {
                return;
            }

            if ($(this).hasClass('is-invalid')) {
                var formGroup = $(this).closest('.form-group');
                $(this).removeClass('is-invalid');
                $(this).parents('.form-group').removeClass('custom-invalidation');
                formGroup.find('.input-group').removeClass('is-invalid');
                formGroup.find('.invalid-feedback').text('');
            }
        });
        $('body').on('focus', '.payment-form-container input[name$="expirationDate"]', function (e) {
            if (!$(this).parents('.customValidation')) {
                return;
            }

            if ($(this).hasClass('is-invalid')) {
                var formGroup = $(this).closest('.form-group');
                $(this).removeClass('is-invalid');
                $(this).parents('.form-group').removeClass('custom-invalidation');
                formGroup.find('.input-group').removeClass('is-invalid');
                formGroup.find('.invalid-feedback').text('');
            }
        });
    },

    checkZipCodeServiceAvailable: function () {
        function showErrorMessage($this, errorMessage) {
            var $thisFormGroup = $this.parents('.form-group');
            var $thisInvalidFeedback = $thisFormGroup.siblings('.invalid-feedback');
            if ($thisInvalidFeedback.length > 0) {
                $thisInvalidFeedback.text(errorMessage);
                $thisFormGroup.addClass('custom-invalidation');
            } else {
                $thisFormGroup.find('.invalid-feedback').text(errorMessage);
            }
        }

        $('body').on('blur', 'input[name=zipCode]', function () {
            if (this.validity.valid) {
                var $this = $(this);
                var $form = $this.closest('form.js-custom-form');
                var validationUrl = $form.attr('data-field-validation-url') || '';

                if (validationUrl) {
                    $.ajax({
                        url: validationUrl,
                        type: 'GET',
                        dataType: 'json',
                        data: {
                            type: 'zipcode',
                            value: $this.val() || ''
                        },
                        success: function (data) {
                            if (data && !data.valid) {
                                $this.addClass('is-invalid');
                                showErrorMessage($this, data.errorMessage);
                            }
                        },
                        error: function (err) {
                            // nothing
                        }
                    });
                }
            }
        });
    }
};

var clientSideValidation = $.extend(true, {}, base, exports);

module.exports = clientSideValidation;
