'use strict';

var base = require('base/checkout/billing');

var addressHelpers = require('base/checkout/address');
var cleave = require('../components/cleave');
var clientSideValidation = require('../components/clientSideValidation');

/**
 * updates the billing address selector within billing forms
 * @param {Object} order - the order model
 * @param {Object} customer - the customer model
 */
function updateBillingAddressSelector(order, customer) {
    var shippings = order.shipping;

    var form = $('form[name$=billing]')[0];
    var $billingAddressSelector = $('.addressSelector', form);
    var hasSelectedAddress = false;

    if ($billingAddressSelector && $billingAddressSelector.length === 1) {
        $billingAddressSelector.empty();
        // Add New Address option
        $billingAddressSelector.append(addressHelpers.methods.optionValueForAddress(
            null,
            false,
            order,
            { type: 'billing' }));

        // Separator -
        $billingAddressSelector.append(addressHelpers.methods.optionValueForAddress(
            order.resources.shippingAddresses, false, order, {
            // className: 'multi-shipping',
            type: 'billing'
        }
        ));

        shippings.forEach(function (aShipping) {
            if (!aShipping.fromStoreId) {
                var isSelected = order.billing.matchingAddressId === aShipping.UUID;
                hasSelectedAddress = hasSelectedAddress || isSelected;
                // Shipping Address option
                $billingAddressSelector.append(
                    addressHelpers.methods.optionValueForAddress(aShipping, isSelected, order,
                        {
                            // className: 'multi-shipping',
                            type: 'billing'
                        }
                    )
                );
            }
        });

        if (customer.addresses && customer.addresses.length > 0) {
            $billingAddressSelector.append(addressHelpers.methods.optionValueForAddress(
                order.resources.accountAddresses, false, order));
            customer.addresses.forEach(function (address) {
                var isSelected = order.billing.matchingAddressId === address.ID;
                hasSelectedAddress = hasSelectedAddress || isSelected;
                // Customer Address option
                $billingAddressSelector.append(
                    addressHelpers.methods.optionValueForAddress({
                        UUID: 'ab_' + address.ID,
                        shippingAddress: address
                    }, isSelected, order, { type: 'billing' })
                );
            });
        }
    }

    if (hasSelectedAddress
        || (!order.billing.matchingAddressId && order.billing.billingAddress.address)) {
        // show
        $(form).attr('data-address-mode', 'edit');
    } else {
        $(form).attr('data-address-mode', 'new');
    }

    $billingAddressSelector.show();
}


/**
 * updates the billing address form values within payment forms
 * @param {Object} order - the order model
 */
function updateBillingAddressFormValues(order) {
    var billing = order.billing;
    if (!billing.billingAddress || !billing.billingAddress.address) return;
    var form = $('form[name=dwfrm_billing]');
    if (!form) return;
    $('input[name$=_firstName]', form).val(billing.billingAddress.address.firstName);
    $('input[name$=_lastName]', form).val(billing.billingAddress.address.lastName);
    $('input[name$=_address1]', form).val(billing.billingAddress.address.address1);
    $('input[name$=_address2]', form).val(billing.billingAddress.address.address2);
    $('input[name$=_city]', form).val(billing.billingAddress.address.city);
    $('input[name$=_postalCode]', form).val(billing.billingAddress.address.postalCode);
    $('select[name$=_stateCode],input[name$=_stateCode]', form)
        .val(billing.billingAddress.address.stateCode);
    $('select[name$=_country]', form).val(billing.billingAddress.address.countryCode.value);
    $('input[name$=_phone]', form).val(billing.billingAddress.address.phone);
    $('input[name$=_email]', form).val(order.orderEmail);
    if (billing.payment && billing.payment.selectedPaymentInstruments
        && billing.payment.selectedPaymentInstruments.length > 0) {
        var instrument = billing.payment.selectedPaymentInstruments[0];
        $('select[name$=expirationMonth]', form).val(instrument.expirationMonth);
        $('select[name$=expirationYear]', form).val(instrument.expirationYear);
        if (instrument.expirationMonth && instrument.expirationYear) {
            $('input[name$="_expirationDate"]').val(String(instrument.expirationMonth).padStart(2, '0') + '/' + instrument.expirationYear);
        }
        // Force security code and card number clear
        $('input[name$=securityCode]', form).val('');
        $('input[name$=cardNumber]').data('cleave').setRawValue('');
    }
}


/**
 * Updates the billing information in checkout, based on the supplied order model
 * @param {Object} order - checkout model to use as basis of new truth
 * @param {Object} customer - customer model to use as basis of new truth
 * @param {Object} [options] - options
 */
function updateBillingInformation(order, customer) {
    updateBillingAddressSelector(order, customer);

    // update billing address form
    updateBillingAddressFormValues(order);

    // update billing address summary
    addressHelpers.methods.populateAddressSummary('.billing .address-summary',
        order.billing.billingAddress.address);

    // update billing parts of order summary
    $('.order-summary-email').text(order.orderEmail);

    if (order.billing.billingAddress.address) {
        $('.order-summary-phone').text(order.billing.billingAddress.address.phone);
    }
}

/**
 * clears the credit card form
 */
function clearCreditCardForm() {
    $('input[name$="_creditCardFields_cardNumber"]').data('cleave').setRawValue('');
    $('select[name$="_creditCardFields_expirationMonth"]').val('');
    $('select[name$="_creditCardFields_expirationYear"]').val('');
    $('input[name$="_creditCardFields_securityCode"]').val('');
    $('input[name$="_creditCardFields_expirationDate"]').val('');
    $('input[name$="_creditCardFields_email"]').val('');
    $('input[name$="_creditCardFields_phone"]').val('');

}

function updateChargeAmount(priceTotal) {
    if (priceTotal) {
        var newAmount = parseFloat(Number(priceTotal.replace('$', '').replace(',', ''))).toFixed(2);
    }
    return;
}

/**
 * Updates the payment information in checkout, based on the supplied order model
 * @param {Object} order - checkout model to use as basis of new truth
 */
function updatePaymentInformation(order) {
    // update payment details
    var $paymentSummary = $('.payment-details');
    var htmlToAppend = '';
    var paymentInstrumentsFound = order.billing.payment && order.billing.payment.selectedPaymentInstruments && order.billing.payment.selectedPaymentInstruments.length > 0;
    var paymentMethodId = $('.payment-information').data('payment-method-id') || null;
    var firstPaymentIndexMethodId = (paymentInstrumentsFound) ? order.billing.payment.selectedPaymentInstruments[0].paymentMethod : null;
    if (paymentInstrumentsFound && ((paymentMethodId && paymentMethodId !== 'Affirm') && (firstPaymentIndexMethodId && firstPaymentIndexMethodId !== 'PayPal'))) {
        htmlToAppend += '<span>' + order.resources.cardType + ' '
            + order.billing.payment.selectedPaymentInstruments[0].type
            + '</span><div>'
            + order.billing.payment.selectedPaymentInstruments[0].maskedCreditCardNumber
            + '</div><div><span>'
            + order.resources.cardEnding + ' '
            + order.billing.payment.selectedPaymentInstruments[0].expirationMonth
            + '/' + order.billing.payment.selectedPaymentInstruments[0].expirationYear
            + '</span></div>';
    } else if (paymentInstrumentsFound && firstPaymentIndexMethodId && firstPaymentIndexMethodId === 'PayPal') {
        htmlToAppend += '<span><div class="paypal-method-name">PayPal</div><div class="paypal-amount">'
            + (order.billing.payment.selectedPaymentInstruments[0].formattedAmount || '');
        + '</div></span>'
    } else {
        htmlToAppend += '<span><div class="js-affirm-payment-description">Affirm</div></span>';
    }

    $paymentSummary.empty().append(htmlToAppend);
}

var exports = {
    methods: {
        clearCreditCardForm: clearCreditCardForm,
        updateChargeAmount: updateChargeAmount,
        updateBillingInformation: updateBillingInformation,
        updateBillingAddressSelector: updateBillingAddressSelector,
        updatePaymentInformation: updatePaymentInformation
    },
    handleCreditCardNumber: function () {
        if ($('#credit-card-form-2').length > 0) {
            cleave.handleCreditCardNumber('.cardNumber-2', '#cardType-2', '#cardOptions-2', '#securityCode-2');
        } else {
            cleave.handleCreditCardNumber('.cardNumber', '#cardType', '#cardOptions', '#securityCode');
        }
    },
    santitizeForm: function () {
        $('body').on('checkout:serializeBilling', function (e, data) {
            var serializedForm = cleave.serializeData(data.form);

            data.callback(serializedForm);
        });
    },
    validateSavedCards: function () {
        clientSideValidation.init({
            continueSelector: 'button.submit-payment',
            formSelector: '.user-payment-instruments',
            enableContinue: true
        });
    },
    validateNewCard: function () {
        clientSideValidation.init({
            continueSelector: 'button.submit-payment',
            formSelector: '.credit-card-form',
            enableContinue: true
        });
    },
    validateBillingAddress: function () {
        clientSideValidation.init({
            continueSelector: 'button.submit-payment',
            formSelector: '.billing-address-block',
            enableContinue: true
        });
    },
    validateBillingContactInfo: function () {
        clientSideValidation.init({
            continueSelector: 'button.submit-payment',
            formSelector: '.contact-info-block',
            enableContinue: true
        });
    },
    addNewPaymentInstrument: function () {
        $('.btn.add-payment').on('click', function (e) {
            e.preventDefault();
            $('.payment-information').data('is-new-payment', true);
            clearCreditCardForm();
            $('.credit-card-form').addClass('selected-payment');
            $('.add-payment').addClass('d-none');
            $('.credit-card-form').removeClass('checkout-hidden');
            $('.credit-card-form').find('input:not(#saveCreditCard,.primary-charge-amount-input,#is-split-payments), select').attr('required', true);
            //$('.user-payment-instruments').addClass('checkout-hidden');
            $('.saved-payment-security-code').val('');
            $('.saved-payment-instrument').removeClass('selected-payment');
            $('.saved-payment-instrument .security-code-input').addClass('checkout-hidden');
        });
    },
    cancelNewPayment: function () {
        $('.cancel-new-payment').on('click', function (e) {
            e.preventDefault();
            $('.payment-information').data('is-new-payment', false);
            clearCreditCardForm();
            $('.credit-card-form').removeClass('selected-payment');
            $('.credit-card-form').addClass('checkout-hidden');
            $('.add-payment').removeClass('d-none');
            // $('.user-payment-instruments').removeClass('checkout-hidden');
            // $('body').trigger('splitPayments:updateChargeAmounts');
            // $('body').trigger('splitPayments:closeCreditCardForm');
            // $('body').trigger('splitpayments:selectSaved', { target: $('.default-payment') });
            $('.credit-card-form').find('input, select').removeAttr('required');
        });
    },
    selectSavedPaymentInstrument: function () {
        $(document).on('click', '.saved-payment-instrument:not(.selected-payment):not(.c-expired-card)', function (e) {
            e.preventDefault();
            if ($('.saved-payment-instrument.selected-payment').length !== 2) {
                $('.saved-payment-security-code').val('');
                $('.saved-payment-instrument').removeClass('selected-payment');
                $('.saved-payment-instrument').find('.amount-to-be-charged').addClass('checkout-hidden');
                $(this).addClass('selected-payment');
                $(this).find('.amount-to-be-charged').removeClass('checkout-hidden');
                $('.saved-payment-instrument .card-image').removeClass('checkout-hidden');
                $('.saved-payment-instrument .security-code-input').addClass('checkout-hidden');
                //$('.saved-security-code.checkout-hidden').removeClass('checkout-hidden');
                $('.saved-payment-instrument.selected-payment ' + '.security-code-input').removeClass(
                    'checkout-hidden'
                );
                $('.cancel-new-payment').trigger('click');
            }
        });
    },
    selectBillingAddress: function () {
        $('.payment-form .addressSelector').on('change', function () {
            var form = $(this).parents('form')[0];
            var selectedOption = $('option:selected', this);
            var optionID = selectedOption[0].value;

            if (optionID === 'new' || optionID === 'manual-entry') {
                // Show Address
                $(form).attr('data-address-mode', 'new');
            } else {
                // Hide Address
                $(form).attr('data-address-mode', 'shipment');
            }

            // Copy fields
            var attrs = selectedOption.data();
            var element;

            Object.keys(attrs).forEach(function (attr) {
                element = attr === 'countryCode' ? 'country' : attr;
                if (element === 'cardNumber') {
                    $('.cardNumber').data('cleave').setRawValue(attrs[attr]);
                } else {
                    $('[name$=' + element + ']', form).val(attrs[attr]);
                }
            });
        });
    },
    paymentTabs: function () {
        $('.payment-options .nav-item').on('click', function (e) {
            e.preventDefault();
            var methodID = $(this).data('method-id');
            $('.payment-information').attr('data-payment-method-id', methodID);
            $('.payment-information').data('payment-method-id', methodID);

            $('.submit-payment').addClass('d-none');
            $('.js_paypal_button_on_billing_form').addClass('d-none');
            $('.confirm-place-order').removeClass('d-none');
            $('.confirm-place-order.applepay-hint').addClass('d-none');

            if (methodID === 'DW_APPLE_PAY') {
                $('.submit-payment').addClass('d-none');
                $('.confirm-place-order').addClass('d-none');
                $('.confirm-place-order.applepay-hint').removeClass('d-none');
                $('.submit-payment[data-buttonfor="placeorderapplepay"]').removeClass("d-none");
            } else if (methodID === 'Affirm') {
                $('.submit-payment[data-buttonfor="placeorderaffirm"]').removeClass("d-none");
            } else if (methodID === 'PayPal') {
                if ($('#dwfrm_billing_paypal_useCustomerBillingAgreement').val() === 'true') {
                    $('.paypal-payment-instructions').hide();
                } else {
                    $('.paypal-payment-instructions').show();
                }
                $('.js_paypal_button_on_billing_form').removeClass('d-none');
                $('.submit-payment[data-buttonfor="placeorderpaypal"]').removeClass("d-none");
            } else {
                $('.submit-payment[data-buttonfor="placeorder"]').removeClass("d-none");
            }
        });
        //in case no paypal option
        if (!window.paypal) {
            $('.payment-options[role=tablist] a[data-toggle="tab"]').on('click', function (e) {
                var target = $(this).get(0);
                $('.payment-options[role=tablist] a[data-toggle="tab"]').each(function () {
                    var $tabContent = $($(this).attr('href'));
                    if (this === target) {
                        $tabContent.find('input, textarea, select').removeAttr('disabled', 'disabled');
                    } else {
                        $tabContent.find('input, textarea, select').attr('disabled', 'disabled');
                    }
                });
            });
        }
    },
    checkAndClearValidity: function () {
        $('.btn-show-details, .btn-add-new').on('click', function () {
            clientSideValidation.checkAndClearInvalidFields();
            setTimeout(function () {
                var form = $('form[name=dwfrm_billing]');
                if ($('input[name$=_email]', form).length > 0) {
                    let billingEmail = $('input[name$=_email]', form).data('defaultValue');
                    $('input[name$=_email]', form).val(billingEmail);
                }
            }, 0);
        });
    },
    edqAddressValidationBilling: function () {
        $('#dwfrm_billing').on('keyup', 'input[name$=_address1], input[name$=_address2],input[name$=_city],input[name$=_postalCode],select[name$=_stateCode]', function () {
            $('.billing-address-block').attr('data-addressvalidedq', 'false');
        });
    },
};

var billing = $.extend(true, {}, base, exports);

module.exports = billing;
