'use strict';

/**
 *
 * @param {string} zipCode for look up in google maps API
 * @param {string} apiKey google maps api keyMap
 * @param {function} success success callback function
 * @param {function} error error call back
 */
function getZipLocation(zipCode, apiKey, success, error) {
    $.ajax({
        url: 'https://maps.googleapis.com/maps/api/geocode/json?key=' + apiKey + '&address=' + zipCode,
        type: 'GET',
        dataType: 'json',
        success: function (data) {
            if (data.results.length > 0) {
                success({
                    city: data.results[0].address_components[1].long_name,
                    postalCode: data.results[0].address_components[0].long_name
                });
            } else {
                error(data);
            }
        },
        error: function (e) {
            error(e);
        }
    });
}



/**
 *
 * @param {string} apiKey google maps api keyMap
 * @param {function} success success callback function
 * @param {function} error error call back
 */
function getGeolocation(apiKey, success, error) {
    navigator.geolocation.getCurrentPosition(
        function (position) {
            var url =
                'https://maps.googleapis.com/maps/api/geocode/json?key=' +
                apiKey +
                '&latlng=' +
                position.coords.latitude +
                ',' +
                position.coords.longitude;
            $.ajax({
                url: url,
                type: 'GET',
                dataType: 'json',
                success: function (data) {
                    // Pull correct data from the first address in response
                    var postalCode, city;
                    data.results[0].address_components.forEach(function (component) {
                        if (component.types.indexOf("postal_code") > -1) {
                            postalCode = component.long_name;
                        } else if (component.types.indexOf("locality") > -1) {
                            city = component.long_name;
                        }
                    });
                    success({
                        city: city,
                        postalCode: postalCode
                    });
                },
                error: function (e) {
                    error(e);
                }
            });
        },
        function (err) {
            //error
        },
        {
            timeout: 1000
        }
    );
}

function getGeolocationWithAddress(apiKey, address, success, error) {
    var url =
        'https://maps.googleapis.com/maps/api/geocode/json?key=' +
        apiKey +
        '&address=' +
        address.replace(' ', '+');
    $.ajax({
        url: url,
        type: 'GET',
        dataType: 'json',
        success: function (data) {
            if (data.results.length > 0) {
                success({
                    lat: data.results[0].geometry.location.lat,
                    long: data.results[0].geometry.location.lng
                });
            } else {
                error(data);
            }
        },
        error: function (e) {
            error(e)
        }
    })
}

function getZipCode(lat, long, apiKey, success, error) {
    $.ajax({
        url: 'https://maps.googleapis.com/maps/api/geocode/json?key=' + apiKey + '&latlng=' + lat + ',' + long,
        type: 'GET',
        dataType: 'json',
        success: function (data) {
            var zipcode;
            if (data.status == 'OK') {
                var result = data.results[0];
                for (var i = 0; i < result.address_components.length; i++) {
                    if (result.address_components[i].types.indexOf('postal_code') > -1) {
                        zipcode = result.address_components[i].long_name;
                    }
                }
            }
            success({
                zipcode: zipcode
            })
        },
        error: function (data) {
            error({
                error: true
            })
        }
    })
}

// calculates the distance b/w user input and all store locations
// TODO: get new google maps API key for distancematrix
function getDistance(apiKey, origin, destinations, success, error) {
    var parsedDestinations = JSON.parse(destinations);
    var destinationsStr = '';

    for (var index = 0; index < parsedDestinations.length; index++) {
        destinationsStr += parsedDestinations[index].latitude + ',' + parsedDestinations[index].longitude;
        if (index !== parsedDestinations.length - 1) {
            destinationsStr += '|'
        }
    }

    var url = 'https://maps.googleapis.com/maps/api/distancematrix/json?key=' +
        apiKey +
        '&origins=' + origin.replace(' ', '+') + '&destinations=' + destinationsStr;

    return url;

    // $.ajax({
    //     url: url,
    //     type: 'GET',
    //     dataType: 'json',
    //     success: function (data) {
    //         destinations.each(function(index){
    //             destinations[index].distance = data.rows[0].elements[index]
    //         });
    //         success({
    //             destinations: destinations
    //         });
    //     },
    //     error: function (e) {
    //         error(e)
    //     }
    // })
}

/**
 *
 * @param {string} zipCode for look up in google maps API
 * @param {function} success success callback function
 * @param {function} error error call back
 */
 function getStateByZipCode(zipCode, success, error) {

    $.ajax({
        url: 'https://maps.googleapis.com/maps/api/geocode/json?key=' + window.mapsApiKey + '&address=' + zipCode,
        type: 'GET',
        dataType: 'json',
        success: function (data) {
            if (data.results.length > 0 && data.results[0].address_components.length > 0) {
                var state = '';
                for (var i = 0; i < data.results[0].address_components.length; i++) {
                    if (data.results[0].address_components[i].types[0] === "administrative_area_level_1") {
                        state = data.results[0].address_components[i].short_name;

                    }
                }
                if (state != '') {
                    success({
                        state: state,
                        deliveryToZip: zipCode
                    });
                } else {
                    error(null);
                }
               
            } else {
                error(null);
            }
        },
        error: function (e) {
            console.log(e);
            error(null);
        }
    });
}

module.exports = {
    getZipLocation: getZipLocation,
    getGeolocation: getGeolocation,
    getGeolocationWithAddress: getGeolocationWithAddress,
    getDistance: getDistance,
    getZipCode: getZipCode,
    getStateByZipCode: getStateByZipCode
};
