'use strict';

function initCarousel($carousel) {
    $carousel.slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: length && length < 4 ? length : 4,
        slidesToScroll: length && length < 4 ? length : 4,
        autoplay: false,
        autoplaySpeed: 4000,
        prevArrow:
            '<div class="text-light bg-primary my-auto rounded-circle flex-grow-0 flex-shrink-0 d-flex justify-content-center align-items-center slick-arrow-prev" style="height: 40px; width: 40px;"><i class="fa fa-arrow-left"></i></div>',
        nextArrow:
            '<div class="text-light bg-primary my-auto rounded-circle flex-grow-0 flex-shrink-0 d-flex justify-content-center align-items-center slick-arrow-next" style="height: 40px; width: 40px;"><i class="fa fa-arrow-right"></i></div>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                    autoplay: false,
                    autoplaySpeed: 4000,
                    arrows: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: true,
                    autoplay: false,
                    autoplaySpeed: 4000
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                    autoplay: false,
                    autoplaySpeed: 4000
                }
            }
        ]
    });
}

function initModalCarousel($carousel) {
    $carousel.slick({
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: false,
        prevArrow:
            '<div class="text-light bg-primary my-auto rounded-circle flex-grow-0 flex-shrink-0 d-flex justify-content-center align-items-center" style="height: 40px; width: 40px;"><i class="fa fa-arrow-left"></i></div>',
        nextArrow:
            '<div class="text-light bg-primary my-auto rounded-circle flex-grow-0 flex-shrink-0 d-flex justify-content-center align-items-center" style="height: 40px; width: 40px;"><i class="fa fa-arrow-right"></i></div>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
}

function initBRWidgetCarousel($carousel, length) {
    var responsiveObj = {
        fullWidth: length && length < 4 ? length : 4,
        '1024': length && length < 3 ? length : 3,
        '991': length && length < 3 ? length : 3,
        '800': 2,
        '600': 1
    };
    if ($carousel.closest('.recommendations').hasClass('plp-recommendations')) {
        responsiveObj.fullWidth = 3;
        responsiveObj['991'] = 2;
    }

    $carousel.slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: responsiveObj.fullWidth,
        slidesToScroll: responsiveObj.fullWidth,
        autoplay: false,
        autoplaySpeed: 4000,
        prevArrow:
            '<div class="text-light bg-primary my-auto rounded-circle flex-grow-0 flex-shrink-0 d-flex justify-content-center align-items-center slick-arrow-prev" style="height: 40px; width: 40px;"><i class="fa fa-arrow-left"></i></div>',
        nextArrow:
            '<div class="text-light bg-primary my-auto rounded-circle flex-grow-0 flex-shrink-0 d-flex justify-content-center align-items-center slick-arrow-next" style="height: 40px; width: 40px;"><i class="fa fa-arrow-right"></i></div>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: responsiveObj['1024'],
                    slidesToScroll: responsiveObj['1024'],
                    infinite: true,
                    dots: true,
                    autoplay: false,
                    autoplaySpeed: 4000,
                    arrows: true
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: responsiveObj['991'],
                    slidesToScroll: responsiveObj['991'],
                    infinite: true,
                    dots: true,
                    autoplay: false,
                    autoplaySpeed: 4000,
                    arrows: true
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: responsiveObj['800'],
                    slidesToScroll: responsiveObj['800'],
                    arrows: true,
                    autoplay: false,
                    autoplaySpeed: 4000
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: responsiveObj['600'],
                    slidesToScroll: responsiveObj['600'],
                    arrows: false,
                    autoplay: false,
                    autoplaySpeed: 4000
                }
            }
        ]
    });
}

/**
 * Setup Mutation Observers for Recommendations Slots
 * When recommendations markup is loaded, fire off Mutation Observer callback
 * - Init Product Tile Carousel on Recommendation Product Tiles
 */
function initRecommendations() {
    var observer = new MutationObserver(function (mutations, observer) {
        var $this = $(mutations[0].target);

        // This is the logic to ensure that initProductTileCarousel()
        // is only fired once. You could also use a Singleton pattern
        var $carousel = $this.find('.js-recommendation-carousel');
        if ($carousel.length && !$carousel.hasClass('slick-initialized')) {
            // Remove CQuotient event tags
            // TODO: make this work better
            $carousel.find('script').remove();
            initCarousel($carousel);
        }
    });

    // Create an observer for CQ Recommendations being injected into
    // slot containers with id starting with cq_recomm_slot
    $('[id^=cq_recomm_slot]').each(function (i, slot) {
        observer.observe(slot, {
            subtree: true,
            childList: true
        });
    });
}

/**
 * wrapper function to call initCarousel function for bloomreach pathways
 */
var initBloomreachRecommendations = function () {
    var $carousel = $('.bloomreach-recommendations-wrapper .js-product-tile-carousel');
    $carousel.each(function(){
        var $this = $(this);
        if ($this.length && $this.children().length) {
            initBRWidgetCarousel($this, $this.children().length);
        }
    });
}

module.exports = {
    initCarousel: function () {
        // init on Page load
        if ($('.js-product-tile-carousel').is('*')) {
            initCarousel($('.js-product-tile-carousel'));
        }
    },
    initModalCarousel: function() {
        if ($('body').find('.modal-slot-product .js-product-tile-carousel').is('*')) {
            initModalCarousel($('.modal-slot-product .js-product-tile-carousel'));
        }
    },
    initRecommendations: initRecommendations,
    initBloomreachRecommendations: initBloomreachRecommendations
};
