'use strict';

var keyboardAccessibility = require('base/components/keyboardAccessibility');

var clearSelection = function (element) {
    $(element).closest('.dropdown').children('.dropdown-menu').children('.top-category').detach();
    $(element).closest('.dropdown.show').children('.nav-link').attr('aria-expanded', 'false');
    $(element).closest('.dropdown.show').children('.dropdown-menu').attr('aria-hidden', 'true');
    $(element).closest('.dropdown.show').removeClass('show');
    $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'false');
    $(element).closest('li').detach();
};

module.exports = function () {
    var isDesktop = function (element) {
        return $(element).parents('.menu-toggleable-left').css('position') !== 'fixed';
    };


    function getModalHtmlElement() {
        if ($('#editProductModal').length !== 0) {
            $('#editProductModal').remove();
        }
        var htmlString = '<!-- Modal -->'
            + '<div class="modal fade" id="registrationModal" tabindex="-1" role="dialog">'
            + '<span class="enter-message sr-only" ></span>'
            + '<div class="modal-dialog">'
            + '<!-- Modal content-->'
            + '<div class="modal-content unselected">'
            + '<div class="modal-body register-popup-modal"></div>'
            + '</div>'
            + '</div>'
            + '</div>';
        $('body').append(htmlString);
    }

    /**
    * replaces the content in the modal window for product variation to be edited.
    * @param {string} registrationURL - url to be used to retrieve the form
    */
    function fillModalElement(registrationURL, callback) {
        $.ajax({
            url: registrationURL,
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                var parsedHtml = parseHtml(data.renderedTemplate);

                $('#registrationModal .modal-body').empty();
                $('#registrationModal .modal-body').html(parsedHtml.body);
                $('#registrationModal').modal('show');

                if (typeof callback === "function") callback();
            },
            error: function (error) {
                console.log(error);
            }
        });
    }

    /**
 * Parse HTML code in Ajax response
 *
 * @param {string} html - Rendered HTML from quickview template
 * @return {QuickViewHtml} - QuickView content components
 */
    function parseHtml(html) {
        var $html = $('<div>').append($.parseHTML(html));

        var body = $html.find('.registrationPopupModal');

        return { body: body };
    }

    $('#pool-perks-registration').on('click', function () {
        var url = window.registrationURL;
        getModalHtmlElement();
        fillModalElement(url, function () {
            $("#poolPerksPopupModal").attr("novalidate", "");
            $("#poolPerksPopupModal").on("submit", function (e) {
                var endpoint = e.target.getAttribute("action");
                var formData = {
                    firstName: e.target["registration-form-fname"].value,
                    lastName: e.target["registration-form-lname"].value,
                    phone: e.target["registration-form-phone"].value,
                    email: e.target["registration-form-email"].value,
                    sms: (e.target["sms"].checked) ? true : false
                };
                /* var serializedForm = $("#poolPerksPopupModal").serialize(); */

                e.preventDefault();

                $.ajax({
                    url: endpoint,
                    type: 'post',
                    dataType: 'json',
                    data: formData,
                    beforeSend: function () {
                        $("#overallErrorMessageContainer").addClass("d-none");
                        $(".is-invalid").removeClass("is-invalid");
                        $("#registrationModal .modal-content").spinner().start();
                    },
                    success: function (data, textStatus, jqXHR) {
                        var isAgent = (data.redirectUrl && typeof data.redirectUrl === "string") ? true : false;

                        if (!isAgent) {
                            $("#registrationModal .modal-content").spinner().stop();
                            $("#registrationModal").modal("hide");
                            return;
                        }

                        window.location.href = data.redirectUrl;
                    },
                    error: function (res) {
                        var overallErrorMessage = "";
                        var errorFields = [];

                        try {
                            overallErrorMessage = res.responseJSON.errorMessage;
                            errorFields = res.responseJSON.fields;
                        } catch (e) {
                            console.error(e);
                        }

                        $("#registrationModal .modal-content").spinner().stop();

                        if (errorFields.length) {
                            $.each(errorFields, function (i) {
                                var item = errorFields[i];
                                $("#" + item.id + " + .invalid-feedback").text(item.errorMessage);
                                $("#" + item.id).addClass("is-invalid");
                            });
                        }

                        if (overallErrorMessage.length) {
                            $("#overallErrorMessage").text(overallErrorMessage);
                            $("#overallErrorMessageContainer").removeClass("d-none");
                            document.getElementById("overallErrorMessageContainer").scrollIntoView();
                        }
                    }
                });
            });
            window.sessionStorage.setItem('registrationModal', '1');
            $('#registrationModal').modal('show');
        });
    });

    var headerBannerStatus;
    try {
        headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');
        $('.header-banner .close').on('click', function () {
            $('.header-banner').addClass('d-none');
            window.sessionStorage.setItem('hide_header_banner', '1');
        });
    } catch (e) {
        headerBannerStatus = -1;
    }

    var isCheckoutPage = (window.location.href).indexOf('checkout') > 0;
    var isCartPage = (window.location.href).indexOf('cart') > 0;
    var isCreateAccountPage = (window.location.href).indexOf('login?action=register&login=true') > 0

    var isNoShowHeaderPage = isCartPage || isCheckoutPage || isCreateAccountPage;
    //don't allow banner on checkout DEV-3516

    if ((!headerBannerStatus || headerBannerStatus < 0) && !isNoShowHeaderPage) {
        $('.header-banner').removeClass('d-none');
    }

    keyboardAccessibility(
        '.main-menu .nav-link, .main-menu .dropdown-link',
        {
            40: function (menuItem) {
                // down
                if (menuItem.hasClass('nav-item')) {
                    // top level
                    $('.navbar-nav .show').removeClass('show').children('.dropdown-menu').removeClass('show');
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.find('ul > li > a').first().focus();
                } else {
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    if (!(menuItem.next().length > 0)) {
                        // if this is the last menuItem
                        menuItem
                            .parent()
                            .parent()
                            .find('li > a') // set focus to the first menuitem
                            .first()
                            .focus();
                    } else {
                        menuItem.next().children().first().focus();
                    }
                }
            },
            39: function (menuItem) {
                // right
                if (menuItem.hasClass('nav-item')) {
                    // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                } else if (menuItem.hasClass('dropdown')) {
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    $(this).attr('aria-expanded', 'true');
                    menuItem.find('ul > li > a').first().focus();
                }
            },
            38: function (menuItem) {
                // up
                if (menuItem.hasClass('nav-item')) {
                    // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                } else if (menuItem.prev().length === 0) {
                    // first menuItem
                    menuItem.parent().parent().removeClass('show').children('.nav-link').attr('aria-expanded', 'false');
                    menuItem
                        .parent()
                        .children()
                        .last()
                        .children() // set the focus to the last menuItem
                        .first()
                        .focus();
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: function (menuItem) {
                // left
                if (menuItem.hasClass('nav-item')) {
                    // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.prev().children().first().focus();
                } else {
                    menuItem
                        .closest('.show')
                        .removeClass('show')
                        .closest('li.show')
                        .removeClass('show')
                        .children()
                        .first()
                        .focus()
                        .attr('aria-expanded', 'false');
                }
            },
            27: function (menuItem) {
                // escape
                var parentMenu = menuItem.hasClass('show') ? menuItem : menuItem.closest('li.show');
                parentMenu.children('.show').removeClass('show');
                parentMenu.removeClass('show').children('.nav-link').attr('aria-expanded', 'false');
                parentMenu.children().first().focus();
            }
        },
        function () {
            return $(this).parent();
        }
    );

    $('.main-menu .dropdown:not(.disabled) .dropdown-toggle')
        .on('click', function (e) {
            if (!isDesktop(this)) {
                $('.modal-background').show();
                // copy parent element into current UL
                var li = $('<li class="dropdown-item top-category bg-light pl-0" role="button"></li>');
                var link = $(this)
                    .clone()
                    .removeClass('dropdown-toggle')
                    .addClass('pl-2')
                    .removeAttr('data-toggle')
                    .removeAttr('aria-expanded')
                    .attr('aria-haspopup', 'false');
                li.append(link);
                var closeMenu = $('<li class="nav-menu"></li>');
                closeMenu.append($('.close-menu').first().clone());
                $(this).parent().children('.dropdown-menu').prepend(li).prepend(closeMenu).attr('aria-hidden', 'false');
                // copy navigation menu into view
                $(this).parent().addClass('show');
                $(this).attr('aria-expanded', 'true');
                $(link).focus();
                $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
                e.preventDefault();
            } else {
                location.href = $(this).attr('href');
            }
        })
        .on('mouseenter', function () {
            if (isDesktop(this)) {
                var eventElement = this;
                $('.navbar-nav > li').each(function () {
                    if (!$.contains(this, eventElement)) {
                        $(this)
                            .find('.show')
                            .each(function () {
                                clearSelection(this);
                            });
                        if ($(this).hasClass('show')) {
                            $(this).removeClass('show');
                            $(this).children('ul.dropdown-menu').removeClass('show');
                            $(this).children('.nav-link').attr('aria-expanded', 'false');
                        }
                    }
                });
                // need to close all the dropdowns that are not direct parent of current dropdown
                $(this).parent().addClass('show');
                $(this).siblings('.dropdown-menu').addClass('show');
                $(this).attr('aria-expanded', 'true');
            }
        })
        .parent()
        .on('mouseleave', function () {
            if (isDesktop(this)) {
                $(this).removeClass('show');
                $(this).children('.dropdown-menu').removeClass('show');
                $(this).children('.nav-link').attr('aria-expanded', 'false');
            }
        });

    $('.navbar>.close-menu>.close-button').on('click', function (e) {
        e.preventDefault();
        $('.menu-toggleable-left').removeClass('in');
        $('.modal-background').hide();

        $('.navbar-toggler').focus();

        $('.main-menu').attr('aria-hidden', 'true');
        $('.main-menu').siblings().attr('aria-hidden', 'false');
        $('header').siblings().attr('aria-hidden', 'false');
        $('html').removeClass('menu-open');
    });

    $('.navbar-nav').on('click', '.back', function (e) {
        e.preventDefault();
        clearSelection(this);
    });

    $('.navbar-nav').on('click', '.close-button', function (e) {
        e.preventDefault();
        $('.navbar-nav').find('.top-category').detach();
        $('.navbar-nav').find('.nav-menu').detach();
        $('.navbar-nav').find('.show').removeClass('show');
        $('.menu-toggleable-left').removeClass('in');

        $('.main-menu').siblings().attr('aria-hidden', 'false');
        $('header').siblings().attr('aria-hidden', 'false');

        $('.modal-background').hide();
        $('.mobile-nav-top .close-button').trigger('click');
        $('html').removeClass('menu-open');
    });

    $('.navbar-toggler').click(function (e) {
        e.preventDefault();
        $('.main-menu').toggleClass('in');
        $('.modal-background').show();

        $('.main-menu').removeClass('d-none');
        $('.main-menu').attr('aria-hidden', 'false');
        $('.main-menu').siblings().attr('aria-hidden', 'true');
        $('header').siblings().attr('aria-hidden', 'true');

        $('.main-menu .nav.navbar-nav .nav-link').first().focus();
        $('html').addClass('menu-open');
    });

    keyboardAccessibility(
        '.navbar-header .user.nav-item',
        {
            40: function ($popover) {
                // down
                if ($popover.children('a').first().is(':focus')) {
                    $popover.next().children().first().focus();
                } else {
                    $popover.children('a').first().focus();
                }
            },
            38: function ($popover) {
                // up
                if ($popover.children('a').first().is(':focus')) {
                    $(this).focus();
                    $popover.removeClass('show');
                } else {
                    $popover.children('a').first().focus();
                }
            },
            27: function () {
                // escape
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            },
            9: function () {
                // tab
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            }
        },
        function () {
            var $popover = $('.user .popover li.nav-item');
            return $popover;
        }
    );

    $('.navbar-header .user.nav-item').on('mouseenter focusin', function () {
        if ($('.navbar-header .user .popover').length > 0) {
            $('.navbar-header .user .popover').addClass('show');
            $('.user').attr('aria-expanded', 'true');
        }
    });

    $('.navbar-header .user.nav-item').on('mouseleave', function () {
        $('.navbar-header .user .popover').removeClass('show');
        $('.user').attr('aria-expanded', 'false');
    });

    $('.navbar-header .user.nav-item').on('touchend', function (e) {
        if (!($(e.target).parents('#header-wrapper').length && ($(e.target).parents('.popover').length) || $(e.target).hasClass('popover'))) {
            e.preventDefault();
            if ($('.navbar-header .user .popover').hasClass('show')) {
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            } else {
                $('.navbar-header .user .popover').addClass('show');
                $('.user').attr('aria-expanded', 'true');
            }
        }
    });


    $('.navbar-header .user-loyalty').on('mouseenter focusin', function () {
        if ($('.navbar-header .user-loyalty .popover').length > 0) {
            $('.navbar-header .user-loyalty .popover').addClass('show');
            $('.user-loyalty').attr('aria-expanded', 'true');
        }
    });

    $('.navbar-header .user-loyalty').on('mouseleave', function () {
        $('.navbar-header .user-loyalty .popover').removeClass('show');
        $('.user-loyalty').attr('aria-expanded', 'false');
    });

    $('body').on('click', '#myaccount', function () {
        event.preventDefault();
    });
    $('.modal-background').on('click', function () {
        $('.main-menu .close-menu .close-button').trigger('click');
    });

    $('.dropdown-toggle .blog-nav-link').on('click', function () {
        window.location.href = $(this).attr('href');
    });

    if (window.KAMPYLE_ONSITE_SDK) {
        loadKampyle();
    } else {
        window.addEventListener('neb_OnsiteLoaded', loadKampyle);
    }
    function loadKampyle() {
        if (window.KAMPYLE_ONSITE_SDK) {
            var formId = $('.page').data('kampyleId');
            var neb_status = window.KAMPYLE_ONSITE_SDK.loadForm(formId);
            if (neb_status === true) {
                $('#mdFormButton').parent().removeClass('d-none');
            }
        }
    }

    $('#mdFormButton').on('click', function () {
        var formId = $('.page').data('kampyleId');
        if (window.KAMPYLE_ONSITE_SDK) {
            window.KAMPYLE_ONSITE_SDK.showForm(formId);
        }
    });

    function verifyAll() {
        return (/^[a-zA-Z]+$/.test($('.shippingPickUpFirstName').val()) &&
            (/^[a-zA-Z]+$/.test($('.shippingPickupLastName').val()) &&
                (/^[^\s@]+@[^\s@]+$/.test($('.pickupEmail').val()) &&
                    (/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test($('.pu-phone').val())))));


    }

    $('.shippingPickUpFirstName, .shippingPickupLastName').focus(function () {
        $(this).removeClass('is-invalid');
        $(this).closest('form').find('.btn-save').prop('disabled', false);
        if ($(this).next().hasClass('invalid-feedback')) {
            $(this).next().remove();
        }
    }).blur(function (e) {
        var nameInput = $(this).val();
        var res = /^[a-zA-Z]+$/.test(nameInput);
        if (!verifyAll()) {
            $(this).closest('form').find('.btn-save').prop('disabled', true);
        }
        if (!res) {
            $(this).addClass('is-invalid');
            $(this).closest('form').find('.btn-save').prop('disabled', true);
            if ($(this).hasClass('shippingPickUpFirstName')) {
                $(this).after('<div class="invalid-feedback" id="phoneInvalidMessage">Please enter a valid First Name.</div>');
            } else {
                $(this).after('<div class="invalid-feedback" id="phoneInvalidMessage">Please enter a valid Last Name.</div>');
            }
        }
    });

    $('.pu-phone').focus(function () {
        $(this).removeClass('is-invalid');
        $(this).closest('form').find('.btn-save').prop('disabled', false);
        if ($(this).next().hasClass('invalid-feedback')) {
            $(this).next().remove();
        }
    }).blur(function () {
        var phoneNum = ($(this).val());
        var res = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(phoneNum);
        if (!verifyAll()) {
            $(this).closest('form').find('.btn-save').prop('disabled', true);
        }
        if (!res) {
            $(this).addClass('is-invalid');
            $(this).after('<div class="invalid-feedback" id="phoneInvalidMessage">Please enter a valid Phone number.</div>');
        }
    });

    $('.delivery-to-form').on('submit', function (e) {
        var googleMapsHelpers = require('googleMaps/googleMaps');
        e.preventDefault();
        $('.row-state-error').addClass('d-none');
        $('.row-no-results-error').addClass('d-none');
        $('.delivery-to-dropdown').removeClass('dropdown-width-warn');
        var data = $(this).serialize();
        var url = $(this).attr('action');
        if ($('.product-detail').length && $('.product-id').length) {
            url += '?pdp=true&productId=' + $('.product-id').html();
        }

        $("#deliveryToZip").removeClass('is-invalid');
        $("#deliverytoError").html('');
        var zipCode = $('#deliveryToZip').val();
        var isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode);
        if (!isValidZip) {
            $("#deliveryToZip").addClass('is-invalid');
            $("#deliverytoError").html('Please add a valid zip code');
            return false;
        }

        googleMapsHelpers.getStateByZipCode(
            $('#deliveryToZip').val(),
            function (data) {
                if (data.state && (data.state == 'AK' || data.state == 'HI' || data.state == 'PR')) {
                    $('.row-state-error').removeClass('d-none');
                    $('.delivery-to-dropdown').addClass('dropdown-width-warn');
                } else {
                    var timezone = new Date().toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'short' }).substring(4);
                    if (timezone && timezone != 'undefined') {
                        data.timezone = timezone;
                    }
                    $.spinner().start();
                    $.ajax({
                        url: url,
                        data: data,
                        method: 'POST',
                        success: function (response) {
                            if (response.success) {
                                $('.delivery-to-code').html($('#deliveryToZip').val());
                                $('#deliveryToZip').val('');
                                if (response.triggerStoreChange && $('.select-store-dropdown').length > 0) {
                                    $('.delivery-to-tooltip .tooltip').removeClass('d-none');
                                    setTimeout(function () {
                                        $('.delivery-to-tooltip .tooltip').addClass('d-none');
                                    }, 5000);
                                    $('.deliveryto-wrapper').removeClass('show');
                                    $('.deliveryto-wrapper').find('.dropdown-menu').removeClass('show');
                                }
                                if (response.promiseDates) {
                                    var htmlPromiseDates = response.promiseDates;
                                    if (!$('.select-store-dropdown').length && $('.page').data('site') !== 'its') {
                                        var text = $(htmlPromiseDates).html();
                                        htmlPromiseDates = '<span class="shipping-item">' + text + '</span>';
                                        if ($('.pdp-shipping-item').length > 0) {
                                            $('.pdp-shipping-item').html(htmlPromiseDates);
                                        }
                                    } else {
                                        $('.promise-dates-target').html(htmlPromiseDates);
                                    }
                                    if ($('.sor-availability').length > 0) {
                                        var rawData = response.rawPromiseDate.replace('Get it by ', '');
                                        $('.sor-availability').find('.pdp-promise-date').html(rawData);
                                    }

                                    $('.estimate-sth-date-wrapper').length && $('.estimate-sth-date-wrapper').addClass('d-none');
                                    $('.promise-dates-target').length && $('.promise-dates-target').removeClass('d-none');
                                } else {
                                    $('.estimate-sth-date-wrapper').length && $('.estimate-sth-date-wrapper').removeClass('d-none');
                                    $('.promise-dates-target').length && $('.promise-dates-target').addClass('d-none');
                                }
                                if ($('.product-detail').length && $('.product-id').length) {
                                    $('.delivery-to-pdp-form').addClass('d-none');
                                    $('.delivery-to-pdp-section').removeClass('d-none');
                                    $('.pdp-change-delivery-to').removeClass('d-none');
                                    $('.pdp-change-delivery-to-hide').addClass('d-none');
                                }

                                $('.delivery-to-dropdown').removeClass('show');
                                if (response.success && $('.cart').length > 0) {
                                    window.location.reload();
                                }
                            }
                        },
                        complete: function () {
                            $.spinner().stop();
                        }
                    });
                }
            },
            function (error) {
                console.log('no results');
                $('.row-no-results-error').removeClass('d-none');
            }
        );


    });


    $("#btn-deliveryto-mobile").click(function () {
        $("#delivery-to-dropdown-mobile").removeClass("d-none");
    });

    $("#deliveryto-modal-mobile-close").click(function () {
        $('#delivery-to-dropdown-mobile').addClass('d-none');
    });

    $('.delivery-to-form-mobile').on('submit', function (e) {
        var googleMapsHelpers = require('googleMaps/googleMaps');
        e.preventDefault();
        $('.row-state-error').addClass('d-none');
        $('.delivery-to-dropdown').removeClass('dropdown-width-warn');

        var data = $(this).serialize();

        var url = $(this).attr('action');

        if ($('.product-detail').length && $('.product-id').length) {
            url += '?pdp=true&productId=' + $('.product-id').html();
        }

        var timezone = new Date().toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'short' }).substring(4);
        if (timezone && timezone != 'undefined') {
            data = data + "&timezone=" + timezone;
        }

        $("#deliveryToZipMobile").removeClass('is-invalid');

        $("#deliveryToZipMobile").html('');

        var zipCode = $('#deliveryToZipMobile').val();

        var isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode);

        if (!isValidZip) {
            $("#deliveryToZipMobile").addClass('is-invalid');
            $("#deliveryToZipMobile").html('Please add a valid zip code');
            return false;
        }
        googleMapsHelpers.getStateByZipCode(
            $('#deliveryToZipMobile').val(),
            function (data) {
                if (data.state && (data.state == 'AK' || data.state == 'HI' || data.state == 'PR')) {
                    $('.row-state-error').removeClass('d-none');
                } else {
                    $.spinner().start();
                    $.ajax({
                        url: url,
                        data: data,
                        method: 'POST',
                        success: function (response) {
                            if (response.success) {
                                $('.delivery-to-code').html($('#deliveryToZipMobile').val());
                                $('.delivery-to-pdp').html($('#deliveryToZipMobile').val());
                                $('#deliveryToZipMobile').val('');
                                if (response.triggerStoreChange && $('.select-store-dropdown').length > 0) {
                                    $('.delivery-to-tooltip-mobile .tooltip').removeClass('d-none');
                                    setTimeout(function () {
                                        $('.delivery-to-tooltip-mobile .tooltip').addClass('d-none');
                                    }, 5000);
                                    $('.deliveryto-wrapper').removeClass('show');
                                    $('.deliveryto-wrapper').find('.dropdown-menu').removeClass('show');
                                }
                                if (response.promiseDates && response.promiseDates != 'undefined') {
                                    var htmlPromiseDates = response.promiseDates;
                                    if (!$('.select-store-dropdown').length) {
                                        var text = $(htmlPromiseDates).html();
                                        htmlPromiseDates = '<span class="shipping-item">' + text + '</span>';
                                        $('.pdp-shipping-item').html(htmlPromiseDates);
                                    } else {
                                        $('.promise-dates-target').html(htmlPromiseDates);
                                    }
                                }

                                $('.js-installation-wrapper').toggleClass('member', response.isEligibleForFieldServices);

                                $('.delivery-to-dropdown').removeClass('show');
                                $('#delivery-to-dropdown-mobile').addClass('d-none');
                                if (response.success && $('.cart').length > 0) {
                                    window.location.reload();
                                }
                            }
                        },
                        complete: function () {
                            $.spinner().stop();
                        }
                    })
                }
            },
            function (error) {
                console.log('no results');
            }
        );

    });


    $('.delivery-to-pdp-form').on('submit', function (e) {
        e.preventDefault();
        var data = $(this).serialize();
        var url = $(this).attr('action');
        $("#deliveryToPostalPdp").removeClass('is-invalid');
        $("#pdpDeliverytoError").html('');
        var zipCode = $('#deliveryToPostalPdp').val();
        var isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode);
        if (!isValidZip) {
            $("#deliveryToPostalPdp").addClass('is-invalid');
            $("#pdpDeliverytoError").html('Please add a valid zip code');
            return false;
        }

        var timezone = new Date().toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'short' }).substring(4);
        if (timezone && timezone != 'undefined') {
            data = data + "&timezone=" + timezone;
        }

        $.spinner().start();
        $.ajax({
            url: url,
            data: data,
            method: 'POST',
            success: function (response) {
                if (response.success) {
                    $('.delivery-to-pdp').html($('#deliveryToPostalPdp').val());
                    $('.delivery-to-code').html($('#deliveryToPostalPdp').val());
                    $('#deliveryToPostalPdp').val('');
                    if (response.promiseDates) {
                        var htmlPromiseDates = response.promiseDates;
                        if (!$('.select-store-dropdown').length && $('.page').data('site') !== 'its') {
                            var text = $(htmlPromiseDates).html();
                            htmlPromiseDates = '<span class="shipping-item">' + text + '</span>';
                            $('.pdp-shipping-item').html(htmlPromiseDates);
                        } else {
                            $('.promise-dates-target').html(htmlPromiseDates);
                        }
                        if ($('.sor-availability').length > 0) {
                            var rawData = response.rawPromiseDate.replace('Get it by ', '');
                            $('.sor-availability').find('.pdp-promise-date').html(rawData);
                        }

                        $('.estimate-sth-date-wrapper').length && $('.estimate-sth-date-wrapper').addClass('d-none');
                        $('.promise-dates-target').length && $('.promise-dates-target').removeClass('d-none');
                    } else {
                        $('.estimate-sth-date-wrapper').length && $('.estimate-sth-date-wrapper').removeClass('d-none');
                        $('.promise-dates-target').length && $('.promise-dates-target').addClass('d-none');
                    }
                    $('.delivery-to-pdp-form').addClass('d-none');
                    $('.delivery-to-pdp-section').removeClass('d-none');
                    $('.pdp-change-delivery-to').removeClass('d-none');
                    $('.pdp-change-delivery-to-hide').addClass('d-none');
                }
            },
            complete: function () {
                $.spinner().stop();
            }
        })
    });

    $('button.navbar-toggler .fa.fa-bars').on('click', function () {
        //prevent body scroll on tablet view
        $('body').css({ 'overflow': 'hidden', 'position': 'fixed' });
        $('.main-menu.navbar-toggleable-sm').css({ 'overflow-x': 'hidden', 'position': 'fixed' });
    });

    $('.mobile-nav-top .close-button').on('click', function () {
        $('body').css({ 'overflow': 'unset', position: 'unset' });
        $('.main-menus.navbar-toggleable-sm').css({ 'overflow': 'unset', 'position': 'unset' });
    });

    $(document).on('keyup', '.measurement, #liners-hopper-ft, #liners-hopper-in, #liners-wall-ft, #liners-wall-in', function () {
        this.value = this.value.replace(/[^\d]+/, '');
    });

    function resizeSorting() {
        if ($('.resizeselect').length === 2) {
            var $whereToMove = $('.sort-wrapping-parent:first');
            var $reRenderedSortingWrapper = $('.sort-wrapping-parent:not(:first)').children().clone();
            $whereToMove.empty().html($reRenderedSortingWrapper);
            $('.sort-wrapping-parent:not(:first)').remove();
            var filterBarSelector = '.filter-bar:not(:first)';
            if ($('.filter-bar').length === 1) {
                filterBarSelector = '.filter-bar';
            }
            var $filterBar = $(filterBarSelector).clone();
            $(filterBarSelector).remove();
            $whereToMove.parent().find('.filter-bar').remove();
            $whereToMove.after($filterBar);
        }
        $("#width_tmp_option").html($('#resizing_select option:selected').text());
        $('#resizing_select').width($("#width_tmp_select").width() - 20);

        $('#resizing_select').change(function () {
            $("#width_tmp_option").html($('#resizing_select option:selected').text());
            $(this).width($("#width_tmp_select").width() - 20);
        });
    }

    $(document).on('searchResults:updated', resizeSorting);
    $(document).ready(resizeSorting);

    //logic to add delivery info into PLP page
    $(document).ready(function () {
        var url = $('.js-page').data('url-delivery');
        if ($('.page').data('action') === 'Search-Show' && url) {
            var productIDs = [];
            $('.product-grid .product').each(function () {
                var pid = $(this).attr('data-pid');
                productIDs.push(pid);
            });
            $.spinner().start();
            $.ajax({
                url: url,
                data: {
                    productIds: JSON.stringify(productIDs),
                    storeID: $('.header-store-nickname').attr('data-store-id')
                },
                method: 'POST',
                success: function (response) {
                    if (response.success && response.products) {
                        response.products.forEach(function (item) {
                            if ($('.product[data-pid="' + item.pid + '"]')) {
                                $('.product[data-pid="' + item.pid + '"]')
                                    .find('.delivery-options-wrapper').html(item.html);
                            }
                        })
                    }
                },
                complete: function () {
                    $.spinner().stop();
                }
            });
        }
    });

    $(window).on('load', function () {
        if (!window.sessionStorage.getItem('loyaltyCardCheck')) {
            var url = $('.js-page').data('url-loyaltycard');
            $.ajax({
                url: url,
                method: 'GET',
                success: function (response) {
                    if (response.success) {
                        window.sessionStorage.setItem('loyaltyCardCheck', true);
                    }
                }
            });
        }

        if (!window.sessionStorage.getItem('loyaltyDataCheck')) {
            var url = $('.js-page').data('url-loyaltydata');
            var page = $('.js-page').attr('data-action');
            var data = {};
            if (page == 'Account-Show') {
                data.myaccount = true;
            }
            $.ajax({
                url: url,
                data: data,
                method: 'GET',
                success: function (response) {
                    if (response.success) {
                        if (response.loyaltyHeaderHtml) {
                            $('.user-loyalty').replaceWith(response.loyaltyHeaderHtml);
                        }
                        if (response.loyaltyDashboardHtml && response.loyaltyDashboardHtml != '' && page == 'Account-Show') {
                            $('.dashboard-loyalty').replaceWith(response.loyaltyDashboardHtml);
                        }
                        window.sessionStorage.setItem('loyaltyDataCheck', true);
                    }
                }
            });
        }

        /* if ($('.preloaded_lightbox').length > 0) {
            const gtmData = {
                'event': 'email_popup',
                'eventAction': 'start',
                'pageType': true
            };
            $('body').trigger('gtm:push', gtmData);
        } */
    })

    $(document).on('click', 'form[name="login-form"] button', function () {
        window.sessionStorage.removeItem('loyaltyCardCheck');
        window.sessionStorage.removeItem('loyaltyDataCheck');
    })
};
