'use strict';
var BrTrkHandler = require('bloomreach/helperPixel');

module.exports = (function () {
    // Validate blank inputs on Search form
    var searchForm = document.querySelector("form[name='simpleSearch']");
    if (searchForm) {
        var searchField = searchForm.querySelector(".search-field");

    }

    function reset() {
      var errorMessage = searchForm.querySelector(".invalid-feedback");

      searchField.classList.remove("is-invalid");

      if (errorMessage) {
        errorMessage.remove();
      }
    }

      function showError() {
        var searchTermRequired = "Please enter a search term and try again.";
        var errorHTML = document.createElement("div");

        reset();

        searchField.classList.add("is-invalid");

        errorHTML.classList.add("invalid-feedback", "px-3", "pt-1", "pb-2", "bg-white", "border");
        errorHTML.style.position = "absolute";
        errorHTML.style.zIndex = 1;
        errorHTML.innerText = searchTermRequired;
        searchForm.appendChild(errorHTML);
      }

      if (searchForm) {
        searchForm.addEventListener("submit", function (e) {
          var searchTerm = e.target["q"].value.trim();
          if (!searchTerm.length) {
            showError();
            e.preventDefault();
          }
          if (window.resources.bloomreach.pixel === 'true') {
            var productTab = $('.search-tab').find('.product')
            var contentTab = $('.search-tab').find('.content')
            var catalogs = [];
            if (productTab.length > 0) {
                catalogs.push({ "name": "lesliespool" })
            }
            if (contentTab.length > 0) {
                catalogs.push({ "name": "content_en" })
            }
            var searchData = {};
            searchData["q"] = $('.search .search-field').val().replaceAll('  ', '').replaceAll('\n', '');
            searchData["catalogs"] = catalogs;

            BrTrkHandler.brtrkHandle(searchData, 'Event', 'suggest', 'submit');
          }
          
        });
        searchField.addEventListener("keypress", function () {
          reset();
        });
        
        document.addEventListener("click", function (event) {
          var clickedAway = !event.target.contains(searchField);
      
          if (clickedAway) {
            reset();
          }
        });
    }


  
})();
