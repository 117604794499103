'use strict';
var focusHelper = require('base/components/focus');
var productTileCarousel = require('../components/productTileCarousel');
var preferences = require('refArch/config/preferences');


/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    var pid;

    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').find('.product-id').text();
    } else {
        pid = $('.product-detail:not(".bundle-item")').data('pid');
    }

    return pid;
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    return $el && $('.set-items').length
        ? $($el).closest('.product-detail').find('.quantity-select')
        : $('.quantity-select');
}

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
function getQuantitySelected($el) {
    return getQuantitySelector($el).val();
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
function processSwatchValues(attr, $productContainer, msgs) {
    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find(
            '[data-attr="' + attr.id + '"] [data-attr-value="' + attrValue.value + '"]'
        );
        var $swatchButton = $attrValue.parent();
        var $swatchButtonArialLabel = $swatchButton.attr('aria-label').toString();

        if (attrValue.selected) {
            $swatchButtonArialLabel = $swatchButtonArialLabel.replace(msgs.assistiveSelectText, msgs.assistiveSelectedText);
            $swatchButton.attr('aria-label', $swatchButtonArialLabel);
            $attrValue.addClass('selected');
            $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);

        } else {
            $swatchButtonArialLabel = $swatchButtonArialLabel.replace(msgs.assistiveSelectedText, msgs.assistiveSelectText);
            $swatchButton.attr('aria-label', $swatchButtonArialLabel);
            $attrValue.removeClass('selected');
            $attrValue.siblings('.selected-assistive-text').empty();
        }

        if (attrValue.url) {
            $swatchButton.attr('data-url', attrValue.url);
        } else {
            $swatchButton.removeAttr('data-url');
        }

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable');

        $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');
    });
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processNonSwatchValues(attr, $productContainer) {
    var $attr = '[data-attr="' + attr.id + '"]';
    var $defaultOption = $productContainer.find($attr + ' .select-' + attr.id + ' option:first');
    $defaultOption.attr('value', attr.resetUrl);
    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find($attr + ' [data-attr-value="' + attrValue.value + '"]');
        $attrValue.attr('value', attrValue.url).attr('hidden', false);

        if (!attrValue.selectable) {
            $attrValue.attr('hidden', true);
        }
    });
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} msgs - object containing resource messages
 */
function updateAttrs(attrs, $productContainer, msgs) {
    // Currently, the only attribute type that has image swatches is Color.
    var attrsWithSwatches = ['color'];
    attrs.forEach(function (attr) {
        if (attrsWithSwatches.indexOf(attr.id) > -1) {
            processSwatchValues(attr, $productContainer, msgs);
            var $label = $('.js-attr-label');
            var displayName = '';
            attr.values.forEach(function (attrValue) {
                if (attrValue.selected) {
                    displayName = attrValue.displayValue;
                }
            });
            $label.text($label.data('labelText') + ' ' + displayName);
        } else {
            processNonSwatchValues(attr, $productContainer);
        }
    });
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer) {
    var availabilityValue = '';
    var availabilityMessages = response.product.availability.messages;
    if (!response.product.readyToOrder) {
        availabilityValue = '<li><div>' + response.resources.info_selectforstock + '</div></li>';
    } else {
        availabilityMessages.forEach(function (message) {
            availabilityValue += '<li><div>' + message + '</div></li>';
        });
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
function getAttributesHtml(attributes) {
    if (!attributes) {
        return '';
    }

    var html = '';

    attributes.forEach(function (attributeGroup) {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach(function (attribute) {
                html += '<div class="attribute-values">' + attribute.label + ': ' + attribute.value + '</div>';
            });
        }
    });

    return html;
}

/**
 * @typedef UpdatedOptionValue
 * @type Object
 * @property {string} id - Option value ID for look up
 * @property {string} url - Updated option value selection URL
 */

/**
 * @typedef OptionSelectionResponse
 * @type Object
 * @property {string} priceHtml - Updated price HTML code
 * @property {Object} options - Updated Options
 * @property {string} options.id - Option ID
 * @property {UpdatedOptionValue[]} options.values - Option values
 */

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} optionsHtml - Ajax response optionsHtml from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateOptions(optionsHtml, $productContainer) {
    // Update options
    $productContainer.find('.product-options').empty().html(optionsHtml);
}

/**
 * Creates new carousel from response html string
 * @param {string} pictureHtml - string of html for picture tag
 * @param {Object} $productContainer - picture tag container
 */
function createCarousel(pictureHtml, $productContainer) {
    $productContainer
        .find('.js-pdp-main-images')
        .children('.js-pdp-main-image')
        .each(function (index) {
            $(this).html(pictureHtml[index]);
        });
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer) {
    var isChoiceOfBonusProducts = $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVaraint;
    if (response.product.variationAttributes) {
        updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
        isVaraint = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVaraint) {
            $productContainer.parent('.bonus-product-item').data('pid', response.product.id);

            $productContainer.parent('.bonus-product-item').data('ready-to-order', response.product.readyToOrder);
        }
    }
    createCarousel(response.product.productPictures, $productContainer);

    // Update pricing
    if (!isChoiceOfBonusProducts) {
        var $priceSelector = $('.prices .price', $productContainer).length
            ? $('.prices .price', $productContainer)
            : $('.prices .price');
        var html = response.product.price.html;
        $priceSelector.replaceWith(html);
        if ($('.warranty-authenticated').length > 0) {
            var $newPriceSelector = $('.prices .price', $productContainer).length
                ? $('.prices .price', $productContainer)
                : $('.prices .price');
            var newOptionsHTML = response.product.optionsHtml;
            var itemSelected = $(newOptionsHTML).find('.warranty-authenticated').find('input[checked]');
            var itemPrice = itemSelected.siblings('.warranty-pdp-label').find('.final-price');
            if (itemPrice && itemPrice.attr('data-price') !== '$0.00' && itemPrice.html().indexOf('FREE') != -1) {
                $('.warn-logged-warranty').removeClass('d-none');
                var warrantyPrice = itemPrice.attr('data-price').replace('$', '');
                warrantyPrice = parseFloat(warrantyPrice);
                var htmlPrice = $newPriceSelector.find('.sales').find('.value').attr('content');
                if (htmlPrice) {
                    var priceWithoutWarranty = parseFloat(htmlPrice) - warrantyPrice;
                    $newPriceSelector.find('.sales').find('.value').attr('content', priceWithoutWarranty);
                    var formattedPrice = priceWithoutWarranty.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    $newPriceSelector.find('.sales').find('.value').html(formattedPrice);
                }
            } else {
                $('.warn-logged-warranty').addClass('d-none');
            }
        }


        if (response.product.price.sales && $('.warranty-pdp-container').length == 0) {
            var priceWithQuantity = response.product.price.sales.decimalPrice * response.product.selectedQuantity;
            var pointsEarn = Math.floor(priceWithQuantity / 20).toFixed(0);
            //Update Loyalty Earning points Message
            if ($("#loyalty-perk-points-message-registered").length) {
                var message = "Earn at least $" + pointsEarn + " in Pool Perks for this item";
                $("#loyalty-perk-points-message-registered").text(message)
            }
            else if ($("#loyalty-perk-points-message-guest").length) {
                var message = "Earn at least $" + pointsEarn + " in pool perks for this item by joining Pool Perks.";
                $("#loyalty-perk-points-message-guest").text(message)
            }
        }
    }

    // Update promotions
    $productContainer.find('.promotions').empty().html(response.product.promotionsHtml);

    updateAvailability(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product,
            $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global')
            .trigger('product:updateAddToCart', {
                product: response.product,
                $productContainer: $productContainer
            })
            .trigger('product:statusUpdate', response.product);

        $('body').trigger('buynow:updateAvailability', false);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty().html(getAttributesHtml(response.product.attributes));
}

/**
 * @typespec UpdatedQuantity
 * @type Object
 * @property {boolean} selected - Whether the quantity has been selected
 * @property {string} value - The number of products to purchase
 * @property {string} url - Compiled URL that specifies variation attributes, product ID, options,
 *     etc.
 */

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {UpdatedQuantity[]} quantities -
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function updateQuantities(quantities, $productContainer) {
    if (!($productContainer.parent('.bonus-product-item').length > 0)) {
        var optionsHtml = quantities
            .map(function (quantity) {
                var selected = quantity.selected ? ' selected ' : '';
                return (
                    '<option value="' +
                    quantity.value +
                    '"  data-url="' +
                    quantity.url +
                    '"' +
                    selected +
                    '>' +
                    quantity.value +
                    '</option>'
                );
            })
            .join('');
        getQuantitySelector($productContainer).empty().html(optionsHtml);
    }
}

function updateBuyNowButton(pid) {
    var $addToCart = $('.add-to-cart');
    var $buyNowButton = $('.buynow-button');
    if ($addToCart.data('pid') == pid && $buyNowButton.data('pid') == pid) {
        $addToCart.attr('disabled', 'disabled')
        $buyNowButton.attr('disabled', 'disabled')
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
function attributeSelect(selectedValueUrl, $productContainer, updateQuantity) {
    if (selectedValueUrl) {
        $('body').trigger('product:beforeAttributeSelect', { url: selectedValueUrl, container: $productContainer });

        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                updateOptions(data.product.optionsHtml, $productContainer);
                handleVariantResponse(data, $productContainer);
                updateQuantities(data.product.quantities, $productContainer);
                if (data.availability) {
                    $('body').trigger('product:updateDeliveryCard', data.availability);
                }

                if (data.product.available == false) {
                    updateBuyNowButton(data.product.id);
                }

                if (updateQuantity == null) {
                    $('body').trigger('product:afterAttributeSelect', { data: data, container: $productContainer });
                }
                if ($('.delivery-to-pdp-form').length > 0) {
                    var prevURL = $('.delivery-to-pdp-form').attr('action').slice(0, $('.delivery-to-pdp-form').attr('action').indexOf('productId=')) + 'productId=' + (data && data.product && data.product.id);
                    $('.delivery-to-pdp-form').prop('action', prevURL);
                }
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $('.add-to-cart-url').val();
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.choice-of-bonus-product');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    var htmlString =
        '<!-- Modal -->' +
        '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">' +
        '<span class="enter-message sr-only" ></span>' +
        '<div class="modal-dialog choose-bonus-product-dialog" ' +
        'data-total-qty="' +
        data.maxBonusItems +
        '"' +
        'data-UUID="' +
        data.uuid +
        '"' +
        'data-pliUUID="' +
        data.pliUUID +
        '"' +
        'data-addToCartUrl="' +
        data.addToCartUrl +
        '"' +
        'data-pageStart="0"' +
        'data-pageSize="' +
        data.pageSize +
        '"' +
        'data-moreURL="' +
        data.showProductsUrlRuleBased +
        '"' +
        'data-bonusChoiceRuleBased="' +
        data.bonusChoiceRuleBased +
        '">' +
        '<!-- Modal content-->' +
        '<div class="modal-content">' +
        '<div class="modal-header">' +
        '    <span class="">' +
        data.labels.selectprods +
        '</span>' +
        '    <button type="button" class="close pull-right" data-dismiss="modal">' +
        '        <span aria-hidden="true">&times;</span>' +
        '        <span class="sr-only"> </span>' +
        '    </button>' +
        '</div>' +
        '<div class="modal-body"></div>' +
        '<div class="modal-footer"></div>' +
        '</div>' +
        '</div>' +
        '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
            var parsedHtml = parseHtml(response.renderedTemplate);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    var messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast
    if (response.newBonusDiscountLineItem && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else if (!response.showAddToCartModal && response.message) {
        if ($('.add-to-cart-messages').length === 0) {
            $('body').append('<div class="add-to-cart-messages"></div>');
        }

        $('.add-to-cart-messages').append(
            '<div class="alert ' +
            messageType +
            ' add-to-basket-alert text-center" role="alert">' +
            response.message +
            '</div>'
        );

        setTimeout(function () {
            $('.add-to-basket-alert').remove();
        }, 5000);
    }
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
    var childProducts = [];
    $('.bundled-item').each(function () {
        childProducts.push({
            pid: $(this).data('pid'),
            quantity: parseInt($('.quantity-select').val(), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find('.product-option')
        .map(function () {
            var selectedValueId = '';
            if ($('.warranty-pdp-container').length > 0 && $(this).data('option-id') === 'warranty') {
                selectedValueId = $('input[name="warranty-pdp"]:checked').data('value-id');
            } else if ($(this).data('option-id') === 'fieldServices') {
                selectedValueId = $('input[name="installation-service"]:checked').data('value-id') || '';
            } else {
                var $elOption = $(this).find('.options-select');
                var urlValue = $elOption.val();
                selectedValueId = $elOption.find('option[value="' + urlValue + '"]').data('value-id');
                if ($(this).data('option-id') == 'warranty') {
                    if ($(this).find('option[data-price="$0.00"]').length > 0) {
                        selectedValueId = $(this).find('option[data-price="$0.00"]').data('value-id');
                    }
                }
            }
            return {
                productId: $productContainer.find('.product-id').text(),
                optionId: $(this).data('option-id'),
                selectedValueId: selectedValueId
            };
        })
        .toArray();

    return JSON.stringify(options);
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url: url,
            method: 'GET',
            success: function () {
                // reporting urls hit on the server
            },
            error: function () {
                // no reporting urls hit on the server
            }
        });
    }
}

/**
 *
 * @param {Object} data Previously submitted form data
 */
function showSorLineItemDialog(data) {
    let sorLineitemDialogEle = document.querySelector('.sor-lineitem-dialog');
    if (sorLineitemDialogEle) {
        sorLineitemDialogEle.remove();
    }

    const htmlString =
        '<div class="modal fade sor-lineitem-dialog" tabindex="-1" role="dialog">' +
        '<div class="modal-dialog" role="document">' +
        '<div class="modal-content">' +
        '<div class="modal-header">' +
        '<h5 class="modal-title">' + data.resources.title + '</h5>' +
        '</div>' +
        '<div class="modal-body">' +
        '<p>' + data.resources.body + '</p>' +
        '</div>' +
        '<div class="modal-footer">' +
        '<button type="button" class="btn btn-secondary" data-dismiss="modal">' + data.resources.btnNo + '</button>' +
        '<button type="button" class="btn btn-primary js-sor-lineitem-dialog__btn--accept" data-dismiss="modal">' + data.resources.btnYes + '</button>' +
        '</div>' +
        '</div>' +
        '</div>' +
        '</div>'
        ;

    $('body').append(htmlString);
    sorLineitemDialogEle = document.querySelector('.sor-lineitem-dialog');

    // Events
    let adcBtn = document.querySelector('.add-to-cart-global[data-pid="' + data.form.pid + '"]');
    if (!adcBtn) {
        adcBtn = document.querySelector('.add-to-cart[data-pid="' + data.form.pid + '"]');
    }
    const acceptBtn = document.querySelector('.js-sor-lineitem-dialog__btn--accept');

    if (acceptBtn) {
        acceptBtn.addEventListener('click', function (e) {
            e.preventDefault();
            // @TODO Use MVC - click handler insted of `trigger`
            $(adcBtn).trigger('click', { sorLineItemDialogAccepted: true });
        });
    }

    $(sorLineitemDialogEle).modal('show');
}

function updateRefillDataAjax(response) {
    if (!response.error && response.refillInfoUrl) {
        return $.ajax({
            type: "POST",
            url: SubscriptionData.Urls.updateRefillData,
            data: {
                action: "update",
                hasSubscription: $("[name=\"subscriptionOption\"]").filter(":checked").val(),
                everyDelivery: $("[name=\"everyDelivery\"]").val(),
                weekInterval: $("[name=\"weekInterval\"]").val(),
                monthInterval: $("[name=\"monthInterval\"]").val(),
                liuuid: response.pliUUID
            },
            error: function (err) {
                console.log('Error while updating SOR info');
            }
        });
    }
}

module.exports = {
    attributeSelect: attributeSelect,
    methods: {
        editBonusProducts: function (data) {
            chooseBonusProducts(data);
        }
    },

    focusChooseBonusProductModal: function () {
        $('body').on('shown.bs.modal', '#chooseBonusProductModal', function () {
            $('#chooseBonusProductModal').siblings().attr('aria-hidden', 'true');
            $('#chooseBonusProductModal .close').focus();
        });
    },

    onClosingChooseBonusProductModal: function () {
        $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
            $('#chooseBonusProductModal').siblings().attr('aria-hidden', 'false');
        });
    },

    trapChooseBonusProductModalFocus: function () {
        $('body').on('keydown', '#chooseBonusProductModal', function (e) {
            var focusParams = {
                event: e,
                containerSelector: '#chooseBonusProductModal',
                firstElementSelector: '.close',
                lastElementSelector: '.add-bonus-products'
            };
            focusHelper.setTabNextFocus(focusParams);
        });
    },

    colorAttribute: function () {
        $(document).on('click', '[data-attr="color"] button', function (e) {
            e.preventDefault();

            if ($(this).attr('disabled')) {
                return;
            }
            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }

            attributeSelect($(this).attr('data-url'), $productContainer);
        });
    },

    selectAttribute: function () {
        $(document).on('change', 'select[class*="select-"], .options-select', function (e) {
            e.preventDefault();
            e.stopPropagation();
            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }
            attributeSelect(e.currentTarget.value, $productContainer);
        });
    },

    availability: function () {
        $(document).on('change', '.quantity-select', function (e) {
            e.preventDefault();
            e.stopPropagation();
            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.modal-content').find('.product-quickview');
            }

            if ($('.bundle-items', $productContainer).length === 0) {
                attributeSelect($(e.currentTarget).find('option:selected').data('url'), $productContainer, true);
            }
        });
    },

    addToCart: function () {
        $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function (e, params) {
            params = params || {}
            var addToCartUrl;
            var pid;
            var pidsObj;
            var setPids;

            $('body').trigger('product:beforeAddToCart', this);

            if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
                setPids = [];

                $('.product-detail').each(function () {
                    if (!$(this).hasClass('product-set-detail')) {
                        setPids.push({
                            pid: $(this).find('.product-id').text(),
                            qty: $(this).find('.quantity-select').val(),
                            options: getOptions($(this))
                        });
                    }
                });
                pidsObj = JSON.stringify(setPids);
            }

            pid = getPidValue($(this));

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
            }

            addToCartUrl = getAddToCartUrl();

            var form = {
                pid: pid,
                pidsObj: pidsObj,
                childProducts: getChildProducts(),
                quantity: getQuantitySelected($(this))
            };

            if (form.quantity > preferences.maxOrderQty) {
                $.spinner().stop();
                if ($('.add-to-cart-messages').length === 0) {
                    $('body').append('<div class="add-to-cart-messages"></div>');
                }
                $('.add-to-cart-messages').html(
                    '<div class="alert alert-danger' +
                        ' add-to-basket-alert text-center" role="alert">' +
                        'Max order qtantity limit reached' +
                        '</div>'
                );

                setTimeout(function () {
                    $('.add-to-basket-alert').remove();
                }, 5000);
                return false;
            }

            // Add extra data for line item check in basket
            if (window.SubscriptionData && window.SubscriptionData.SitePreferences.SOR_ENABLED) {
                const hasSmartOrderRefill = $("[name='subscriptionOption']").filter(":checked").val() === 'true' ? true : false;
                const deliveryMethod = $("[name='deliveryMethod']").filter(":checked").val();
                const isBopis = deliveryMethod === 'ShipToStore' || deliveryMethod === 'PickUpAtStore' ? true : false;

                form.hasSmartOrderRefill = hasSmartOrderRefill;
                form.isBopis = isBopis;
                form.addToCartUrl = addToCartUrl;
            }

            // Don't show `showSorLineItemDialog` when accepted
            if (params.hasOwnProperty('sorLineItemDialogAccepted')) {
                form.sorLineItemDialogAccepted = params.sorLineItemDialogAccepted;
            }

            // Store Pickup
            var deliveryMethod = $('input[name=deliveryMethod]:checked')
            form.deliveryMethod = deliveryMethod.val();

            if (deliveryMethod.data('store')) {
                var store = deliveryMethod.data('store');
                form.storeId = store;
            }

            // One Day available
            var oneDayAvailable = $('.js-odd-result-msg').data("oneDayAvailable");

            if (oneDayAvailable) {
                form.oneDay = $('.js-odd-zipcode').val();
            }

            if (!$('.bundle-item').length) {
                form.options = getOptions($productContainer);
            }

            var timezone = new Date().toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'short' }).substring(4);
            if (timezone && timezone != 'undefined') {
                form.timezone = timezone;
            }

            $(this).trigger('updateAddToCartFormData', form);

            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success: function (data) {
                        if (window.SubscriptionData && window.SubscriptionData.SitePreferences.SOR_ENABLED && data.showSorLineItemAlert) {
                            $.spinner().stop();
                            showSorLineItemDialog(data);
                            return;
                        }

                        handlePostCartAdd(data);
                        data.updateRefillData = false;
                        if (!data.error) {
                            $('body').trigger('product:afterAddToCart', data);
                        }

                        $.when(updateRefillDataAjax(data)).done(function (updateRefillResponse) {

                            if (!data.error) {
                                miniCartReportingUrl(data.reportingURL);
                            }

                            if (data.error) {
                                $('#pickupInStore').parents('.delivery-card').find('#pickupInStore').val("ShipToStore");
                                $('body').find('.pickup-date').text("LATER").css("color", "#212529");
                                $('body').find('.pickup-later').css("display", "inline")
                                $('body').find('.pickup-today').css("display", "none")
                                if ($('.add-to-cart-messages').length === 0) {
                                    $('body').append('<div class="add-to-cart-messages"></div>');
                                }
                                $('.add-to-cart-messages').html(
                                    '<div class="alert alert-danger' +
                                        ' add-to-basket-alert text-center" role="alert">' +
                                        data.message +
                                        '</div>'
                                );

                                setTimeout(function () {
                                    $('.add-to-basket-alert').remove();
                                }, 5000);
                            } else if (data.isLastBopisItems && data.availabilityHtml) {
                                $('.store-inventory-container').empty().append(data.availabilityHtml).find('input').prop('checked', true);

                                $('.add-to-cart-messages').append(
                                    '<div class="alert alert-danger' +
                                    ' add-to-basket-alert text-center" role="alert">' +
                                    data.lastBopisError +
                                    '</div>'
                                );

                                setTimeout(function () {
                                    $('.add-to-basket-alert').remove();
                                }, 5000);
                            }

                            var refillInfoData = {
                                lineItemID: data.pliUUID,
                                orderNumber: '',
                                showLinks: false,
                                addedQuantity: data.addedQuantity,
                                pdpModal: true
                            };

                            var buyNowButton = $('.buynow-button');
                            if (buyNowButton.length && buyNowButton.hasClass("buynow-triggered")) {
                                $('body').trigger('buynow:afterAddToCart');
                                if (!data.error && data.showAddToCartModal) {
                                    const postAddToCartModal = data.postAddToCartModal || {};
                                    const $modal = $(postAddToCartModal.container);
                                    const $modalContent = $modal.find('.modal-content');
                                    $modalContent.append(postAddToCartModal.header);
                                    $modalContent.append(postAddToCartModal.body);

                                    if (data.refillInfoUrl) {
                                        $.ajax({
                                            url: data.refillInfoUrl,
                                            method: 'GET',
                                            data: refillInfoData,
                                            success: function (response) {
                                                $modal.find('.modal-slot-container').html(response);
                                                $modal.modal('show');
                                                $.spinner().stop();
                                            }
                                        });
                                    } else {
                                        $modal.modal('show');
                                        $.spinner().stop();
                                    }
                                }
                            } else {
                                $.spinner().stop();
                            }

                            if (!data.error && data.showAddToCartModal) {
                                const postAddToCartModal = data.postAddToCartModal || {};
                                const $modal = $(postAddToCartModal.container);
                                const $modalContent = $modal.find('.modal-content');
                                $modalContent.append(postAddToCartModal.header);
                                $modalContent.append(postAddToCartModal.body);
                                $modalContent.append(postAddToCartModal.footer);
                                var IsBloomReachRecommendation = window.resources.bloomreach.enabled && window.resources.bloomreach.recommendationEnabled;
                                var widgetID = window.resources.bloomreach.SPWidgetID;
                                var acctID = window.resources.bloomreach.accountID;
                                var domainKey = window.resources.bloomreach.domainKey;
                                var productId = $('.js-product-detail-page').data('pid');
                                var bloomreachURL = `${window.resources.bloomreach.bloomreachRecommendationURL}?pid=${productId}&widgetID=${widgetID}&acctID=${acctID}&domainKey=${domainKey}&pageFrom=view`;

                                if (data.refillInfoUrl) {
                                    $.ajax({
                                        url: data.refillInfoUrl,
                                        method: 'GET',
                                        data: refillInfoData,
                                        success: function (response) {
                                            $modal.find('.modal-slot-container').html(response);
                                            $modal.modal('show');
                                            $.spinner().stop();
                                        }
                                    });
                                    if (IsBloomReachRecommendation === 'true') {
                                        $.ajax({
                                            url: bloomreachURL,
                                            method: 'GET',
                                            success: function (response) {
                                                $modal.find('.modal-slot-product').html(response);
                                                productTileCarousel.initModalCarousel();
                                                if (typeof yotpo !== 'undefined') {
                                                    yotpo.refreshWidgets();

                                                }
                                                if (IsBloomReachRecommendation) {
                                                    require('bloomreach/bloomreachEvent');
                                                };
                                            }
                                        });
                                    }
                                } else {
                                    $modal.modal('show');
                                    if (IsBloomReachRecommendation === 'true') {
                                        $.ajax({
                                            url: bloomreachURL,
                                            method: 'GET',
                                            success: function (response) {
                                                $modal.find('.modal-slot-product').html(response);
                                                productTileCarousel.initModalCarousel();
                                                if (typeof yotpo !== 'undefined') {
                                                    yotpo.refreshWidgets();

                                                }
                                                if (IsBloomReachRecommendation) {
                                                    require('bloomreach/bloomreachEvent');
                                                };
                                            }
                                        });
                                    }
                                    $.spinner().stop();
                                }
                            } else {
                                $.spinner().stop();
                            }
                        });

                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        $.spinner().stop();
                    }
                }
                );
            }
        });

        //Recommendation add to cart
        function addToCart() {
            $.spinner().start();
            let pidsObj = [];
            let items = document.querySelectorAll('.item-checkboxes');
            let addToCartURL = document.querySelectorAll('.add-to-cart-url')[0].value;
            if (items.length > 0) {
                items.forEach(function (item) {
                    let checkbox = item.querySelectorAll('input')[0];
                    if (checkbox.checked) {
                        pidsObj.push({
                            'pid': checkbox.dataset.pid,
                            'qty': '1',
                            'options': ''
                        });
                    }
                });

                const xhr = new XMLHttpRequest();

                xhr.open('POST', addToCartURL, true);
                xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded; charset=UTF-8");

                xhr.onreadystatechange = function () {

                    if (xhr.readyState == 4) {
                        $.spinner().stop();
                        if (xhr.status == 200) {
                            handlePostCartAdd(JSON.parse(xhr.responseText));
                        }
                        else {
                            console.log(xhr.responseText);
                        }
                    }
                }

                xhr.send('pidsObj=' + JSON.stringify(pidsObj));
            }
        }
        if (document.querySelectorAll('.add-to-cart-together').length > 0) {
            document.querySelectorAll('.add-to-cart-together')[0].addEventListener('click', addToCart);
        }

        $(document).on('click', '.warranty-pdp-container input', function () {
            var id = $(this).attr('data-value-id');
            if (id && $('select[id="Warranty"]').length > 0) {
                if ($('select[id="Warranty"]').find('option[data-value-id="' + id + '"]').length > 0) {
                    var optionID = $('select[id="Warranty"]').find('option[data-value-id="' + id + '"]').val();
                    $('select[id="Warranty"]').val(optionID);
                    $('select[id="Warranty"]').trigger('change');
                }
            }
        });


    },

    selectBonusProduct: function () {
        $(document).on('click', '.select-bonus-product', function () {
            var $choiceOfBonusProduct = $(this).parents('.choice-of-bonus-product');
            var pid = $(this).data('pid');
            var maxPids = $('.choose-bonus-product-dialog').data('total-qty');
            var submittedQty = parseInt($choiceOfBonusProduct.find('.bonus-quantity-select').val(), 10);
            var totalQty = 0;
            $.each($('#chooseBonusProductModal .selected-bonus-products .selected-pid'), function () {
                totalQty += $(this).data('qty');
            });
            totalQty += submittedQty;
            var optionID = $choiceOfBonusProduct.find('.product-option').data('option-id');
            var valueId = $choiceOfBonusProduct.find('.options-select option:selected').data('valueId');
            if (totalQty <= maxPids) {
                var selectedBonusProductHtml =
                    '' +
                    '<div class="selected-pid row" ' +
                    'data-pid="' +
                    pid +
                    '"' +
                    'data-qty="' +
                    submittedQty +
                    '"' +
                    'data-optionID="' +
                    (optionID || '') +
                    '"' +
                    'data-option-selected-value="' +
                    (valueId || '') +
                    '"' +
                    '>' +
                    '<div class="col-sm-11 col-9 bonus-product-name" >' +
                    $choiceOfBonusProduct.find('.product-name').html() +
                    '</div>' +
                    '<div class="col-1"><i class="fa fa-times" aria-hidden="true"></i></div>' +
                    '</div>';
                $('#chooseBonusProductModal .selected-bonus-products').append(selectedBonusProductHtml);
                $('.pre-cart-products').html(totalQty);
                $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
            } else {
                $('.selected-bonus-products .bonus-summary').addClass('alert-danger');
            }
        });
    },
    removeBonusProduct: function () {
        $(document).on('click', '.selected-pid', function () {
            $(this).remove();
            var $selected = $('#chooseBonusProductModal .selected-bonus-products .selected-pid');
            var count = 0;
            if ($selected.length) {
                $selected.each(function () {
                    count += parseInt($(this).data('qty'), 10);
                });
            }

            $('.pre-cart-products').html(count);
            $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
        });
    },
    enableBonusProductSelection: function () {
        $('body').on('bonusproduct:updateSelectButton', function (e, response) {
            $('button.select-bonus-product', response.$productContainer).attr(
                'disabled',
                !response.product.readyToOrder || !response.product.available
            );
            var pid = response.product.id;
            $('button.select-bonus-product', response.$productContainer).data('pid', pid);
        });
    },
    showMoreBonusProducts: function () {
        $(document).on('click', '.show-more-bonus-products', function () {
            var url = $(this).data('url');
            $('.modal-content').spinner().start();
            $.ajax({
                url: url,
                method: 'GET',
                success: function (html) {
                    var parsedHtml = parseHtml(html);
                    $('.modal-body').append(parsedHtml.body);
                    $('.show-more-bonus-products:first').remove();
                    $('.modal-content').spinner().stop();
                },
                error: function () {
                    $('.modal-content').spinner().stop();
                }
            });
        });
    },
    addBonusProductsToCart: function () {
        $(document).on('click', '.add-bonus-products', function () {
            var $readyToOrderBonusProducts = $('.choose-bonus-product-dialog .selected-pid');
            var queryString = '?pids=';
            var url = $('.choose-bonus-product-dialog').data('addtocarturl');
            var pidsObject = {
                bonusProducts: []
            };

            $.each($readyToOrderBonusProducts, function () {
                var qtyOption = parseInt($(this).data('qty'), 10);

                var option = null;
                if (qtyOption > 0) {
                    if ($(this).data('optionid') && $(this).data('option-selected-value')) {
                        option = {};
                        option.optionId = $(this).data('optionid');
                        option.productId = $(this).data('pid');
                        option.selectedValueId = $(this).data('option-selected-value');
                    }
                    pidsObject.bonusProducts.push({
                        pid: $(this).data('pid'),
                        qty: qtyOption,
                        options: [option]
                    });
                    pidsObject.totalQty = parseInt($('.pre-cart-products').html(), 10);
                }
            });
            queryString += JSON.stringify(pidsObject);
            queryString = queryString + '&uuid=' + $('.choose-bonus-product-dialog').data('uuid');
            queryString = queryString + '&pliuuid=' + $('.choose-bonus-product-dialog').data('pliuuid');
            $.spinner().start();
            $.ajax({
                url: url + queryString,
                method: 'POST',
                success: function (data) {
                    $.spinner().stop();
                    if (data.error) {
                        $('#chooseBonusProductModal').modal('hide');
                        if ($('.add-to-cart-messages').length === 0) {
                            $('body').append('<div class="add-to-cart-messages"></div>');
                        }
                        $('.add-to-cart-messages').append(
                            '<div class="alert alert-danger add-to-basket-alert text-center"' +
                            ' role="alert">' +
                            data.errorMessage +
                            '</div>'
                        );
                        setTimeout(function () {
                            $('.add-to-basket-alert').remove();
                        }, 3000);
                    } else {
                        $('.configure-bonus-product-attributes').html(data);
                        $('.bonus-products-step2').removeClass('hidden-xl-down');
                        $('#chooseBonusProductModal').modal('hide');

                        if ($('.add-to-cart-messages').length === 0) {
                            $('body').append('<div class="add-to-cart-messages"></div>');
                        }
                        $('.minicart-quantity').html(data.totalQty);
                        $('.add-to-cart-messages').append(
                            '<div class="alert alert-success add-to-basket-alert text-center"' +
                            ' role="alert">' +
                            data.msgSuccess +
                            '</div>'
                        );
                        setTimeout(function () {
                            $('.add-to-basket-alert').remove();
                            if ($('.cart-page').length) {
                                location.reload();
                            }
                        }, 1500);
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },

    getPidValue: getPidValue,
    getQuantitySelected: getQuantitySelected,
    miniCartReportingUrl: miniCartReportingUrl,
    getChildProducts: getChildProducts,
    getOptions: getOptions
};
