'use strict';

var monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];


$(document).on('ready promiseDates:loaded', function () {

    $('.wrapper-promise-dates').each(function () {
        if ($(this).html() != '') {
            return;
        }
        var promiseText = "";
        var storeText = '';
        var rawTime = $(this).attr('data-rawdate');
        var formattedDate = $(this).attr('data-formatteddate');
        var zipCode = $('.delivery-to-code').html();
        var shippingName = $(this).attr('data-shippingname');
        var isSTS = $(this).attr('data-issts');
        var isOversize = $(this).closest('.omni-label').find('.cart-oversize-price');
        if (shippingName) {
            promiseText = shippingName + ' - ';
        }
        if (formattedDate && formattedDate != 'undefined') {
            $(this).html(formattedDate);
            return;
        }
        var date = new Date(rawTime);
        if (date && rawTime && rawTime != 'undefined') {
            var finalDate = monthNames[date.getMonth()] + ' ' + date.getDate();
            var dateText = 'Delivered by ';
            var initDateText = dateText + ' <span class="sth-dates-later">' + finalDate + '</span>';
            if (isSTS == 'true') {
                storeText = ' ' + $(this).attr('data-store');
                $(this).parent().find('.estimate-bopis-time-wrapper').remove();
            }
            if (isSTS == 'true') {
                dateText = $(this).attr('data-static-pickup') + ' <span class="sts-date-later">' + $(this).attr('data-static-laterby') + ' ' + finalDate + '</span>';
            } else {
                dateText = $(this).attr('data-static-arrivesby') + ' <span class="sth-dates-later">' + finalDate + '</span>';
            }

            // Used as a fallback to use 'Delivered by' if static text is missing to avoid 'undefined' appearing
            if (!$(this).attr('data-static-pickup') && !$(this).attr('data-static-arrivesby')) {
                dateText = initDateText;
            }

            promiseText = (isOversize.length ? '<span class="mr-1">|</span>' : '') + dateText;

            $(this).html(promiseText);
        }

    });
});
