'use strict';

module.exports = function (element, offset, wrapperClass) {
    if (offset === undefined){
        offset = 0;
    }
    if (!wrapperClass){
        wrapperClass = 'html, body';
    }
    var position = (element && element.length && offset === 0) ? element.offset().top : offset;
    $(wrapperClass).animate({
        scrollTop: position
    }, 500);
    if (!element) {
        $('.logo-home').focus();
    }
};
