'use strict';

/**
* This function is responsible for verifying if the BrTrk exists and triggering its methods.
* @param {Object} data - treated object that is responsible to gather the information that is necessary to make the Pixel requests.
* @param {String} functionType - responsible to verify if it's page view or event
* @param {String} eventType - responsible to verify what kind of the event, e.g, "suggest" or "product"
* @param {String} eventTrigger - it's a param that is responsible to identify which the event trigger is being used.
* @param {Object} emptyObject - it's an empty object that has default value that does not need changes and it's received only on suggest eventType.
* @param {Boolean} boolean - it's a boolean variable that has default value that does not need changes and it's received only on suggest eventType.
*/

function brtrkHandle(data, functionType, eventType = '', eventTrigger = '', emptyObject = {}, boolean = true) {

    const functionTypeHandle = function () {
        switch (functionType) {
            case "Page View":
                window.BrTrk.getTracker().updateBrData(data);
                window.BrTrk.getTracker().logPageView();
                break;
            case "Event":
                if (eventType === 'suggest') {
                    window.BrTrk.getTracker().logEvent(eventType, eventTrigger, data, emptyObject, boolean);
                } else if (eventType === 'product' || eventType === 'cart') {
                    window.BrTrk.getTracker().logEvent(eventType, eventTrigger, data);
                } else if (eventType === 'widget') {
                    window.BrTrk.getTracker().logEvent(eventType, eventTrigger, data, boolean);
                }
                break;
            default:
                break;
        }
    }

    if (window.BrTrk) {
        functionTypeHandle()
    } else if (functionType === 'Event' && eventType === 'widget') {
        (function() {
            var brtrk = document.createElement('script');
            brtrk.type = 'text/javascript';
            brtrk.async = true;
            brtrk.src = '//cdn.brcdn.com/v1/br-trk-' + window.resources.bloomreach.accountID + '.js';
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(brtrk, s);
        })();
        
        functionTypeHandle()
    } else {
        var timer = setInterval(() => {
            if (window.BrTrk) {
                functionTypeHandle();
                stopInterval();
            }
        }, 2000);
        function stopInterval() {
            clearInterval(timer);
        }
    }
}

/**
* This function is responsible for gathering the required data properties to make the BloomReach requests for the Page View Pixels.
* @param {Object} data - treated object that is responsible to gather the information that is necessary to make the Pixel requests.
* link to the docs - https://documentation.bloomreach.com/discovery/docs/global-page-view-pixel
*/
function globalPageView(data) {
    var bloomReachResources = window.resources.bloomreach
    var accId = bloomReachResources.accountID
    data.acct_id = accId;
    data.title = $('title').text().replaceAll('  ', '').replaceAll('\n', '');
    if (bloomReachResources.viewID) {
        data.view_id = bloomReachResources.viewID;
    }
    data.user_id = bloomReachResources.customerID;
    data.tms = "";
    if (bloomReachResources.testData === 'true') {
        data.test_data = bloomReachResources.testData;
    }

    brtrkHandle(data, 'Page View')
}

/**
* This function is responsible for gathering the required data properties for the Product Page View Pixel.
* link to the docs - https://documentation.bloomreach.com/discovery/docs/product-page-view-pixel
*/
function productPageView() {
    var br_data = {};
    br_data.prod_id = $('span.product-id').html();
    br_data.prod_name = $('h1.product-name').text().replaceAll('  ', '').replaceAll('\n', '');
    br_data.sku = $('span.manufacturer-id').html();
    br_data.ptype = "product";

    globalPageView(br_data);
}

/**
* This function is responsible for gathering the required data properties for the Search Page View Pixel.
* link to the docs - https://documentation.bloomreach.com/discovery/docs/search-result-page-view-pixel
*/
function searchPageView() {
    const searchTerm = $('.search-keywords').first().text().replaceAll('  ', '').replaceAll('\n', '');
    var br_data = {};
    br_data.search_term = searchTerm;
    br_data.ptype = "search";

    globalPageView(br_data);
}

/**
* This function is responsible for gathering the required data properties for the Category Page View Pixel.
* link to the docs - https://documentation.bloomreach.com/discovery/docs/category-page-view-pixel
*/
function categoryPageView() {
    var bloomReachResources = window.resources.bloomreach;
    var breadCrumb = $('.breadcrumb-item');
    var categoriesOnBreadCrumb = $.map(breadCrumb, function (elem, i) {
        if (elem.attributes[1].nodeValue !== '') {
            return elem.attributes[1].nodeValue
        }
    });
    var br_data = {};
    br_data.ptype = 'category';
    br_data.cat_id = bloomReachResources.categoryID;
    br_data.cat = categoriesOnBreadCrumb.join('|');

    globalPageView(br_data)
}

/**
* This function is responsible for gathering the required data properties for the Content Page View Pixel.
* link to the docs - https://documentation.bloomreach.com/discovery/docs/content-page-view-pixel
*/
function contentPageView() {
    var br_data = {};
    br_data.ptype = 'content';

    br_data.catalogs = [{ name: "content_en" }];
    br_data.item_id = $('.js-page.page').attr('data-querystring').replace('cid=', '');
    br_data.item_name = $('.js-page.page h1').text();

    globalPageView(br_data)
}

/**
* This function is responsible for gathering the required data properties for the Conversion Page View Pixel.
* link to the docs - https://documentation.bloomreach.com/discovery/docs/conversion-page-view-pixel
*/
function conversionPageView() {
    var bloomReachResources = JSON.parse(window.resources.bloomreach.orderInfo)
    var br_data = {};
    br_data.ptype = 'other';
    br_data.is_conversion = 1;
    br_data.basket_value = bloomReachResources.totalPrice;
    br_data.order_id = bloomReachResources.orderID;
    br_data.basket = {
        items: bloomReachResources.items
    };

    globalPageView(br_data)
}

module.exports = {
    globalPageView: globalPageView,
    productPageView: productPageView,
    searchPageView: searchPageView,
    brtrkHandle: brtrkHandle,
    categoryPageView: categoryPageView,
    conversionPageView: conversionPageView,
    contentPageView: contentPageView
}
