'use strict';
require('formdata-polyfill');
var appointmentModal = require('../appointments/appointmentsGlobal').methods.appointmentModal;

/**
 * Sorts input fields into readable/iterable arrays or objects
 * @param {element} $inputElement - the jQuery element/field being sorted
 * @param {Object} formObject - the object the element will be added to
 */
function sortInput($inputElement, formObject) {
    switch ($inputElement.data('type')) {
        case 'array-item':
            var array = $inputElement.data('array');
            var titleCaseArray = array.replace(/([A-Z])/g, ' $1');
            titleCaseArray = titleCaseArray.charAt(0).toUpperCase() + titleCaseArray.slice(1);
            if (!formObject[array]) {
                formObject[array] = {
                    name: titleCaseArray,
                    itemType: 'simple',
                    array: []
                };
            }
            formObject[array].array.push({ name: $inputElement.data('label'), value: $inputElement.val() });
            break;
        case 'array-object':
            var array = $inputElement.data('array');
            var parentObject = $inputElement.data('object');
            var key = $inputElement.attr('name').split(parentObject)[0];
            parentObject = parentObject.replace(/([A-Z])/g, ' $1');
            var titleCaseArray = array.replace(/([A-Z])/g, ' $1');
            titleCaseArray = titleCaseArray.charAt(0).toUpperCase() + titleCaseArray.slice(1);
            if (!formObject[array]) {
                formObject[array] = {
                    name: titleCaseArray,
                    itemType: 'complex',
                    array: []
                };
                var arrayItem = { name: parentObject };
                arrayItem[key] = $inputElement.val();
                formObject[array].array.push(arrayItem);
            } else {
                if (formObject[array].itemType === 'simple') {
                    formObject[array].itemType = 'complex';
                }
                var itemFound = false;
                for (var i = 0; i < formObject[array].array.length; i++) {
                    if (formObject[array].array[i].name === parentObject) {
                        formObject[array].array[i][key] = $inputElement.val();
                        itemFound = true;
                        break;
                    }
                }
                if (!itemFound) {
                    var arrayItem = { name: parentObject };
                    arrayItem[key] = $inputElement.val();
                    formObject[array].array.push(arrayItem);
                }
            }
            break;
        case 'object-key':
            var object = $inputElement.data('object');
            var keyName = $inputElement.attr('name').split(object)[0];
            object = object.toLowerCase();
            if (!formObject[object]) {
                formObject[object] = {
                    objectName: object
                };
            }
            formObject[object][keyName] = $inputElement.val();
            break;
        default:
            formObject[$inputElement.attr('name')] = $inputElement.val();
            break;
    }
}

function populateModal(estimatorData, $div) {
    var $alert = $('<div/>')
        .addClass('alert alert-success')
        .text('An email has been sent to your account with the following estimates:');
    $($div).append($alert);
    var coverTypes = estimatorData.Covers.types;
    for (var i = 0; i < coverTypes.length; i++) {
        var $h3 = $('<h3></h3>').text(coverTypes[i].typeOfCover + ' Safety Covers');
        var $ul = $('<ul></ul>');
        var recommendations = coverTypes[i].recommendations;
        for (var h = 0; h < recommendations.length; h++) {
            var $li = $('<li></li>');
            var $span = $('<span></span>')
                .text(recommendations[h].Name + ': ')
                .addClass('h5 font-weight-bold');
            var price = recommendations[h].priceType != 'string' ? '$' : '';
            price +=
                recommendations[h].Price.indexOf('Call') > -1
                    ? 'Call us for pricing details.'
                    : recommendations[h].Price;
            $li.append($span, price);
            $ul.addClass('list-unstyled ml-3').append($li);
        }
        $($div).append($h3, $ul);
    }
}

module.exports = {
    sendEmail: function () {
        var $form = $('.js-custom-form');
        if ($form.is('*')) {
            $form.submit(function (e) {
                e.preventDefault();
                e.stopImmediatePropagation();
                if ($(this).hasClass('openAppointmentModal')) {
                    var yourNameField = $form.find('#your-name').val();
                    var firstName = '';
                    var lastName = '';

                    if (yourNameField && yourNameField.length > 0) {
                        firstName = yourNameField.substring(0, yourNameField.indexOf(' '));
                        lastName = yourNameField.substring(yourNameField.indexOf(' ') + 1);
                    }

                    var appointmentPrePopulation = {
                        firstname: firstName,
                        lastname: lastName,
                        email: $form.find('#email').val() || '',
                        phone: $form.find('#your-phone').val() || '',
                        postalCode: $form.find('#your-zipcode').val() || '',
                        city: $form.find('#city').val() || '',
                        stateCode: $form.find('#state').val() || '',
                        address1: $form.find('#address1').val() || '',
                        address2: $form.find('#address2').val() || '',
                        storeAssociate: $form.find('#store-associate').val() || '',
                        storeId: $form.find('#store-id').val() || '',
                        workDesc: $form.find('#work-description').val() || '',
                        pPmeth: ($form.find('#prepaid-yes').attr('checked')) ? $form.find('#prepaid-yes').val() : $form.find('#prepaid-no').val(),
                        transactionId: ($form.find('#prepaid-yes').attr('checked')) ? $form.find('#transactionID').val() : ''
                    }

                    var requestData = {
                        appointmentActionType: $('#request-service-submit').data('action-type'),
                        serviceType: $('#serviceType').length ? $('#serviceType').val() : $('#service-list-asset').val(),
                        appointmentPrePopulation: JSON.stringify(appointmentPrePopulation),
                        origin: 'requestAppointmentForm'
                    }

                    var url = $('#request-service-submit').data('url');
                    appointmentModal(url, requestData);
                } else {
                    var formData = new FormData($form[0]);
                    var formObject = {};
                    $('.js-custom-form *')
                        .filter(':input')
                        .each(function () {
                            if (formData.get($(this).attr('name')) === $(this).val()) {
                                if ($(this).data('default') && !$(this).val()) {
                                    $(this).val($(this).data('defaultvalue'));
                                }
                                sortInput($(this), formObject);
                            } else if ($(this).is('textarea') && $(this).val()) {
                                formObject[$(this).attr('name')] = $(this).val().replace(/\n/g, '<br />');
                            }
                        });
                    formData.set('formObject', JSON.stringify(formObject));
                    var url = $(this).attr('action');
                    $.spinner().start();
                    $.ajax({
                        url: url,
                        data: formData,
                        method: 'POST',
                        cache: false,
                        enctype: 'multipart/form-data',
                        contentType: false,
                        processData: false,
                        success: function (data) {
                            $('body').trigger('formSubmit:submit', data.error);
                            if (!data.error) {
                                if (data.redirectUrl) {
                                    window.location.href = data.redirectUrl;
                                } else {
                                    $form.html($('<div/>').addClass('alert').addClass('alert-success').text(data.message));
                                    if (data.estimatorData) {
                                        // var $div = $('<div/>').addClass('card my-2');
                                        // var $cardbody = $('<div/>').addClass('card-body')
                                        // populateModal(data.estimatorData, $cardbody);
                                        // $div.append($cardbody);
                                        $form.html(data.resultsHtml);
                                    } else if (data.custom) {
                                        $form.html(data.resultsHtml);
                                    }
                                    $.spinner().stop();
                                }
                            } else {
                                $('input[name="email"], input[name="zipCode"]').removeClass('is-invalid');
                                $('input[name="email"], input[name="zipCode"]').siblings('.invalid-feedback').text('');
                                if (
                                    data.errorCode === 1 ||
                                    data.errorCode === 2 ||
                                    data.errorCode === 4 ||
                                    data.errorCode === 6
                                ) {
                                    if (data.errorCode === 4) {
                                        $('input[name="zipCode"]').addClass('is-invalid');
                                        $('input[name="zipCode"]').siblings('.invalid-feedback').text(data.message);
                                    }

                                    if (data.errorCode === 1 || data.errorCode === 2) {
                                        $('input[name="email"]').addClass('is-invalid');
                                        $('input[name="email"]').siblings('.invalid-feedback').text(data.message);
                                    }

                                    if (data.errorCode === 6) {
                                        var $modal = $('#serviceSelectedNotAvailableForZipCode');
                                        $modal.modal('show');

                                        $modal.on('hidden.bs.modal', function () {
                                            $('#serviceSelectedNotAvailableForZipCode').modal('hide');
                                        });
                                    }

                                    $('body').trigger('customForm:error');
                                } else {
                                    var $alertNotification = $('.js-recaptcha-alert');
                                    if ($alertNotification.length > 0) {
                                        $alertNotification.removeClass('d-none');
                                    } else {
                                        $('button[name="submit"]').before(
                                            $('<div/>')
                                                .addClass('alert')
                                                .addClass('alert-danger')
                                                .addClass('js-recaptcha-alert')
                                                .text(data.message)
                                        );
                                    }
                                }
                                $.spinner().stop();
                            }
                            $('body').trigger('recaptcha:reset');
                        },
                        error: function () {
                            $form.html(
                                $('<div/>')
                                    .addClass('alert')
                                    .addClass('alert-danger')
                                    .text('Something went wrong. Please refresh and try again.')
                            );
                            $.spinner().stop();
                            $('body').trigger('recaptcha:reset');
                        }
                    });
                }
            });
        }
    },
    resetForm: function () {
        $('body').on('click', '.btn-reset', function (e) {
            e.preventDefault();
            $('.js-custom-form')
                .find('input[type=number]')
                .each(function () {
                    $(this).val('');
                });
            $('.js-custom-form')
                .find('input[type=radio]')
                .each(function () {
                    $(this).prop('checked', false);
                });
            $('.js-custom-form')
                .find('select')
                .each(function () {
                    $(this)[0].selectedIndex = 0;
                });
        });
    },
    openForm: function () {
        var $form = $('.js-custom-form');
        if ($form.is('*')) {
            $('body').trigger('formOpener:open');
        }
        var $jacuzziForm = $('#energy_calc_main');
        if ($jacuzziForm.is('*')) {
            $('body').trigger('formOpener:open');
        }
    },
    resetCaptcha: function () {
        $('body').on('recaptcha:reset', function () {
            if (typeof grecaptcha !== 'undefined') {
                grecaptcha.reset();
            }
        });
    },
    clearCaptchaNotification: function () {
        window.clearCaptchaNotification = function () {
            $('.js-recaptcha-alert').addClass('d-none');
        };
    }
};
